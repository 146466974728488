import {
  COL_FORM_STRUCTURE,
  COL_FORM_STRUCTURE_UPDATE,
} from "../constants/constants";

const initialState = [];

export const getColFormStructureReducers = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case COL_FORM_STRUCTURE:
      return [...state, { ...payload }];
    case COL_FORM_STRUCTURE_UPDATE:
      return payload;

    default:
      return state;
  }
};

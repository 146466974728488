import React, { useState } from "react";
import "./styles.scss";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createSingleInterviewFunc,
  resetSingleCandidateReff,
} from "../../../store/actions/singleCandidateScreenActions";

const InterviewPopup = ({ opne, setOpen, data, name, certificate }) => {
  const [selectValue, setSelectValue] = useState(0);
  const [startDate, setStartDate] = useState("");
  const candidateID = JSON.parse(localStorage.getItem("candidateID"));

  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const candidate = useSelector((state) => state?.rcandidate);
  const singleCandidate = useSelector((state) => state?.singleCandidate);
  console.log(data);

  return (
    <Box className="interviewParrent">
      <Typography variant="h4" className="align-left">
        {"IP Matched"}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <table style={{ width: "100%" }}>
            <tr>
              <th>Referee IP</th>
              <td>{data?.referee_ip}</td>
            </tr>
            <tr>
              <th>Candidate IP</th>
              <td>{data?.candidate_ip}</td>
            </tr>
          </table>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InterviewPopup;

import React from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { routes } from "./routes";
import { useSelector } from "react-redux";

function CheckWorkSpaceType() {
  const auth = useSelector((state) => state.auth);
  let user = false;
  if (auth?.results?.workspace_type === "recruitment") {
    user = true;
  }
  return user ? <Outlet /> : <Navigate to={routes.LOGIN} replace />;
}

export default CheckWorkSpaceType;

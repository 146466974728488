import React, { useCallback, useLayoutEffect, useState } from "react";
import { DOCUMENT_SIGNING } from "../../store/constants/constants";
import { useDispatch } from "react-redux";
import {
  setDocSigningType,
  setUploadPdf,
} from "../../store/actions/pdfupload.actions";
import { setPublishingPDFs } from "../../store/actions/docPublishing.action";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import style from "./styles.module.scss";
import { subScriptionStatus } from "../../api/sinner/SinnerApi";
import { ShowAlert } from "../../store/actions/alertActions";

function SelectPDF() {
  const [docType, setDocType] = useState("");

  const [fileNameList, setFileNameList] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [showDocError, setShowDocError] = useState(false);

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const userData = JSON.parse(localStorage.getItem("auth"))?.results || {};
  const userType = JSON.parse(localStorage.getItem("user"));

  {
    //check subscription API
    const subsCriptionAPICall = useCallback(async () => {
      try {
        const query = `recruiter_id=${
          userData?.recruiter_id || ""
        }&workspace_id=${userData?.workspace_id || ""}`;
        const response = await subScriptionStatus(query);
        if (response?.data?.code === 200) {
          const data = response?.data?.results;
          if (
            (!data?.is_trial_active && !data?.subscription) ||
            (!data?.subscription &&
              data?.is_trial_active &&
              data?.total_completed_signed === 1)
          ) {
            navigation(-1);
          }
        } else {
          dispatch(ShowAlert("Something went wrong..!!", "error"));
        }
      } catch (error) {
        console.error(error);
        dispatch(ShowAlert("Something went wrong..!!", "error"));
      }
    }, [dispatch, navigation]);

    useLayoutEffect(() => {
      if (userType === "recruitment") {
        subsCriptionAPICall();
      }
    }, [subsCriptionAPICall, userType]);
  }

  const handleFileSelect = (e, fileType) => {
    if (e.target.files && e.target.files.length > 0) {
      const names = [];
      const tmpFilelist = [];
      for (let i = 0; i < e.target.files.length; i++) {
        if (i > process.env.REACT_APP_MAX_FILE_CHOOSE - 1) {
          break;
        }
        names.push(e.target.files[i].name);
        tmpFilelist.push(e.target.files[i]);
      }
      setFileNameList([...names]);
      setAllFiles([...e.target.files]);

      if (fileType === DOCUMENT_SIGNING) {
        if (e.target.files[0].type !== "application/pdf") {
          setShowDocError(true);
          return;
        }
      }
      setShowDocError(false);

      dispatch(setUploadPdf(e.target.files[0]));

      dispatch(setPublishingPDFs(tmpFilelist));
    }
    dispatch(setDocSigningType(fileType));
  };

  const handleNext = (e) => {
    e.preventDefault();

    if (docType === DOCUMENT_SIGNING) {
      navigation(`${routes.DOCUMENT_SIGN.SINNER}`, {
        state: {
          allFiles: allFiles,
        },
      });
    } else {
      navigation(`${routes.DOCUMENT_SIGN.SINNER}`, {
        state: {
          allFiles: "all test",
        },
      });
    }
  };

  return (
    <form onSubmit={handleNext} className={`${style.contentBox}`}>
      <label
        className={`visually-hidden form-check form-check-inline cursor-pointer ${
          docType === DOCUMENT_SIGNING ? " doc-background" : null
        }`}
        htmlFor='fileElem1'
        title=''>
        <span className={`${style.levelOne}`}>
          Upload PDF <span>+</span>
        </span>
      </label>
      <input
        accept='application/pdf'
        className=''
        id='fileElem1'
        onChange={(e) => {
          setDocType(DOCUMENT_SIGNING);
          handleFileSelect(e, DOCUMENT_SIGNING);
        }}
        style={{
          display: "none",
        }}
        type='file'
      />

      {fileNameList?.length > 0 &&
        fileNameList.map((fileItems, fileKeys) => {
          return (
            <div className='pdf_name' key={fileKeys}>
              {fileItems}
            </div>
          );
        })}

      {showDocError ? (
        <div
          style={{
            margin: "auto",
            display: "table",
            color: "red",
            marginBottom: "20px",
          }}>
          Please choose pdf file for Document signing
        </div>
      ) : null}

      <div className='button_box mb-4'>
        <button
          disabled={docType === "" || showDocError ? true : false}
          type='submit'>
          Next
        </button>
      </div>
    </form>
  );
}

export default SelectPDF;

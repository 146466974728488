import { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import LoadingBar from 'react-top-loading-bar';

import AskPassword from "../../document_sinner/AskPassword.jsx";
// import DashboardHeader from '../common/DashboardHeader';
import useGetCombineDocMetadataForDocumentSigningFlow from "../../../customHook/useGetCombinedMetadataForDocumentSigningFlow";
import SpinnerLoader from "../../document_sinner/SpinnerLoader.jsx";
import { routes } from "../../../routes.js";

const ViewSigningDocs = () => {
  // const ref = useRef();
  const { id } = useParams();
  const { state } = useLocation();
  const {
    docInfo,
    showPasswordPopup,
    onPassSubmit,
    passError,
    setDecPassword,
    isProgress,
    docResult,
  } = useGetCombineDocMetadataForDocumentSigningFlow({ id });

  const [signerInfo, setSignerInfo] = useState([]);
  const [uninqueAlreadySign, setUninqueAlreadySign] = useState([]);

  const userData = JSON.parse(localStorage.getItem("auth"))?.results || {};

  const navigation = useNavigate();

  {
    //API function
    const getSignersInfo = useCallback(async () => {
      if (docResult?.payload?.signers?.length > 0) {
        console.log(docResult?.payload?.signers);

        const signedData = [];
        const notSignedData = [];

        docResult?.payload?.signers.forEach((item) => {
          const { isSigned } = item;
          console.log(isSigned);
          if (isSigned) {
            signedData.push(item);
          } else {
            notSignedData.push(item);
          }
        });
        console.log(signedData.length, notSignedData?.length);

        setSignerInfo(notSignedData);
        setUninqueAlreadySign(signedData);
      }
    }, [docResult?.payload?.signers]);

    useEffect(() => {
      if (docInfo) {
        getSignersInfo();
        // alreadySinned();
      }
    }, [docInfo, getSignersInfo]);
  }
  const alreadySinned = () => {
    console.log(docInfo?.signers);
    if (docInfo.signers && docInfo?.signers?.length > 0) {
      const uniqueData = docInfo.signers.filter((item, index, array) => {
        // Use the findIndex method to get the index of the first occurrence of the email
        const firstIndex = array.findIndex(
          (element) => element.email === item.email
        );

        // Filter out the items with duplicate emails
        return index === firstIndex;
      });
      const sinnedData = uniqueData.filter((item, index, array) => {
        // Use the findIndex method to get the index of the first occurrence of the email
        const firstIndex = array.findIndex((element) => element.hasSigned);

        // Filter out the items with duplicate emails
        return index === firstIndex;
      });
      setUninqueAlreadySign(sinnedData);
    }
  };

  return (
    <>
      {/* <DashboardHeader /> */}
      <SpinnerLoader isOpen={isProgress} />
      <AskPassword
        decryptionPopup={showPasswordPopup}
        onPassSubmit={onPassSubmit}
        passError={passError}
        setDecPassword={setDecPassword}
      />
      {docInfo && (
        <div className=' container content_box mt-5'>
          <div className='row  '>
            <div
              className='col-md-7 m-md-auto mx-4'
              style={{ border: "1px solid black" }}>
              <div className='form_main_box  px-0 py-3'>
                <div className='text-center'>
                  <title>{docInfo?.name || ""}</title>
                  <label className='form-label file_title text-capitalize'>
                    Signing Documents
                  </label>
                  <hr />
                </div>
                <div className='form-group'>
                  <div className='row'>
                    <div className='col-9 text-secondary'>
                      {" "}
                      {state?.row?.title}
                    </div>
                  </div>
                </div>
                <div>
                  <label className='form-label' htmlFor='intendedAudience'>
                    Shared By
                  </label>
                  <div className='mb-3'>
                    <div className='row'>
                      <div className='col-3'>Name</div>
                      <div className='col-9 text-secondary'>
                        {docInfo?.blob?.sender.name || ""}{" "}
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-3'>Email</div>
                      <div className='col-9 text-secondary'>
                        {docInfo?.blob?.sender?.email || null}{" "}
                      </div>
                    </div>
                  </div>
                </div>

                {uninqueAlreadySign?.length > 0 ? (
                  <>
                    <div>
                      <label className='form-label' htmlFor='intendedAudience'>
                        Already Signed documents
                      </label>
                    </div>

                    {uninqueAlreadySign.map((item, key) => {
                      return (
                        <div
                          className='filesList form-control file_list_main mb-3'
                          key={key}>
                          <div className='row'>
                            <div className='col-3'>Name</div>
                            <div className='col-9 text-secondary'>
                              {item.name}
                            </div>
                            <div className='col-3'>Email</div>
                            <div className='col-9 text-secondary'>
                              {item.signerEmail}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : null}

                {signerInfo?.length > 0 ? (
                  <>
                    <div>
                      <label className='form-label' htmlFor='intendedAudience'>
                        Waiting For Signature
                      </label>
                    </div>

                    {signerInfo.map((item, key) => {
                      return (
                        <div key={key}>
                          <div className='filesList form-control file_list_main mb-3'>
                            <div className='row'>
                              <div className='col-3'>Name</div>
                              <div className='col-9 text-secondary'>
                                {item.name}
                              </div>
                              <div className='col-3'>Email</div>
                              <div className='col-9 text-secondary'>
                                {item.signerEmail}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : null}

                <div className='download_btn mt-2 text-center'>
                  <button
                    className='btn btn-primary ml-3'
                    onClick={() =>
                      navigation(
                        `${
                          userData?.type === "recruitment"
                            ? routes.DOCUMENT_SIGN.DOC_PREVIEW_SINNER
                            : routes.CAN_DOCUMENT.DOC_PREVIEW_SINNER_CAN
                        }/${id}`
                      )
                    }
                    target='_blank'
                    type='button'>
                    View PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewSigningDocs;

import { Box } from "@mui/system";
import React, { useRef } from "react";
import style from "./style.module.scss";
import Actions from "./Actions";
import useDragField from "../../../../customHook/useDragField";

function TableField({
  id,
  main_id,
  type,
  header,
  table,
  two_col,
  col_name,
  index,
  moveCard,
}) {
  const ref = useRef(null);
  const dragField = useDragField(ref, index, moveCard, id);
  const opacity = dragField.opacity;
  return (
    <Box
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={dragField?.handlerId}
      className={`${style.inputBox} dropItem ${style.tableBlock}`}
    >
      <Actions
        type={type}
        id={id}
        two_col={two_col}
        col_name={col_name}
        main_id={main_id}
      />
      <table>
        <tr>
          {header?.data.map((item, i) => {
            return <th>{item.name}</th>;
          })}
        </tr>
        {table?.map((item, i) => {
          return (
            <tr>
              {item?.map((value, j) => {
                return <td>{value}</td>;
              })}
            </tr>
          );
        })}
      </table>
    </Box>
  );
}

export default TableField;

import React, { useRef } from "react";
import useDragField from "../../../../customHook/useDragField";
import { Box } from "@mui/system";
import Actions from "./Actions";
import style from "./style.module.scss";
import { useSelector } from "react-redux";
import { TbMail, TbPhoneCall } from "react-icons/tb";

function RecruiterInfo({
  type,
  info,
  id,
  index,
  moveCard,
  main_id,
  two_col,
  col_name,
}) {
  console.log("info",info);
  const ref = useRef(null);
  const dragField = useDragField(ref, index, moveCard, id);
  const opacity = dragField.opacity;
  const auth = useSelector((state) => state.auth);
  const rprofile = useSelector((state) => state?.rprofile?.profile);
  // console.log("auth", auth);
  // console.log("rprofile", rprofile?.results);
  // console.log("info", info);
  return (
    <Box
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={dragField?.handlerId}
      className={`${style.inputBox} dropItem`}
    >
      <Actions
        type={type}
        id={id}
        two_col={two_col}
        col_name={col_name}
        main_id={main_id}
      />

      <p>
        {info?.name 
          ? info?.name
          : rprofile?.results?.first_name + " " + rprofile?.results?.last_name}
      </p>
      <p>
        <span style={{ color: "#00cfc5", fontWeight: 600, fontSize: "20px" }}>
          <TbPhoneCall />{" "}
        </span>
        {info?.phone || rprofile?.results?.contact_number}
      </p>
      <p>
        <span style={{ color: "#00cfc5", fontWeight: 600, fontSize: "20px" }}>
          <TbMail />{" "}
        </span>
        { info?.email || rprofile?.results?.email}
      </p>
    </Box>
  );
}

export default RecruiterInfo;

import React from "react";
import Modal from "react-responsive-modal";

import Loader150GIF from "../../assets/common/loader150.gif";

/***
 * loader component.
 */
function SpinnerLoader({ isOpen, message = "Loading..." }) {
  return (
    <>
      <Modal center closeOnEsc={false} open={isOpen} showCloseIcon={false}>
        <div
          style={{
            minWidth: 150,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <img src={Loader150GIF} style={{ width: 30, marginRight: 10 }} />
          <div>{message}</div>
        </div>
      </Modal>
    </>
  );
}

export default SpinnerLoader;

import React from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import style from "./styles.module.scss";

/****
 * this is popup modal , renders elements ie- div tags in popup mode
 */
function PopupModal({ children, minWidth = 'auto', ...props }) {
  return (
    <Modal {...props} center>
       
      <div
        
        className={`${style.optionContainer}`}
        style={{ minWidth: minWidth, paddingTop: 30 }}
      >
        {children}
      </div>
    </Modal>
  );
}

export default PopupModal;

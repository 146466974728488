import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const AllCandidates = () => {
  const orders = useSelector(
    (state) => state?.licenseOrders?.singleOrder?.results
  );
  const dispatch = useDispatch();

  const auth = useSelector((state) => state?.auth);
  // const total = ?.reduce((i, e) => i + Number(e?.price), 0);

  return (
    <Box className={styles.parent}>
      <Box className={styles.header}>
        <Typography variant="h4">License Orders Detail</Typography>

        {/* <Box className={styles.searchBox}>
          <BsSearch />
          <input type="text" placeholder="Search..." />
        </Box> */}
      </Box>
      {/* <Typography component={"h5"}>
        {orders?.singleOrder?.results?.orderDetail[0]?.plan_id}
      </Typography> */}
      <Box className={styles.candidateTable}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Box className={styles.boxTable2} style={{ marginTop: "10px" }}>
              <Box className={styles.rowHead}>
                <Box className={styles.rowBody}>Order Details</Box>
                {/* <Box className={styles.rowBody}>Items Summary</Box> */}
              </Box>
              <Grid container>
                <Grid item md={12} lg={6} className={styles.borderRight}>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>License Name</Box>
                    <Box className={styles.rowBody}>
                      {orders?.license_data?.name}
                    </Box>
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>Industry</Box>
                    <Box className={styles.rowBody}>
                      {orders?.license_data?.industry}
                    </Box>
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>License Price</Box>
                    <Box className={styles.rowBody}>
                      ${orders?.license_data?.price}
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12} lg={6}>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>Candidate Name</Box>
                    <Box className={styles.rowBody}>
                      {orders?.candidate_details?.first_name +
                        " " +
                        orders?.candidate_details?.last_name}
                    </Box>
                  </Box>

                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>Email address</Box>
                    <Box className={styles.rowBody}>
                      {orders?.candidate_details?.email}
                    </Box>
                  </Box>

                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>Phone Number</Box>
                    <Box className={styles.rowBody}>
                      {orders?.candidate_details?.contact_number}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item md={12} lg={12} sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={6} sx={{ width: "100%" }}>
                <Box className={styles.boxTable2}>
                  <Box className={styles.rowHead}>
                    <Box className={styles.rowBody}>Customer order details</Box>
                    {/* <Box className={styles.rowBody}>Items Summary</Box> */}
                  </Box>

                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>Customer Name</Box>
                    <Box className={styles.rowBody}>
                      {auth?.results?.first_name +
                        " " +
                        auth?.results?.last_name}
                    </Box>
                  </Box>

                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>Email address</Box>
                    <Box className={styles.rowBody}>{auth?.results?.email}</Box>
                  </Box>

                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>Phone Number</Box>
                    <Box className={styles.rowBody}>
                      {auth?.results?.contact_number}
                    </Box>
                  </Box>

                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>Address line</Box>
                    <Box className={styles.rowBody}>Minigin</Box>
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>Flat / Building Name</Box>
                    <Box className={styles.rowBody}>Eureka Tower</Box>
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}> Street Name</Box>
                    <Box className={styles.rowBody}>13 Loris Way</Box>
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>Postal Code</Box>
                    <Box className={styles.rowBody}>6312</Box>
                  </Box>

                  {/* <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                <Box className={styles.rowBody}>Bag Option</Box>
                <Box className={styles.rowBody}>No Bag</Box>
              </Box>
              <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                <Box className={styles.rowBody}>Note</Box>
                <Box className={styles.rowBody}>N /A</Box>
              </Box> */}
                </Box>
              </Grid>
              <Grid item md={12} lg={6} sx={{ width: "100%" }}>
                <Box className={styles.boxTable3}>
                  <Box className={styles.rowHead}>
                    <Box className={styles.rowBody}>Order Summary</Box>
                    {/* <Box className={styles.rowBody}>Items Summary</Box> */}
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>Order Created</Box>
                    <Box className={styles.rowBody}>
                      {moment(
                        orders?.singleOrder?.results?.orderDetail[0]?.created_at
                      ).format("dddd/DD-MM-YYYY")}
                    </Box>
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>Order Time</Box>
                    <Box className={styles.rowBody}>
                      {moment(
                        orders?.singleOrder?.results?.orderDetail[0]?.created_at
                      ).format("HH:mm A")}
                    </Box>
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>Subtotal</Box>
                    <Box className={styles.rowBody}>
                      ${orders?.license_data?.price}
                    </Box>
                  </Box>

                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>GST</Box>
                    <Box className={styles.rowBody}>${orders?.gst}</Box>
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>Total</Box>
                    <Box className={styles.rowBody}>${orders?.total_paid}</Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AllCandidates;

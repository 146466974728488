import { UPLOAD_SIGN } from "../constants/constants";

const initialState = [];

export const uploadSignBuilderReducers = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case UPLOAD_SIGN:
      return [...state, payload];

    default:
      return state;
  }
};

import { Box } from "@mui/material";
import React, { useRef } from "react";
import Actions from "./Actions";
import style from "./style.module.scss";
import useDragField from "../../../../customHook/useDragField";

function ImageField({
  img_url,
  id,
  main_id,
  type,
  name,
  two_col,
  col_name,
  field_style,
  preview,
  index,
  moveCard,
}) {
  const ref = useRef(null);
  const dragField = useDragField(ref, index, moveCard, id);
  const opacity = dragField.opacity;
  return (
    <Box
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={dragField?.handlerId}
      className={`${style.inputBox} dropItem`}
    >
      <Actions
        type={type}
        id={id}
        two_col={two_col}
        col_name={col_name}
        main_id={main_id}
      />
      <div
        style={{
          justifyContent: `${field_style?.display}`,
          display: "flex",
          alignItem: "center",
          marginTop: "10px",
        }}
      >
        <div className={style.imgFieldBox}>
          {img_url === "" || img_url === null ? (
            <span>No Image</span>
          ) : (
            <img
              src={`${process.env.REACT_APP_URL}/storage/${img_url}`}
              alt="img"
              name={name}
              id={id}
            />
          )}
        </div>
      </div>
      {/* {preview !== 1 ? <p>Supported file : JPG,JPEG,PNG</p> : ""} */}
    </Box>
  );
}

export default ImageField;

import React from "react";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import styles from "./styles.module.scss";
import { ReactComponent as Edit } from "../../../assets/candidates/edit.svg";
import { ReactComponent as Download } from "../../../assets/candidates/download.svg";
import { ReactComponent as Eye } from "../../../assets/candidates/eye.svg";
import { ReactComponent as Chat } from "../../../assets/dashboard/message_green.svg";
import { ReactComponent as Delete } from "../../../assets/candidates/delete.svg";
import { ReactComponent as Verification } from "../../../assets/new-icons/verification.svg";
import { ReactComponent as Candidate } from "../../../assets/new-icons/candidate.svg";
import { ReactComponent as Resend } from "../../../assets/new-icons/resend.svg";
import { ReactComponent as Notification } from "../../../assets/dashboard/bell.svg";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";

import { FaSync } from "react-icons/fa";

import DeleteModel from "../../common/DeleteModal/index";
import { AiOutlinePlus } from "react-icons/ai";
import { MdVerifiedUser } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteInterviewFucntion,
  editSingleCandidateCertificate,
  editSingleCandidateInterview,
  getAllRecruiterCandidateReff,
  resendSingleCandidateInterview,
  resendSingleCertificateFunc,
  resendSingleReffFunc,
  resetSingleCandidateReff,
  StatusShowSingleCertificateFunc,
  StatusShowSingleReffFunc,
  verifySingleCandidateCertificate,
} from "../../../store/actions/singleCandidateScreenActions";
import ShowReff from "../showRefference/index";
import ResponsePopup from "../responsePopup/index";
import IPMatchedPopup from "../ipMatchedPopup/index";
import CustomModel from "../single-candidate-model/index";
import Modal from "../model";
import { useState } from "react";
import { useEffect } from "react";

import ResendModel from "../resend-form";
import {
  resendFormCandidate5,
  viewForm,
} from "../../../store/actions/applyJobActions";
import { routes } from "../../../routes";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCandidateFormDetail,
  getCandidateFormDetail2,
  getCandidateFormDetail3,
  getCandidateFormDetail4,
  reqCandidateFormDetail,
  reqCandidateFormDetail2,
  reqCandidateFormDetail3,
  reqCandidateFormDetail4,
  reqCandidateFormDetail5,
  resendFormCandidate,
  resendFormCandidate2,
  resendFormCandidate3,
  resendFormCandidate4,
} from "../../../store/actions/applyJobActions";
import { ShowAlert } from "../../../store/actions/alertActions";
import CheckoutForm from "../checkout/checkout";

// function createData(id, name, status, reff, lof, app) {
//   return { id, name, status, reff, lof };
// }

// const rows = [
//   createData(
//     "Jack Effron",
//     "+6123456781",
//     "jackeffron@gmail.com",
//     "Application Form",
//     `Not Responded Yet`
//   ),
//   createData(
//     "Jack Effron",
//     "+6123456781",
//     "jackeffron@gmail.com",
//     "Application Form",
//     `Not Responded Yet`
//   ),
//   createData(
//     "Jack Effron",
//     "+6123456781",
//     "jackeffron@gmail.com",
//     "Application Form",
//     `Not Responded Yet`
//   ),
//   createData(
//     "Jack Effron",
//     "+6123456781",
//     "jackeffron@gmail.com",
//     "Application Form",
//     `Not Responded Yet`
//   ),
// ];

const CandidateTable = ({
  titles,
  rows,
  interview,
  value,
  cata,
  id,
  results,
}) => {
  const uuid = useParams().uuid;
  const navigate = useNavigate();
  const singleCandidate = useSelector((state) => state?.singleCandidate);
  const candidate = useSelector((state) => state?.rcandidate);
  const auth = useSelector((state) => state?.auth);
  const [openReff, setOpenReff] = useState(false);
  const [openMatchedIP, setOpenMatchedIP] = useState(false);
  const [openCertificate, setOpenCertificate] = useState(false);
  const [openResponse, setOpenResponse] = useState(false);
  const candidateID = JSON.parse(localStorage.getItem("candidateID"));
  const [selectedValue, setSelectedValue] = useState("");
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const applyjobs = useSelector((state) => state?.applyJob);
  const [open3, setOpen3] = useState(false);
  const [resend, setResend] = useState("");
  const [open2, setOpen2] = useState(false);
  // tabel
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [values, setValues] = useState({
    form_builder_id: "",
  });
  const [Customerror, setErrors] = useState({});
  const validate = (data) => {
    const newError = {};
    for (let key in data) {
      let value = data[key];
      switch (key) {
        case "form_builder_id":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "fields is Required";
          break;
        default:
      }
    }
    return newError;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openDelteModal = (e) => {
    setSelectedValue(e?.id);
    setDeleteOpen(true);
  };

  const deleteInterviewTable = (uuid, body2) => {
    dispatch(deleteInterviewFucntion(uuid, body2));
  };

  const dispatch = useDispatch();
  const showStatusReff = (item) => {
    dispatch(StatusShowSingleReffFunc(item?.uuid)).then(() => {
      setOpenReff(true);
    });
  };

  const showIPModel = (item) => {
    dispatch(StatusShowSingleReffFunc(item?.uuid)).then(() => {
      setOpenMatchedIP(true);
    });
  };

  const editSingleCertificate = (item) => {
    if (singleCandidate?.loading) return;
    dispatch(
      editSingleCandidateCertificate(item?.uuid, {
        candidate_id: candidate?.showCandidate?.results?.id || candidateID,
      })
    );
  };
  const resendInterVieww = (item) => {
    if (singleCandidate?.loading) return;
    dispatch(resendSingleCandidateInterview(item?.uuid));
  };

  const editSingleInterView = (item) => {
    if (singleCandidate?.loading) return;
    dispatch(editSingleCandidateInterview(item?.uuid));
  };

  const showStatusCertificates = (item) => {
    dispatch(StatusShowSingleCertificateFunc(item?.uuid)).then(() => {
      setOpenCertificate(true);
    });
  };

  const showResponseCertificate = (item) => {
    dispatch(StatusShowSingleCertificateFunc(item?.uuid)).then(() => {
      setOpenResponse(true);
    });
  };
  const verifycertificate = (item) => {
    if (singleCandidate?.loading) return;
    // console.log("item", item);
    if (item?.is_automated_checks === 1) {
      dispatch(StatusShowSingleCertificateFunc(item?.uuid)).then(() => {
        setOpen2(true);
      });
    } else {
      dispatch(
        verifySingleCandidateCertificate(
          { uuid: item?.uuid },
          {
            workspace_id: auth?.results?.workspace_id,
            candidate_id: candidate?.showCandidate?.results?.id,
          }
        )
      );
    }

    // dispatch(
    //   getAllRecruiterCandidateCertificates({
    //     candidate_id: candidate?.showCandidate?.results?.id || candidateID,
    //     workspace_id: auth?.results?.workspace_id,
    //   })
    // );

    // dispatch(
    //   getAllRecruiterCandidateCertificates2({
    //     candidate_id: candidate?.showCandidate?.results?.id || candidateID,
    //     workspace_id: auth?.results?.workspace_id,
    //     automated_list:1,
    //   })
    // );
  };

  const resendReff = (item, name) => {
    if (singleCandidate?.loading) return;
    dispatch(resendSingleReffFunc(item?.uuid, name)).then(() => {});
  };
  const resendSingleCertificate = (item) => {
    if (singleCandidate?.loading) return;
    dispatch(resendSingleCertificateFunc(item?.uuid)).then(() => {});
  };

  const resetFunction = () => {
    dispatch(resetSingleCandidateReff());
  };

  const applyJobs = useSelector((state) => state?.applyJob);

  const reminderReference = (item) => {
    const array =
      item?.candidate_form_reference[
        item?.candidate_form_reference?.length - 1
      ];
    const local_data = {
      uuid: uuid,
      recruiter_id: auth?.results?.recruiter_id,
      candidate_id: candidate?.showCandidate?.results?.id || candidateID,
      workspace_id: auth?.results?.workspace_id,
      form_category_name: array?.form_builder_category?.name,

      form_builder_id: array?.form_builder?.id,
      candidate_reference_id: item?.id,
    };
    dispatch(resendFormCandidate5(local_data)).then(() => {
      dispatch(
        getAllRecruiterCandidateReff({
          candidate_id: candidate?.showCandidate?.results?.id || candidateID,
          workspace_id: auth?.results?.workspace_id,
        })
      );
    });
  };

  const viewData = (item) => {
    const array =
      item?.candidate_form_reference[
        item?.candidate_form_reference?.length - 1
      ];

    if (array?.response.toLowerCase() === "completed") {
      dispatch(viewForm(array?.submit_data?.uuid)).then(() => {
        navigate(`${routes.FORM_VIEW}/${array?.submit_data?.uuid}`, {
          state: {
            type: "submission",
            uuid: array?.submit_data?.uuid,
            data: array?.form_builder?.form_builder_json,
            json_type: array?.form_builder?.json_type,
            formData: array?.submit_data?.submit_data,
          },
        });
      });
    }
  };

  const viewDataAppForm = (item) => {
    const array = item;

    if (array?.response === "Completed") {
      dispatch(viewForm(array?.submit_data?.uuid)).then(() => {
        navigate(`${routes.FORM_VIEW}/${array?.submit_data?.uuid}`, {
          state: {
            type: "submission",
            uuid: array?.submit_data?.uuid,
            data: array?.form_builder?.form_builder_json,
            json_type: array?.form_builder?.json_type,
            formData: array?.submit_data?.submit_data,
          },
        });
      });
    }
  };

  //
  // resendSingleReffFunc
  // statusReff
  // resendReff

  //   singleCandidateReffLoading
  //
  // singleCandidateInterviewLoading

  const resendData = async (e, id, formCatName, formID) => {
    e.preventDefault();

    // const validateSelect = validate(values)

    // if (Object?.keys(validateSelect)?.length > 0) {
    //     console.log()
    //     setErrors(validateSelect)
    //     dispatch(ShowAlert('Please Select one option', 'error'))
    //     return
    // }

    const localData = {
      form_builder_id: formID,
      uuid: uuid,
      recruiter_id: auth?.results?.recruiter_id,
      candidate_id: candidate?.showCandidate?.results?.id || candidateID,
      workspace_id: auth?.results?.workspace_id,
      form_category_name: formCatName,
      is_resend: 1,
    };
    if (id === 1) {
      dispatch(reqCandidateFormDetail(localData)).then((e) =>
        dispatch(
          getCandidateFormDetail({
            uuid: uuid,
            recruiter_id: auth?.results?.recruiter_id,
            candidate_id: candidate?.showCandidate?.results?.id || candidateID,
            workspace_id: auth?.results?.workspace_id,
            form_category_name: "Job Offer Letter",
          })
        )
      );
    } else if (id === 2) {
      dispatch(reqCandidateFormDetail2(localData)).then((e) =>
        dispatch(
          getCandidateFormDetail2({
            uuid: uuid,
            recruiter_id: auth?.results?.recruiter_id,
            candidate_id: candidate?.showCandidate?.results?.id || candidateID,
            workspace_id: auth?.results?.workspace_id,
            form_category_name: "Application Form",
          })
        )
      );
    } else if (id === 3) {
      dispatch(reqCandidateFormDetail3(localData)).then((e) =>
        dispatch(
          getCandidateFormDetail3({
            uuid: uuid,
            recruiter_id: auth?.results?.recruiter_id,
            candidate_id: candidate?.showCandidate?.results?.id || candidateID,
            workspace_id: auth?.results?.workspace_id,
            form_category_name: "Induction Form",
          })
        )
      );
    } else if (id === 4) {
      dispatch(reqCandidateFormDetail4(localData)).then((e) =>
        dispatch(
          getCandidateFormDetail4({
            uuid: uuid,
            recruiter_id: auth?.results?.recruiter_id,
            candidate_id: candidate?.showCandidate?.results?.id || candidateID,
            workspace_id: auth?.results?.workspace_id,
            form_category_name: "Miscellaneous Form",
          })
        )
      );
    } else {
    }
  };

  const reminderForm = async (e, id, formName) => {
    e.preventDefault();
    // const validateSelect = validate(values);
    // if (Object?.keys(validateSelect)?.length > 0) {
    //   console.log();
    //   setErrors(validateSelect);
    //   dispatch(ShowAlert("Please Select one option", "error"));
    //   return;
    // }

    const localData = {
      // form_builder_id: values?.form_builder_id?.value,
      uuid: uuid,
      recruiter_id: auth?.results?.recruiter_id,
      candidate_id: candidate?.showCandidate?.results?.id || candidateID,
      workspace_id: auth?.results?.workspace_id,
      form_category_name:
        id === 1
          ? applyJobs?.candidateForm1.results?.form_builder_category?.name
          : id === 2
          ? applyJobs?.candidateForm2.results?.form_builder_category?.name
          : id === 3
          ? formName
          : id === 4
          ? applyJobs?.candidateForm4.results?.form_builder_category?.name
          : id === 5
          ? applyJobs?.candidateForm5.results?.form_builder_category?.name
          : "",
      form_builder_id:
        id === 1
          ? applyJobs?.candidateForm1.results?.form_builder?.id
          : id === 2
          ? applyJobs?.candidateForm2.results?.form_builder?.id
          : id === 3
          ? applyJobs?.candidateForm3.results?.form_builder?.id
          : id === 4
          ? applyJobs?.candidateForm4.results?.form_builder?.id
          : id === 5
          ? applyJobs?.candidateForm5.results?.form_builder?.id
          : "",
    };

    if (id === 1) {
      dispatch(resendFormCandidate(localData)).then((e) =>
        dispatch(
          getCandidateFormDetail({
            uuid: uuid,
            recruiter_id: auth?.results?.recruiter_id,
            candidate_id: candidate?.showCandidate?.results?.id || candidateID,
            workspace_id: auth?.results?.workspace_id,
            form_category_name: "Job Offer Letter",
          })
        )
      );
    } else if (id === 2) {
      dispatch(resendFormCandidate2(localData)).then((e) =>
        dispatch(
          getCandidateFormDetail2({
            uuid: uuid,
            recruiter_id: auth?.results?.recruiter_id,
            candidate_id: candidate?.showCandidate?.results?.id || candidateID,
            workspace_id: auth?.results?.workspace_id,
            form_category_name: "Application Form",
          })
        )
      );
    } else if (id === 3) {
      dispatch(resendFormCandidate3(localData)).then((e) =>
        dispatch(
          getCandidateFormDetail3({
            uuid: uuid,
            recruiter_id: auth?.results?.recruiter_id,
            candidate_id: candidate?.showCandidate?.results?.id || candidateID,
            workspace_id: auth?.results?.workspace_id,
            form_category_name: "Induction Form",
          })
        )
      );
    } else if (id === 4) {
      dispatch(resendFormCandidate4(localData)).then((e) =>
        dispatch(
          getCandidateFormDetail4({
            uuid: uuid,
            recruiter_id: auth?.results?.recruiter_id,
            candidate_id: candidate?.showCandidate?.results?.id || candidateID,
            workspace_id: auth?.results?.workspace_id,
            form_category_name: "Miscellaneous Form",
          })
        )
      );
    } else {
    }
  };

  const RendoronValue =
    value === 1 ? (
      rows === undefined && singleCandidate.singleCandidateReffLoading ? (
        <Box className="loader">
          <CircularProgress />
        </Box>
      ) : rows === undefined && !singleCandidate.singleCandidateReffLoading ? (
        <TableRow className="text-center p-3">
          <TableCell colSpan={8} className="text-center">
            No data found
          </TableCell>
        </TableRow>
      ) : rows?.length === 0 ? (
        <TableRow className="text-center p-3">
          <TableCell colSpan={8} className="text-center">
            No data to show
          </TableCell>
        </TableRow>
      ) : (
        <TableBody>
          <>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, i) => (
                <>
                  <TableRow
                    key={i}
                    sx={{ "& td, & th": { border: 0 } }}
                    className={styles.contentBox}
                  >
                    <TableCell align="center">
                      <Box>{row?.id || "-"}</Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box>{row?.full_name || "-"}</Box>
                    </TableCell>
                    <TableCell align="center">
                      {row?.contact_number || "-"}
                    </TableCell>
                    <TableCell align="center">{row?.email || "-"}</TableCell>
                    <TableCell align="center">
                      {row?.job_title || "-"}
                    </TableCell>
                    <TableCell align="center">{row?.response || "-"}</TableCell>

                    <TableCell align="center">
                      {row?.referee_ip === row?.candidate_ip ? (
                        <p
                          onClick={() => showIPModel(row)}
                          style={{ color: "red" }}
                        >
                          IP matched
                        </p>
                      ) : row?.referee_ip === null ? (
                        "-"
                      ) : (
                        <p
                          onClick={() => showIPModel(row)}
                          style={{ color: "#00cfc5" }}
                        >
                          IP not matched
                        </p>
                      )}
                    </TableCell>
                    {!interview && (
                      <TableCell align="center">
                        <Box className={styles.actionsBox}>
                          {/* <Tooltip title="Edit">
                      <Edit />
                    </Tooltip> */}
                          <Tooltip title="Show">
                            <Eye onClick={() => showStatusReff(row)} />
                          </Tooltip>

                          {row?.candidate_response === "completed" &&
                            row?.candidate_form_reference?.length < 1 && (
                              <Box style={{ marginLeft: "8px" }}>
                                <Tooltip title="Verify">
                                  <Verification
                                    // onClick={() => resendReff(row, "verify")}
                                    onClick={() => {
                                      setResend(row?.id);
                                      setOpen3(true);
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            )}
                          {row?.candidate_form_reference[
                            row?.candidate_form_reference?.length - 1
                          ]?.response === "Pending" && (
                            <>
                              <Tooltip title="Reminder">
                                <Notification
                                  onClick={() => reminderReference(row)}
                                />
                              </Tooltip>
                            </>
                          )}
                          {row?.candidate_form_reference[
                            row?.candidate_form_reference?.length - 1
                          ]?.response === "Completed" && (
                            <>
                              <Tooltip title="View form">
                                <Eye onClick={() => viewData(row)} />
                              </Tooltip>
                              {/* <Tooltip title="Resend">
                                <Box style={{ marginLeft: "8px" }}>
                                  <Resend
                                    color="#00CFC5"
                                    onClick={() => {
                                      setResend(row?.id);
                                      setOpen3(true);
                                    }}
                                  />
                                </Box>
                              </Tooltip> */}
                            </>
                          )}

                          {row?.candidate_response === "Completed" && (
                            <>
                              {/* <Tooltip title="Show">
                                <Eye />
                              </Tooltip>

                              <Tooltip title="Download">
                                <Download />
                              </Tooltip> */}
                              {/* <Tooltip title="Send">
                                <Box style={{ marginLeft: "8px" }}>
                                  {" "}
                                  <Resend
                                    color="#00CFC5"
                                   
                                    onClick={() => {
                                      setResend(row?.id);
                                      setOpen3(true);
                                    }}
                                  />
                                </Box>
                              </Tooltip> */}
                            </>
                          )}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                  {resend === row?.id && (
                    <CustomModel open={open3} setOpen={setOpen3}>
                      <ResendModel
                        open={open3}
                        setOpen={setOpen3}
                        candidate_reference_id={row?.id}
                        uuid={uuid}
                        id={5}
                        main_title={"Send Reference Form"}
                        sub_title={"Reference Form Selection"}
                        cata={"Reference Form"}
                        placeholder={"Select reference form"}
                        results={applyjobs?.candidateForm5}
                      />
                    </CustomModel>
                  )}
                </>
              ))}
          </>
        </TableBody>
      )
    ) : value === 2 ? (
      rows === undefined &&
      singleCandidate.singleCandidateCertificatesLoading ? (
        <Box className="loader">
          <CircularProgress />
        </Box>
      ) : rows === undefined &&
        !singleCandidate.singleCandidateCertificatesLoading ? (
        <TableRow className="text-center p-3">
          <TableCell colSpan={5} className="text-center">
            No data found
          </TableCell>
        </TableRow>
      ) : rows?.length === 0 ? (
        <TableRow className="text-center p-3">
          <TableCell colSpan={5} className="text-center">
            No data to show
          </TableCell>
        </TableRow>
      ) : (
        <TableBody>
          {rows
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "& td, & th": { border: 0 } }}
                className={styles.contentBox}
              >
                <TableCell align="center">
                  <Box>
                    <Box>{row?.recruiter_industry?.name || "Industry"}</Box>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  {row?.industry_certification_license?.name || "License name"}
                </TableCell>
                <TableCell align="center">{row?.issue_date || "-"}</TableCell>
                {row?.is_automated_checks !== 1 && (
                  <TableCell align="center">
                    {row?.candidate_response === "completed"
                      ? "Completed"
                      : row?.candidate_response === "pending"
                      ? "Pending"
                      : "-"}
                  </TableCell>
                )}
                {row?.is_automated_checks === 1 && (
                  <TableCell align="center">
                    {row?.is_expired === 1
                      ? "Expired"
                      : row?.is_automated_checks === 1 &&
                        row?.verification_status === 1
                      ? "Verified"
                      : row?.is_automated_checks === 1 &&
                        row?.verification_status === 3
                      ? "Rejected"
                      : row?.is_automated_checks === 1 &&
                        row?.verification_status === 2
                      ? "In Review"
                      : row?.is_automated_checks === 1 &&
                        row?.verification_status === 0
                      ? "Pending"
                      : row?.is_automated_checks !== 1 &&
                        row?.verification_status === 1
                      ? "Verified"
                      : row?.is_automated_checks !== 1
                      ? row?.response
                      : "-"}
                  </TableCell>
                )}
                {!interview && (
                  <TableCell align="center">
                    <Box className={styles.actionsBox}>
                      <Tooltip title="Edit">
                        <Edit onClick={() => editSingleCertificate(row)} />
                      </Tooltip>
                      {row?.industry_certification_license?.is_automated ===
                        1 &&
                      (row?.verification_status === 1 ||
                        row?.verification_status === 3) ? (
                        <Tooltip title="Response for check">
                          <HiOutlineClipboardDocumentList
                            onClick={() => showResponseCertificate(row)}
                          />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      <Tooltip title="Show">
                        <Eye onClick={() => showStatusCertificates(row)} />
                      </Tooltip>

                      {row?.candidate_response === "pending" && (
                        <Tooltip title="Resend">
                          <Resend
                            onClick={() => resendSingleCertificate(row)}
                          />
                        </Tooltip>
                      )}
                      {/* <Tooltip title="Candidate">
                        <Candidate />
                      </Tooltip> */}
                      {(row?.is_automated_checks === 1 &&
                        row?.candidate_response === "completed" &&
                        row?.verification_status === 0) ||
                      row?.is_expired === 1 ? (
                        <Tooltip title="Verify">
                          <Verification
                            onClick={() => verifycertificate(row)}
                          />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      )
    ) : value === 3 ? (
      rows === undefined && singleCandidate.singleCandidateInterviewLoading ? (
        <Box className="loader">
          <CircularProgress />
        </Box>
      ) : rows === undefined &&
        !singleCandidate.singleCandidateInterviewLoading ? (
        <TableRow className="text-center p-3">
          <TableCell colSpan={6} className="text-center">
            No data found
          </TableCell>
        </TableRow>
      ) : rows?.length === 0 ? (
        <TableRow className="text-center p-3">
          <TableCell colSpan={6} className="text-center">
            No data to show
          </TableCell>
        </TableRow>
      ) : (
        <TableBody>
          {rows
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((row, i) => (
              <>
                <TableRow
                  key={i}
                  sx={{ "& td, & th": { border: 0 } }}
                  className={styles.contentBox}
                >
                  <TableCell align="center">
                    <Box>
                      <Box>{row?.title || "title"}</Box>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {row?.start_time || "time"}
                  </TableCell>
                  <TableCell align="center">
                    {row?.date || "20/20/2022"}
                  </TableCell>
                  <TableCell align="center">
                    {row?.type === "online" ? (
                      <a
                        href={row?.zoom_link}
                        target="_blank"
                        style={{
                          color: "#00CFC5",
                          fontFamily: "poppins-semi-bold",
                          fontStyle: "normal",
                          fontSize: "12px",
                          lineHeight: "24px",
                        }}
                      >
                        Click to join meeting
                      </a>
                    ) : (
                      row?.address
                    )}
                  </TableCell>

                  <TableCell align="center">
                    {row?.response || "Pending"}
                  </TableCell>
                  <TableCell align="center">
                    <Box className={styles.actionsBox}>
                      {row?.response !== "accepted" && (
                        <Tooltip title="Edit">
                          <Edit onClick={() => editSingleInterView(row)} />
                        </Tooltip>
                      )}
                      {row?.response !== "accepted" && (
                        <Tooltip title="Delete">
                          <Delete
                            style={{ maxWidht: "17px", maxHeight: "17px" }}
                            onClick={() => openDelteModal(row)}
                          />
                        </Tooltip>
                      )}

                      {row?.response !== "Completed" && (
                        <Tooltip title="Resend">
                          <Resend
                            onClick={() => resendInterVieww(row)}
                            style={{ maxWidth: "15px", maxHeight: "17px" }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
                {selectedValue === row?.id && (
                  <DeleteModel
                    deleteOpen={deleteOpen}
                    setDeleteOpen={setDeleteOpen}
                    item={row}
                    deleteName={"interview"}
                    workspaceId={auth?.results?.workspace_id}
                    candidateId={
                      candidate?.showCandidate?.results?.id || candidateID
                    }
                    deleteFunc={deleteInterviewTable}
                    deletedName={singleCandidate}
                    restFucntion={resetFunction}
                    interviewSection={true}
                  />
                )}
              </>
            ))}
        </TableBody>
      )
    ) : value === 4 ? (
      rows === undefined && singleCandidate.singleCandidateFormLoading ? (
        <Box className="loader">
          <CircularProgress />
        </Box>
      ) : rows === undefined && !singleCandidate.singleCandidateFormLoading ? (
        <TableRow className="text-center p-3">
          <TableCell colSpan={4} className="text-center">
            No Data Found
          </TableCell>
        </TableRow>
      ) : rows?.length === 0 ? (
        <TableRow className="text-center p-3">
          <TableCell colSpan={4} className="text-center">
            No data to show
          </TableCell>
        </TableRow>
      ) : (
        <TableBody>
          {rows
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((row, i) => (
              <>
                <TableRow
                  key={i}
                  sx={{ "& td, & th": { border: 0 } }}
                  className={styles.contentBox}
                >
                  <TableCell align="center">
                    <Box>
                      <Box>{row.form_builder.name || "title"}</Box>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {row?.created_at || "20/20/2022"}
                  </TableCell>

                  <TableCell align="center">
                    {row.response === "Pending" && (
                      <Box className={`${styles.para} text-warning`}>
                        {row.response}
                      </Box>
                    )}
                    {row.response === "Completed" && (
                      <Box className={`${styles.para} text-green`}>
                        {row.response}
                      </Box>
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <Box className={styles.actionsBox}>
                      {row?.response === "Pending" && (
                        <Box>
                          <Tooltip title="Reminder">
                            <Notification
                              onClick={(e) =>
                                reminderForm(
                                  e,
                                  3,
                                  row.form_builder?.form_builder_category?.name,
                                  row.form_builder?.uuid
                                )
                              }
                            />
                          </Tooltip>
                        </Box>
                      )}

                      {row?.response === "Completed" && (
                        <Box className="mr-2">
                          <Tooltip title="View form">
                            <Eye onClick={() => viewDataAppForm(row)} />
                          </Tooltip>
                        </Box>
                      )}

                      {row?.response === "Completed" && (
                        <Box>
                          <Tooltip title="Resend">
                            <Resend
                              onClick={(e) =>
                                resendData(
                                  e,
                                  3,
                                  row?.form_builder?.form_builder_category
                                    ?.name,
                                  row?.form_builder?.id
                                )
                              }
                            />
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
                {selectedValue === row?.id && (
                  <DeleteModel
                    deleteOpen={deleteOpen}
                    setDeleteOpen={setDeleteOpen}
                    item={row}
                    deleteName={"interview"}
                    workspaceId={auth?.results?.workspace_id}
                    candidateId={
                      candidate?.showCandidate?.results?.id || candidateID
                    }
                    deleteFunc={deleteInterviewTable}
                    deletedName={singleCandidate}
                    restFucntion={resetFunction}
                    interviewSection={true}
                  />
                )}
              </>
            ))}
        </TableBody>
      )
    ) : value === 5 ? (
      rows === undefined && singleCandidate.singleCandidateFormLoading1 ? (
        <Box className="loader">
          <CircularProgress />
        </Box>
      ) : rows === undefined && !singleCandidate.singleCandidateFormLoading1 ? (
        <TableRow className="text-center p-3">
          <TableCell colSpan={4} className="text-center">
            No data Found
          </TableCell>
        </TableRow>
      ) : rows?.length === 0 ? (
        <TableRow className="text-center p-3">
          <TableCell colSpan={4} className="text-center">
            No data to show
          </TableCell>
        </TableRow>
      ) : (
        <TableBody>
          {rows
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((row, i) => (
              <>
                <TableRow
                  key={i}
                  sx={{ "& td, & th": { border: 0 } }}
                  className={styles.contentBox}
                >
                  <TableCell align="center">
                    <Box>
                      <Box>{row.form_builder.name || "title"}</Box>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {row?.created_at || "20/20/2022"}
                  </TableCell>

                  <TableCell align="center">
                    {row.response === "Pending" && (
                      <Box className={`${styles.para} text-warning`}>
                        {row.response}
                      </Box>
                    )}
                    {row.response === "Completed" && (
                      <Box className={`${styles.para} text-green`}>
                        {row.response}
                      </Box>
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <Box className={styles.actionsBox}>
                      {row?.response === "Pending" && (
                        <Box>
                          <Tooltip title="Reminder">
                            <Notification
                              onClick={(e) =>
                                reminderForm(
                                  e,
                                  4,
                                  row.form_builder?.form_builder_category?.name,
                                  row.form_builder?.uuid
                                )
                              }
                            />
                          </Tooltip>
                        </Box>
                      )}

                      {row?.response === "Completed" && (
                        <Box className="mr-2">
                          <Tooltip title="View form">
                            <Eye onClick={() => viewDataAppForm(row)} />
                          </Tooltip>
                        </Box>
                      )}

                      {row?.response === "Completed" && (
                        <Box>
                          <Tooltip title="Resend">
                            <Resend
                              onClick={(e) =>
                                resendData(
                                  e,
                                  4,
                                  row?.form_builder?.form_builder_category
                                    ?.name,
                                  row?.form_builder?.id
                                )
                              }
                            />
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
                {selectedValue === row?.id && (
                  <DeleteModel
                    deleteOpen={deleteOpen}
                    setDeleteOpen={setDeleteOpen}
                    item={row}
                    deleteName={"interview"}
                    workspaceId={auth?.results?.workspace_id}
                    candidateId={
                      candidate?.showCandidate?.results?.id || candidateID
                    }
                    deleteFunc={deleteInterviewTable}
                    deletedName={singleCandidate}
                    restFucntion={resetFunction}
                    interviewSection={true}
                  />
                )}
              </>
            ))}
        </TableBody>
      )
    ) : value === 6 ? (
      rows === undefined && singleCandidate.singleCandidateFormLoading2 ? (
        <Box className="loader">
          <CircularProgress />
        </Box>
      ) : rows === undefined && !singleCandidate.singleCandidateFormLoading2 ? (
        <TableRow className="text-center p-3">
          <TableCell colSpan={4} className="text-center">
            No data found
          </TableCell>
        </TableRow>
      ) : rows?.length === 0 ? (
        <TableRow className="text-center p-3">
          <TableCell colSpan={4} className="text-center">
            No data to show
          </TableCell>
        </TableRow>
      ) : (
        <TableBody>
          {rows
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((row, i) => (
              <>
                <TableRow
                  key={i}
                  sx={{ "& td, & th": { border: 0 } }}
                  className={styles.contentBox}
                >
                  <TableCell align="center">
                    <Box>
                      <Box>{row.form_builder.name || "title"}</Box>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {row?.created_at || "20/20/2022"}
                  </TableCell>

                  <TableCell align="center">
                    {row.response === "Pending" && (
                      <Box className={`${styles.para} text-warning`}>
                        {row.response}
                      </Box>
                    )}
                    {row.response === "Completed" && (
                      <Box className={`${styles.para} text-green`}>
                        {row.response}
                      </Box>
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <Box className={styles.actionsBox}>
                      {row?.response === "Pending" && (
                        <Box>
                          <Tooltip title="Reminder">
                            <Notification
                              onClick={(e) =>
                                reminderForm(
                                  e,
                                  1,
                                  row.form_builder?.form_builder_category?.name,
                                  row.form_builder?.uuid
                                )
                              }
                            />
                          </Tooltip>
                        </Box>
                      )}
                      {row?.response === "Pending" && (
                        <Box>
                          <Tooltip title="Reminder">
                            <Notification onClick={(e) => reminderForm(e)} />
                          </Tooltip>
                        </Box>
                      )}

                      {row?.response === "Completed" && (
                        <Box>
                          <Tooltip title="Resend">
                            <Resend
                              onClick={(e) =>
                                resendData(
                                  e,
                                  1,
                                  row?.form_builder?.form_builder_category
                                    ?.name,
                                  row?.form_builder?.id
                                )
                              }
                            />
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
                {selectedValue === row?.id && (
                  <DeleteModel
                    deleteOpen={deleteOpen}
                    setDeleteOpen={setDeleteOpen}
                    item={row}
                    deleteName={"interview"}
                    workspaceId={auth?.results?.workspace_id}
                    candidateId={
                      candidate?.showCandidate?.results?.id || candidateID
                    }
                    deleteFunc={deleteInterviewTable}
                    deletedName={singleCandidate}
                    restFucntion={resetFunction}
                    interviewSection={true}
                  />
                )}
              </>
            ))}
        </TableBody>
      )
    ) : value === 7 ? (
      rows === undefined && singleCandidate.singleCandidateFormLoading3 ? (
        <Box className="loader">
          <CircularProgress />
        </Box>
      ) : rows === undefined && !singleCandidate.singleCandidateFormLoading3 ? (
        <TableRow className="text-center p-3">
          <TableCell colSpan={4} className="text-center">
            No data found
          </TableCell>
        </TableRow>
      ) : rows?.length === 0 ? (
        <TableRow className="text-center p-3">
          <TableCell colSpan={4} className="text-center">
            No data to show
          </TableCell>
        </TableRow>
      ) : (
        <TableBody>
          {rows
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((row, i) => (
              <>
                <TableRow
                  key={i}
                  sx={{ "& td, & th": { border: 0 } }}
                  className={styles.contentBox}
                >
                  <TableCell align="center">
                    <Box>
                      <Box>{row.form_builder.name || "title"}</Box>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {row?.created_at || "20/20/2022"}
                  </TableCell>

                  <TableCell align="center">
                    {row.response === "Pending" && (
                      <Box className={`${styles.para} text-warning`}>
                        {row.response}
                      </Box>
                    )}
                    {row.response === "Completed" && (
                      <Box className={`${styles.para} text-green`}>
                        {row.response}
                      </Box>
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <Box className={styles.actionsBox}>
                      {row?.response === "Pending" && (
                        <Box>
                          <Tooltip title="Reminder">
                            <Notification
                              onClick={(e) =>
                                reminderForm(
                                  e,
                                  2,
                                  row.form_builder?.form_builder_category?.name,
                                  row.form_builder?.uuid
                                )
                              }
                            />
                          </Tooltip>
                        </Box>
                      )}
                      {row?.response === "Completed" && (
                        <Box className="mr-2">
                          <Tooltip title="View form">
                            <Eye onClick={() => viewDataAppForm(row)} />
                          </Tooltip>
                        </Box>
                      )}

                      {row?.response === "Completed" && (
                        <Box>
                          <Tooltip title="Resend">
                            <Resend
                              onClick={(e) =>
                                resendData(
                                  e,
                                  2,
                                  row?.form_builder?.form_builder_category
                                    ?.name,
                                  row?.form_builder?.id
                                )
                              }
                            />
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
                {selectedValue === row?.id && (
                  <DeleteModel
                    deleteOpen={deleteOpen}
                    setDeleteOpen={setDeleteOpen}
                    item={row}
                    deleteName={"interview"}
                    workspaceId={auth?.results?.workspace_id}
                    candidateId={
                      candidate?.showCandidate?.results?.id || candidateID
                    }
                    deleteFunc={deleteInterviewTable}
                    deletedName={singleCandidate}
                    restFucntion={resetFunction}
                    interviewSection={true}
                  />
                )}
              </>
            ))}
        </TableBody>
      )
    ) : (
      ""
    );

  return (
    <Box
      className={styles.candidateTable}
      style={{
        minHeight:
          rows === undefined && singleCandidate?.singleCandidateReffLoading
            ? "300px"
            : rows === undefined &&
              singleCandidate?.singleCandidateCertificatesLoading
            ? "300px"
            : rows === undefined &&
              singleCandidate?.singleCandidateInterviewLoading
            ? "300px"
            : "auto",
      }}
    >
      <CustomModel open={openReff} setOpen={setOpenReff}>
        <ShowReff
          open={openReff}
          setOpen={setOpenReff}
          data={singleCandidate?.statusReff?.results}
        />
      </CustomModel>

      <CustomModel open={openMatchedIP} setOpen={setOpenMatchedIP}>
        <IPMatchedPopup
          open={openMatchedIP}
          setOpen={setOpenMatchedIP}
          data={singleCandidate?.statusReff?.results}
        />
      </CustomModel>

      <CustomModel open={openCertificate} setOpen={setOpenCertificate}>
        <ShowReff
          open={openCertificate}
          setOpen={setOpenCertificate}
          name={"License and Certificate Information"}
          data={singleCandidate?.statusCertificate?.results}
          certificate={true}
        />
      </CustomModel>
      <Modal
        open={open2}
        setOpen={setOpen2}
        // data={data}
        // setData={setData}
      >
        <CheckoutForm
          open={open2}
          setOpen={setOpen2}
          data={singleCandidate?.statusCertificate?.results}
          // setData={setData}
          // updatedData={updatedData}
        />
      </Modal>

      <CustomModel open={openResponse} setOpen={setOpenResponse}>
        <ResponsePopup
          open={openResponse}
          setOpen={setOpenResponse}
          name={"Response for background checks"}
          data={singleCandidate?.statusCertificate?.results}
          certificate={true}
        />
      </CustomModel>

      <Box className={styles.customBox}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{ "& td, & th": { border: 0 } }}
                className={styles.headingBox}
              >
                {titles?.map((e, i) => (
                  <TableCell align="center" key={i}>
                    {e}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {RendoronValue}
          </Table>
        </TableContainer>
        {rows?.length > 5 && (
          <TablePagination
            rowsPerPageOptions={[]}
            component="Box"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </Box>
  );
};

export default CandidateTable;

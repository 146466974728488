import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import "./style.css";
import { BsChevronDoubleRight, BsChevronRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  updateComanpyOnIndustry,
  getAllCatagories,
  unSelectIndustry,
} from "../../../../store/actions/industryActions";

const SecuritySection = ({ open, setOpen, isSelect, setIsSelect, indid }) => {
  const adminIndustryList = useSelector((state) => state?.industry);
  const industry = useSelector((state) => state?.industry?.singleIndustry);
  const updateIndustry = useSelector(
    (state) => state?.industry?.updateIndustry
  );
  const unSelectIndustryState = useSelector(
    (state) => state?.industry?.unSelectIndustry
  );

  const loading = useSelector((state) => state?.industry?.loading);
  const auth = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const submitData = () => {
    dispatch(
      updateComanpyOnIndustry({
        uuid: auth?.results?.company_detail?.uuid,
        industry_id: indid,
      })
    );
    dispatch(
      getAllCatagories({
        is_select: isSelect,
      })
    );
    setIsSelect(1);
  };
  const unselectIndustry = () => {
    dispatch(
      unSelectIndustry({
        uuid: auth?.results?.company_detail?.uuid,
        industry_id: indid,
      })
    );
    dispatch(
      getAllCatagories({
        is_select: isSelect,
      })
    );
    setIsSelect(0);
  };

  useEffect(() => {
    if (updateIndustry?.success || unSelectIndustryState?.success) {
      setOpen(false);
    }
  }, [
    updateIndustry,
    updateIndustry?.success,
    unSelectIndustryState,
    updateIndustry?.success,
  ]);
  return (
    <Grid container spacing={4}>
      <Grid item sm={6}>
        <Box>
          <Box marginBottom={1}>
            <Typography variant="h4" className="industrymodel_heading_h4">
              {/* {Data.title} */}
              {industry?.results?.name}
            </Typography>
          </Box>
          <Typography variant="body1" className="industrymodel_para_body">
            {/* {Data.data} */}
            {industry?.results?.short_description}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={6}>
        <Box>
          <Box marginBottom={1}>
            <Typography variant="h4" className="industrymodel_heading_h4">
              Key Certificates/Licenses:
            </Typography>
          </Box>
          <Box marginLeft={3} className={"boxHight"}>
            <div
              dangerouslySetInnerHTML={{
                __html: industry?.results?.description,
              }}
            ></div>
            {/* <ul>
              {industry?.results?.industry_certification_license?.map(
                (e, i) => (
                  <li key={i}>{e?.name}</li>
                )
              )}
            </ul> */}
          </Box>
          {isSelect === 1 ? (
            <Box className="button-primary mw-100">
              <Button disabled={loading} onClick={() => unselectIndustry()}>
                {loading ? (
                  <p style={{ color: "white" }}>Loading...</p>
                ) : (
                  "Unselect Industry"
                )}
              </Button>
            </Box>
          ) : (
            <Box className={"button-primary mw-100"}>
              <Button disabled={loading} onClick={() => submitData()}>
                {loading ? (
                  <p style={{ color: "white" }}>Loading...</p>
                ) : (
                  "Select Industry"
                )}
              </Button>
            </Box>
          )}
        </Box>
        {/* <Box marginTop={12} className="industrymodel_btn_box">
          <Button className="industrymodel_btn" variant="contained">
            continue <BsChevronDoubleRight />
          </Button>
        </Box> */}
      </Grid>
    </Grid>
  );
};

export default SecuritySection;

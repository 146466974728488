import {
  Badge,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import logo from "../../assets/dashboard/logo.svg";
import { ReactComponent as Notification } from "../../assets/dashboard/bell.svg";
import Home from "../../assets/icons/home.svg";
import NotificationsIcon from "../../assets/icons/notifications.svg";
import Shopping from "../../assets/icons/shopping.svg";
import { BsChevronDown } from "react-icons/bs";
import { routes } from "../../routes";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../store/actions/alertActions";
import { Logout } from "../../store/actions/authActions";
import { editCompany } from "../../store/actions/companyActions";
import { RiHome2Line } from "react-icons/ri";
import { FiShoppingCart } from "react-icons/fi";
import ChangePasswordModal from "../../../src/components/candidate_screens/profile/Modal/change-password";
import LogoutModal from "../../../src/components/candidate_screens/profile/Modal/logout-popup";
import {
  notification,
  readNotification,
  notificationpagination,
} from "../../api/notification/notification";
import {
  editCandidateFunc,
  ShowCandidateFunc,
} from "../../store/actions/recruiterCandidateActions";
import axios from "axios";
import EndPoints from "../../api/EndPoints";

const Header = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [changePassword, setChangePassword] = React.useState(false);
  const [logoutPopUp, setLogoutPopUp] = React.useState(false);
  const company = useSelector((state) => state?.company);
  const auth = useSelector((state) => state?.auth);
  const profiles = useSelector((state) => state?.rprofile);
  const pricing = useSelector((state) => state?.pricing);
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const isNotificationMenu = Boolean(anchorEl2);
  const isCartMenu = Boolean(anchorEl3);

  const [notificationData, setnotificationData] = useState([]);
  const [notificationCount, setnotificationCount] = useState([]);
  const [pages, setpages] = useState(1);
  // const [notifictionIconClick, setnotifictionIconClick] = useState(false)
  const scrollRef = useRef();
  let page = pages;
  const handleScroll = () => {
    if (scrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      if (scrollTop + clientHeight === scrollHeight) {
        page++;
        setpages(page);

        getNotification(page);
      }
    }
  };

  const getNotification = async (page) => {
    try {
      let result = await notification(page);
      let data = result?.data?.results?.notification_data?.data;
      let notifiactionCountData = result?.data?.results?.unread_count;
      if (page === 1) {
        setnotificationData(data);
      }
      if (page > 1 && data?.length > 0) {
        setnotificationData((preVal) => {
          return [...preVal, ...data];
        });
      }

      setnotificationCount(notifiactionCountData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getNotification(1);
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleCloseNotification = () => {
    setAnchorEl2(null);
    setpages(1);
  };
  const handleCloseCart = () => {
    setAnchorEl3(null);
  };
  const profile = () => {
    navigate(
      auth?.user === "candidate" ? routes.CANDIDATE_PROFILE : routes.PROFILE
    );
    setAnchorEl(null);
  };
  const logout = () => {
    dispatch(Logout()).then(() => {
      navigate(routes.LOGIN);
    });

    setAnchorEl(null);
  };
  const editCompanyy = () => {
    dispatch(editCompany(company.allCompany.results.uuid)).then(() => {
      navigate(routes.COMPANY);
    });

    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileNotificationOpen = (event) => {
    getNotification(1);
    setAnchorEl2(event.currentTarget);
  };
  const handleCartMenu = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleNotificationClick = (e) => {
    readNotification(e);
    if (e.notifiable_type === "1") {
      navigate(`${routes.ORDER_DETAIL}/${e?.uuid}`, {
        state: {
          id: "#" + e.data.plan_id,
        },
      });
    } else if (
      e.notifiable_type === "2" ||
      e.notifiable_type === "19" ||
      e.notifiable_type === "18" ||
      e.notifiable_type === "3"
    ) {
      navigate(`${routes.REQUEST_LIST}/`);
    } else if (
      e.notifiable_type === "21" ||
      e.notifiable_type === "22" ||
      e.notifiable_type === "25" ||
      e.notifiable_type === "26" ||
      e.notifiable_type === "24" ||
      e.notifiable_type === "10" ||
      e.notifiable_type === "27" ||
      e.notifiable_type === "30"
    ) {
      dispatch(ShowCandidateFunc(e?.data?.candidate_uuid)).then(() =>
        navigate(`${routes.SINGLE_CANDIDATE}/${e?.data?.candidate_uuid}`)
      );
    } else if (e.notifiable_type === "4" || e.notifiable_type === "7") {
      navigate(`${routes.REF}/`);
    } else if (e.notifiable_type === "8") {
      navigate(`${routes.CANDIDATE_LICENSE}/`);
    } else if (e.notifiable_type === "20") {
      navigate(`${routes.CANDIDATE_INTERVIEW}/`);
    } else if (e.notifiable_type === "28") {
      navigate(`${routes.CONTACT}/`);
    } else if (
      e.notifiable_type === "44" ||
      e.notifiable_type === "43" ||
      e.notifiable_type === "42" ||
      e.notifiable_type === "41"
    ) {
      navigate(`${routes.ALL_DOCUMENTS}`);
    }
    setAnchorEl2();
  };

  const renderNotification = (
    <Menu
      sx={{ width: 420, maxWidth: "100%" }}
      anchorEl={anchorEl2}
      open={isNotificationMenu}
      onClick={() => getNotification(page)}
      onClose={handleCloseNotification}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <MenuList
        className="notification-list"
        onScroll={handleScroll}
        ref={scrollRef}
      >
        {notificationData?.length !== 0 ? (
          notificationData?.map((e, i) => {
            if (e.unread === 1) {
              return (
                <MenuItem
                  key={i}
                  onClick={() => handleNotificationClick(e)}
                  className="new"
                >
                  {e.message} <span>{e.time_ago}</span>
                </MenuItem>
              );
            }
            return (
              <MenuItem key={i} onClick={() => handleNotificationClick(e)}>
                {e.message} <span>{e.time_ago}</span>
              </MenuItem>
            );
          })
        ) : (
          <MenuItem>No Notification Yet!</MenuItem>
        )}
      </MenuList>
    </Menu>
  );

  const renderCart = (
    <Menu
      sx={{ width: 420, maxWidth: "100%" }}
      anchorEl={anchorEl3}
      open={isCartMenu}
      onClose={handleCloseCart}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {/* <MenuList>
        <MenuItem>
          Price: <b> {pricing?.price?.toFixed(2)}</b>
        </MenuItem>
      </MenuList> */}
    </Menu>
  );
  const menuId = "primary-search-account-menu";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={profile}>Profile</MenuItem>
      <MenuItem
        onClick={() => {
          setChangePassword(true);
        }}
      >
        Change Password
      </MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}

      {auth?.results?.workspace_type === "recruitment" && (
        <MenuItem onClick={editCompanyy}>Company Detail</MenuItem>
      )}

      <MenuItem
        onClick={() => {
          setLogoutPopUp(true);
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Box className={styles.header}>
        <Box as="nav">
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} sm={6}>
              {/* <img src={logo} alt="logo" /> */}
              <Typography component={"h4"}>
                {auth?.user === "recruitment"
                  ? company?.allCompany?.results?.name || "Company Name"
                  : ""}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              // paddingRight={1.7}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <IconButton sx={{ fontSize: "1rem", padding: "11px" }}>
                <Link to={routes?.HOME}>
                  <img src={Home} className={styles.homebtn} />
                </Link>
              </IconButton>
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
                className={styles.notification}
                onClick={handleProfileNotificationOpen}
              >
                <Badge
                  badgeContent={notificationCount}
                  color="error"
                  className="badge"
                >
                  <img src={NotificationsIcon} className={styles.homebtn} />
                </Badge>{" "}
              </IconButton>
              {auth?.user !== "candidate" && (
                <IconButton sx={{ fontSize: "1rem", padding: "11px" }}>
                  <Link to={"/dashboard/pricing"}>
                    <img src={Shopping} className={styles.homebtn} />
                  </Link>
                  {/* <Badge badgeContent={0} color="error">
    <img src={Shopping} className={styles.homebtn} />
  </Badge> */}{" "}
                </IconButton>
              )}

              <Box
                marginLeft={1.5}
                onClick={handleProfileMenuOpen}
                className={styles.profileMenu}
              >
                <Typography variant="h5">
                  {profiles?.profile?.results?.first_name}
                </Typography>
                <BsChevronDown />
              </Box>
            </Grid>
          </Grid>
        </Box>
        {renderMenu}
        {renderNotification}
        {renderCart}
        <ChangePasswordModal
          openModal={changePassword}
          setOpenModal={setChangePassword}
        />
        <LogoutModal openModal={logoutPopUp} setOpenModal={setLogoutPopUp} />
      </Box>
    </>
  );
};

export default Header;

import { Resizable } from "re-resizable";
import React from "react";
import Draggable from "react-draggable";

import "../../../assets/sinner_css/DropDate.css";
import Calender from "../../../assets/sinner/calender.svg";

/***
 * drag and drop date component
 */
function DropDate({
  onElementDelete,
  elt,
  defaultPosition = { x: 10, y: 10 },
  signer,
  setSelectedInputField,
  selectedInputField,
  onStop,
}) {
  const fieldId = elt.id || 0;

  const selectedStyle = {
    background: selectedInputField?.id === fieldId ? "red" : "white",
  };
  const focusedStyle = {
    position: "absolute",
    background: "#dfdfdf",
    border:
      selectedInputField?.id === fieldId ? "2px solid red" : "1px solid black",
  };

  return (
    <Draggable
      bounds='.pdfBg'
      defaultPosition={defaultPosition}
      handle='.dragArea'
      onStop={(x, y) => {
        elt.positionFromParentLeft = y.x;
        elt.positionFromParentTop = y.y;
        setSelectedInputField(elt);
        onStop(elt, selectedInputField);
      }}>
      <div style={focusedStyle} title={`${signer.name}'s Input`}>
        <Resizable
          defaultSize={{ width: elt.width, height: elt.height }}
          maxHeight={100}
          maxWidth={500}
          minHeight={20}
          minWidth={50}
          onResizeStop={(x, y, z, size) => {
            elt.width = z.clientWidth;
            elt.height = z.clientHeight;
          }}>
          <div
            className='dragArea'
            style={{
              width: "100%",
              background: elt.background,
              height: "100%",
              overflow: "hidden",
            }}>
            <p
              className='bhBox'
              style={{
                fontSize: elt.fontSize,
                fontFamily: elt.fontFamily,
              }}>
              <img alt='logo' className='App-logo' src={Calender} />
            </p>
          </div>
        </Resizable>

        <div className='dots one' style={selectedStyle}></div>
        <div
          className='closeicon'
          onClick={() => onElementDelete(elt, signer)}
          style={{ backgroundColor: "red", color: "white" }}
          title='Delete'></div>
        <div className='dots three' style={selectedStyle}></div>
        <div className='dots four' style={selectedStyle}></div>
      </div>
    </Draggable>
  );
}

export default DropDate;

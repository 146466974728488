import Hashids from "hashids";

export const uid = () => {
  return (
    new Date().getTime() +
    Date.now().toString(36) +
    Math.random().toString(36).substr(2)
  );
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const parseNumberOrDefault = (value, defaultValue = 0) => {
  const num = parseInt(value, 10);
  const n = isNaN(num) ? defaultValue : num;
  return n;
};

export const getDateFromTimestamp = (datetime) => {
  const date = new Date(datetime).toLocaleDateString();
  return date;
};

export const HideNumber = () => {
  /**
   * never change '16' in hashids it is used to ofscute the numbers into string
   * changing this will break whole website.
   * thankyou.
   */
  const hashIds = new Hashids("", 16);

  function encrypt(num) {
    const str = hashIds.encode(num);
    return str;
  }

  function decrypt(str) {
    const num = hashIds.decode(str);
    if (num.length > 0) {
      return num[0];
    }
    return 0;
  }
  return {
    encrypt,
    decrypt,
  };
};

export async function getPublicKeyFromWalletAddress(account) {
  const keyB64 = await window.ethereum.request({
    method: "eth_getEncryptionPublicKey",
    params: [account],
  });
  console.log("keyB64", keyB64);
  const publicKey = Buffer.from(keyB64, "base64");
  return publicKey;
}

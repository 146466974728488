import axios from "axios";
import EndPoints from "../EndPoints";
import axiosConfig from "../axiosConfig";

export const storeSigningService = (body) => {
  return axiosConfig.post(EndPoints.StoreForm, body);
};

export const EDocListing = (query) => {
  return axiosConfig.post(`${EndPoints.getEDocFormList}?${query}`);
};

export const EDocView = (query, param) => {
  return axiosConfig.post(`${EndPoints.viewSingleSinner}/${param}?${query}`);
};

export const UpdateEDoc = (query) => {
  return axiosConfig.post(`${EndPoints.updateDocument}?${query}`);
};

export const DeleteEDocument = (query) => {
  return axiosConfig.post(`${EndPoints.deleteEDoc}?${query}`);
};

export const UpdateDocumentStatus = (query) => {
  return axiosConfig.post(`${EndPoints.updateStatus}?${query}`);
};

export const subScriptionStatus = (query) => {
  return axiosConfig.post(`${EndPoints.subScription}?${query}`);
};

export const getDraftById = (id) => {
  return axiosConfig.post(`${EndPoints.getDraftById}/${id}`);
};

export const updatedDraft = (body) => {
  return axiosConfig.post(`${EndPoints.updatedDrafted}`, body);
};

export const storeTemplate = (body) => {
  return axiosConfig.post(`${EndPoints.storeTemplate}`, body);
};

export const getFormCategoryDocBuilder = async (payload) => {
  return axiosConfig.post(EndPoints.getFormCategory, payload);
};

export const EDocTemplateListing = (query) => {
  return axiosConfig.post(`${EndPoints.templateListing}?${query}`);
};

export const EDocTemplateDetails = (id) => {
  return axiosConfig.post(`${EndPoints.templateDetails}/${id}`);
};

export const updateTemplate = async (payload) => {
  return axiosConfig.post(EndPoints.updateTemplate, payload);
};
export const deleteTemplate = async (payload) => {
  return axiosConfig.post(EndPoints.deleteTemplate, payload);
};

export const getGuestDoc = async (id, email) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${EndPoints.guestViewDoc}/${id}?email=${email}`
  );
};

export const UpdateGuestEDoc = (query) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${EndPoints.guestDocSign}?${query}`
  );
};

import { useDrag } from "react-dnd";
import { SIDEBAR_ITEM } from "../../../store/constants/constants.js";
import { FieldItems, ItemTypes } from "./ItemTypes.js";
import { MdOutlineRadioButtonChecked } from "react-icons/md";
import "./style.module.scss";
import {
  TbPilcrow,
  TbHeading,
  TbCursorText,
  TbMail,
  TbPhone,
  TbPlus,
  TbCalendar,
  TbMist,
  TbSquareRoundedChevronDown,
  TbCheckbox,
  TbUpload,
  TbStar,
  TbForms,
  TbSignature,
  TbColumns2,
  TbExternalLink,
  TbLink,
  TbPhoto,
  TbTable,
  TbBorderHorizontal,
} from "react-icons/tb";
const style = {
  display: "block",
  border: "1px dashed #a5a5a5",
  padding: "0.5rem 1rem",
  backgroundColor: "white",
  cursor: "move",
  marginBottom: "16px",
};
export const Box = ({ data }) => {
  const [, drag] = useDrag(() => ({
    type: SIDEBAR_ITEM,
    item: data,
  }));

  return (
    <div ref={drag} style={style}>
      {data.value.icon === "TbPilcrow" ? (
        <TbPilcrow />
      ) : data.value.icon === "TbHeading" ? (
        <TbHeading />
      ) : data.value.icon === "TbCursorText" ? (
        <TbCursorText />
      ) : data.value.icon === "TbMail" ? (
        <TbMail />
      ) : data.value.icon === "TbPhone" ? (
        <TbPhone />
      ) : data.value.icon === "TbPlus" ? (
        <TbPlus />
      ) : data.value.icon === "TbCalendar" ? (
        <TbCalendar />
      ) : data.value.icon === "TbMist" ? (
        <TbMist />
      ) : data.value.icon === "TbSquareRoundedChevronDown" ? (
        <TbSquareRoundedChevronDown />
      ) : data.value.icon === "MdOutlineRadioButtonChecked" ? (
        <MdOutlineRadioButtonChecked />
      ) : data.value.icon === "TbCheckbox" ? (
        <TbCheckbox />
      ) : data.value.icon === "TbUpload" ? (
        <TbUpload />
      ) : data.value.icon === "TbStar" ? (
        <TbStar />
      ) : data.value.icon === "TbForms" ? (
        <TbForms />
      ) : data.value.icon === "TbSignature" ? (
        <TbSignature />
      ) : data.value.icon === "TbColumns2" ? (
        <TbColumns2 />
      ) : data.value.icon === "TbLink" ? (
        <TbLink />
      ) : data.value.icon === "TbExternalLink" ? (
        <TbExternalLink />
      ) : data.value.icon === "TbPhoto" ? (
        <TbPhoto />
      ) : data.value.icon === "TbTable" ? (
        <TbTable />
      ) : data.value.icon === "TbBorderHorizontal" ? (
        <TbBorderHorizontal />
      ) : (
        ""
      )}

      {data.value.title}
    </div>
  );
};

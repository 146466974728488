import {
  GET_ALL_BLOGS_HOMEPAGE,
  GET_ALL_BLOGS_HOMEPAGE_START,
  GET_ALL_BLOGS_HOMEPAGE_FAIL,
  SINGLE_BLOG,
  SINGLE_BLOG_START,
  SINGLE_BLOG_FAIL,
  LOADING_TRUE,
  LOADING_FALSE,
  RESET_MODAL,
} from "../constants/constants";

const initialState = {
  loading: false,
  allBlogs: null,
  singleBlog: {},
};

export const homeBlogReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_TRUE:
      return { ...state, loading: true };
    case LOADING_FALSE:
      return { ...state, loading: false };
    case GET_ALL_BLOGS_HOMEPAGE:
      return { ...state, allBlogs: payload, loading: false };
    case GET_ALL_BLOGS_HOMEPAGE_START:
      return { ...state, allBlogs: null, loading: true };
    case GET_ALL_BLOGS_HOMEPAGE_FAIL:
      return { ...state, allBlogs: null, loading: false };
    case SINGLE_BLOG:
      return { ...state, singleBlog: payload, loading: false };
    case SINGLE_BLOG_START:
      return { ...state, singleBlog: null, loading: true };
    case SINGLE_BLOG_FAIL:
      return { ...state, singleBlog: null, loading: false };

    case RESET_MODAL:
      return {
        ...state,
        singleBlog: {},
      };

    default:
      return state;
  }
};

import React from "react";
import styles from "./styles.module.scss";
import { Box, Grid, Typography, Button } from "@mui/material";
import { BsSearch } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import FaqsCard from "../single-faqs-card";
import imgCenter from "../../../assets/faqs/imgCenter.svg";
import imgLeft from "../../../assets/faqs/imgLeft.svg";
import imgRight from "../../../assets/faqs/imgRight.svg";

const CandidateFaqs = () => {
  const navigate = useNavigate();
  const faqsData = [
    {
      title:
        "Can you tell me about the electronic document feature on the Worecs platform?",
      content:
        "As a platform that is designed to streamline administration workflow, Worecs has an electronic document signing feature on the platform to assist recruiters and business users in completing important documents such as contracts and employee onboarding packages. As a candidate, any third-party document that is sent to you should always be carefully reviewed. Signatures can make documents potentially binding agreements, so it is quite important to always read through terms and conditions of an agreement. If you are satisfied with the information that has been provided to you, our electronic signature platform makes documents easy to sign and send back to the third party providing the document.",
    },
    {
      title: "What features are available in a candidate profile in Worecs?",
      content:
        "From a candidate perspective, the Worecs platform provides you a platform to connect with potential employers. As recruiters are the main focus of the platform, we provide the opportunity for all companies to upload job posts which the candidates may apply for on the job board page located on the main page in the jobs section.We also provide you options in the candidate profile to upload your relevant working licenses and CV so that this may be shared with potential employers for key job opportunities.",
    },
    {
      title: "Is my data secure?",
      content:
        "Yes, Worecs takes data security very seriously, and blockchain based servers are used to limit any third party hacking attempts. ",
    },
  ];
  return (
    <Box className={styles.parent}>
      <Box className={styles.header}>
        <Typography variant="h4">Help Desk</Typography>
      </Box>
      <Box className={styles.faq}>
        <Box className={styles.faqHeader}>
          <Typography variant="h5">FAQ's</Typography>
          {/* <Box className={styles.searchBox}>
            <BsSearch />
            <input type="text" placeholder="Search..." />
          </Box> */}
        </Box>
        <Box className={styles.faqContent}>
          <Typography>
            Get answers to the most frequently asked questions about worecs
          </Typography>
          <Link to={routes.FAQS}>
            Please chat with our exceptional customer service.
          </Link>
        </Box>
        <Box className={styles.cardContainer}>
          <Grid container spacing={2}>
            {faqsData.map((data, i) => {
              return (
                <Grid item xs={12} md={6}>
                  <FaqsCard
                    title={data.title}
                    content={data.content}
                    key={data.title}
                    style={{
                      marginLeft: i % 2 === 0 ? "auto " : "",
                      "@media (max-width:900px)": {
                        marginLeft: "unset",
                        maxWidth: "100%",
                      },
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box className={styles.supportSection}>
          <Box className={styles.supportImages}>
            <img src={imgRight} alt="imgRight" className={styles.leftImg} />
            <img src={imgCenter} alt="imgCenter" className={styles.centerImg} />
            <img src={imgLeft} alt="imgLeft" className={styles.rightImg} />
          </Box>
          <Box className={styles.supportContent}>
            <Typography variant="h6">Still have questions?</Typography>
            <Typography>
              Can't find the answer you're looking for? Please chat to our
              friendly team.
            </Typography>
            <Box className="button-primary">
              <Button
                onClick={() => navigate(routes?.CONTACT)}
                className={styles.btnContact}
              >
                Contact Us
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CandidateFaqs;

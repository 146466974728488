import shortid from "shortid";
import { SIDEBAR_ITEM } from "../../../store/constants/constants";
import { useDispatch, useSelector } from "react-redux";

// export const ItemTypes = {
//   BOX: {
//     type: "box",
//     value: {
//       p: <p>hii</p>,
//       h2: <h2>hello</h2>,
//     },
//   },
//   SIDEBAR_ITEMS: {
//     item: {
//       id: shortid.generate(),
//       type: SIDEBAR_ITEM,
//       component: {
//         heading: "Heading",
//         paragraph: "Paragraph",
//       },
//     },
//   },
// };

export const FieldItems = [
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Brand Logo",
      icon: "TbPhoto",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "image",
        style: {
          display: "left",
        },
        img_url: "",
      },
      input_type: "img",
      name: "image",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Heading",
      icon: "TbHeading",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "heading",
        field_value: "Heading Text",

        style: {
          heading_tag: "h2",
          text_color: "#1e1e1e",
          font_size: "30",
          font_weight: "700",
        },
      },
      input_type: "heading",
      name: "heading",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Paragraph",
      icon: "TbPilcrow",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "paragraph",
        field_value: "Please type paragraph text",
        style: {
          text_color: "#1e1e1e",
          font_size: "16",
        },
      },
      input_type: "paragraph",
      name: "paragraph",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Horizontal Line",
      icon: "TbBorderHorizontal",
      type: {
        field_type: "line",
      },
      input_type: "line",
      name: "line",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Text Field",
      icon: "TbCursorText",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "input",
        label: "Text Label",
        require: true,
        placeholder: "Enter text",
        field_value: "",
      },
      input_type: "text",
      name: "input",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Email",
      icon: "TbMail",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "email",
        label: "Email Label",
        require: true,
        placeholder: "Enter email",
        field_value: "",
      },
      input_type: "email",
      name: "email",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Phone",
      icon: "TbPhone",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "phone",
        label: "Phone Label",
        require: true,
        placeholder: "Enter phone number",
        field_value: "",
      },
      input_type: "phone",
      name: "phone",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Number",
      icon: "TbPlus",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "number",
        label: "Number Label",
        require: true,
        placeholder: "Enter number",
        field_value: "",
      },
      input_type: "number",
      name: "number",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Date",
      icon: "TbCalendar",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "date",
        label: "Date Label",
        require: true,
        field_value: "",
      },
      input_type: "date",
      name: "date",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Text Area",
      icon: "TbMist",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "textarea",
        label: "Text area Label",
        require: true,
        placeholder: "Enter message",
        field_value: "",
      },
      input_type: "textarea",
      name: "textarea",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Select",
      icon: "TbSquareRoundedChevronDown",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "select",
        label: "Select Label",
        require: true,
        placeholder: "Select option",

        options: [
          {
            id: shortid.generate() + Math.random(),
            value: "Option 1",
            label: "Option 1",
          },
        ],
      },
      input_type: "select",
      name: "select",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Multiple Choice",
      icon: "MdOutlineRadioButtonChecked",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "radio",
        label: "Multiple Choice Label",
        require: true,
        placeholder: "Select radio",

        options: [
          {
            label: "Option 1",
            value: "Option 1",
            id: shortid.generate() + Math.random(),
          },
          {
            label: "Option 2",
            value: "Option 2",
            id: shortid.generate() + Math.random(),
          },
        ],
      },
      input_type: "radio-group",
      name: "radio",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Checkbox",
      icon: "TbCheckbox",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "checkbox",
        label: "Checkbox Label",
        require: true,

        options: [
          {
            label: "Option 1",
            value: "Option 1",
            id: shortid.generate() + Math.random(),
          },
        ],
      },
      input_type: "checkbox-group",
      name: "checboxgroupname",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Upload File",
      icon: "TbUpload",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "upload_file",
        label: "Upload File Label",
        require: true,
        button_text: "Upload here",
      },
      input_type: "file",
      name: "upload_file",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Rating",
      icon: "TbStar",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "rating",
        label: "Rating Label",
        require: true,
      },
      input_type: "starRating",
      name: "rating",
    },
  },
  // {
  //   type: SIDEBAR_ITEM,
  //   value: {
  //     title: "Autocomplete",
  //     icon: "TbForms",
  //     type: {
  //       id: shortid.generate() + Math.random(),
  //       field_type: "autocomplete",
  //       label: "Autocomplete Label",
  //       require: false,
  //       placeholder: "Autocomplete Placeholder",

  //       options: [
  //         {
  //           id: shortid.generate() + Math.random(),
  //           value: "Option 1",
  //           label: "Option 1",
  //           selected: "Selected",
  //         },
  //         {
  //           id: shortid.generate() + Math.random(),
  //           value: "Option 2",
  //           label: "Option 2",
  //         },
  //       ],
  //     },
  //     input_type: "autocomplete",
  //     name: "autocomplete",
  //   },
  // },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "URL",
      icon: "TbExternalLink",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "url",
        label: "URL Label",
        placeholder: "Example: https://yourSite.com",
        require: false,
      },
      input_type: "text",
      name: "url",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Hyper link",
      icon: "TbLink",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "hyperlink",
        placeholder: "",
        label: "Link title",
        require: false,
      },
      input_type: "text",
      name: "hyperlink",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Table",
      icon: "TbTable",
      type: {
        field_type: "table",
        header: {
          data: [
            { name: "Col 1", id: shortid.generate() + Math.random() },
            { name: "Col 2", id: shortid.generate() + Math.random() },
            { name: "Col 3", id: shortid.generate() + Math.random() },
          ],
        },
        table: {
          data: [
            ["value", "value", "value"],
            ["value", "value", "value"],
            ["value", "value", "value"],
          ],
        },
      },
      input_type: "table",
      name: "table",
    },
  },
  // {
  //   type: SIDEBAR_ITEM,
  //   value: {
  //     title: "Vertical Table",
  //     icon: "TbTable",
  //     type: {
  //       field_type: "vertical_table",
  //       header: {
  //         data: [
  //           { name: "Row 1", id: shortid.generate() + Math.random() },
  //           { name: "Row 2", id: shortid.generate() + Math.random() },
  //           { name: "Row 3", id: shortid.generate() + Math.random() },
  //         ],
  //       },
  //       table: {
  //         data: [
  //           ["value 1", "value 2", "value 3"],
  //           ["value 4", "value 5", "value 6"],
  //           ["value 7", "value 8", "value 9"],
  //         ],
  //       },
  //     },
  //     input_type: "vertical_table",
  //     name: "vertical_table",
  //   },
  // },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Two Column",
      icon: "TbColumns2",
      type: {
        field_type: "two_col",
        col_1: {
          data: [],
        },
        col_2: {
          data: [],
        },
      },
      input_type: "two_col",
      name: "two_col",
    },
  },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Signature",
      icon: "TbSignature",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "signature",
        label: "Signature Label",
        require: true,
      },
      input_type: "signature",
      name: "signature",
    },
  },
  // {
  //   type: SIDEBAR_ITEM,
  //   value: {
  //     title: "Recruiter's details ",
  //     icon: "TbSquareRoundedChevronDown",
  //     type: {
  //       id: shortid.generate() + Math.random(),
  //       field_type: "select_r",
  //       label: "Select details",
  //       require: true,
  //       placeholder: "Select details",

  //       options: [
  //         {
  //           id: shortid.generate() + Math.random(),
  //           value: "Option 1",
  //           label: "Option 1",
  //           selec: true,
  //         },
  //         {
  //           id: shortid.generate() + Math.random(),
  //           value: "Option 1",
  //           label: "Option 1",
  //           selec: false,
  //         },
  //         {
  //           id: shortid.generate() + Math.random(),
  //           value: "Option 1",
  //           label: "Option 1",
  //           selec: false,
  //         },
  //       ],
  //     },
  //     input_type: "select_r",
  //     name: "select",
  //   },
  // },
  {
    type: SIDEBAR_ITEM,
    value: {
      title: "Recruiter's details",
      icon: "TbSquareRoundedChevronDown",
      type: {
        id: shortid.generate() + Math.random(),
        field_type: "info",
      },
      input_type: "info",
      name: "info",
    },
  },
];

import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Button, CircularProgress } from "@mui/material";
import "./styles.css";
import { Container } from "@mui/system";
import {
  acceptForm,
  submitForm,
  showSubmissionForms,
  storeFormRequest,
} from "../../api/candidate/candidate.class";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/home-page/navbar/Navbar";
import { routes } from "../../routes";

import HeadingField from "../../components/forms/formbuilder/FormComponents/HeadingField.js";
import InputEmail from "../../components/forms/formbuilder/FormComponents/InputEmail.js";
import InputPhone from "../../components/forms/formbuilder/FormComponents/InputPhone.js";
import InputNumber from "../../components/forms/formbuilder/FormComponents/InputNumber.js";
import InputDate from "../../components/forms/formbuilder/FormComponents/InputDate.js";
import TextArea from "../../components/forms/formbuilder/FormComponents/TextArea.js";
import SelectField from "../../components/forms/formbuilder/FormComponents/SelectField.js";
import RadioField from "../../components/forms/formbuilder/FormComponents/RadioField.js";
import CheckboxField from "../../components/forms/formbuilder/FormComponents/CheckboxField.js";
import UploadFileField from "../../components/forms/formbuilder/FormComponents/UploadFileField.js";
import StarRating from "../../components/forms/formbuilder/FormComponents/StarRating";
import SignatureField from "../../components/forms/formbuilder/FormComponents/SignatureField";
import AutocompleteField from "../../components/forms/formbuilder/FormComponents/AutocompleteField";
import ParagraphField from "../../components/forms/formbuilder/FormComponents/ParagraphField";
import InputField from "../../components/forms/formbuilder/FormComponents/InputField.js";

import style from "../../components/forms/formbuilder/FormComponents/style.module.scss";
import { FORM_STRUCTURE_UPDATE } from "../../store/constants/constants";
import { storeFormStructure } from "../../store/actions/storeFormStructure";
import InputURL from "../../components/forms/formbuilder/FormComponents/InputURL";
import HyperLinkField from "../../components/forms/formbuilder/FormComponents/HyperLinkField";
import ImageField from "../../components/forms/formbuilder/FormComponents/ImageField";
import Line from "../../components/forms/formbuilder/FormComponents/Line";
import TableField from "../../components/forms/formbuilder/FormComponents/TableField";
import TwoColGrid from "../../components/forms/formbuilder/GridLayout/TwoColGrid";
import TwoColGrid2 from "../../components/forms/formbuilder/GridLayout/TwoColGrid2";
import { ShowAlert } from "../../store/actions/alertActions";
import RecruiterInfo from "../../components/forms/formbuilder/FormComponents/RecruiterInfo";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");
require("formBuilder/dist/form-render.min.js");

const FormView = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth.results);
  const location = useLocation();
  const type = location?.state?.type;
  const json_type = location?.state?.json_type;
  const data = location?.state?.data;
  const formData = location?.state?.formData;
  const uuid = location?.state?.uuid;
  const row = location?.state?.row;
  const [loading, setLoading] = useState(false);
  const { formToken } = useParams();

  const [renderData, setRenderData] = useState();
  const [payload, setPayload] = useState();
  const [submitData, setSubmitData] = useState();
  const [formBuilderData, setFormBuilderData] = useState();
  const getpreviewform = useSelector((state) => state?.getpreviewform);
  const getfileformbuilder = useSelector((state) => state?.getfileformbuilder);
  const getsignformbuilder = useSelector((state) => state?.getsignformbuilder);

  let fbOptions = {
    disabledAttrs: ["subtype"],
  };

  useEffect(() => {
    if (json_type == 0) {
      $("#fb-render").formRender({
        disabledAttrs: ["subtype"],
        dataType: "json",
        formData: formBuilderData?.length > 0 ? formBuilderData : data,
      });
    }
    if (data !== undefined) {
      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, data));
    }
  });

  useEffect(() => {
    const AcceptForm = async () => {
      try {
        const response = await acceptForm(formToken);
        // console.log("response", response);

        if (response?.data?.success) {
          setFormBuilderData(
            response?.data?.results?.form_builder?.form_builder_json
          );

          dispatch(
            storeFormStructure(
              FORM_STRUCTURE_UPDATE,
              response?.data?.results?.form_builder?.form_builder_json
            )
          );

          setPayload(response?.data?.results);
        }
      } catch (error) {
        console.log(error);
      }
    };
    AcceptForm();
  }, []);

  const handleSubmit = async () => {
    var json = {};
    $("#my-form").on("submit", function (event) {
      //var object = $("#fb-render :input").serializeArray();
      var object = $("#fb-render :input").serializeArray();
      object.push(...getfileformbuilder);
      object.push(...getsignformbuilder);

      $.each(object, function () {
        if (json[this.name] !== undefined) {
          if (!json[this.name].push) {
            json[this.name] = [json[this.name]];
          }
          json[this.name].push(this.value || "");
        } else {
          json[this.name] = this.value || "";
        }
      });

      // data.map((item) => {
      //   if (item?.type?.require === "true") {
      //     let val = $("input[name=" + item.name + "]").val();
      //     const Addar = {
      //       [item.name]: {
      //         value: "This field is required",
      //       },
      //     };
      //     validation.push(Addar);
      //   }
      // });

      // console.log("validation", validation);
      setRenderData(json);

      event.preventDefault();
    });
  };

  useEffect(() => {
    const SubmitForm = async () => {
      if (renderData) setLoading(true);

      try {
        if (renderData && formToken && type !== "candidate") {
          let body = {
            ...renderData,
            recruiter_id: payload?.recruiter_id,
            form_builder_id: payload?.form_builder?.id,
            form_request_id: payload?.id,
            form_builder_category_id:
              payload?.form_builder?.form_builder_category?.id,
            workspace_id: payload?.workspace_id,
          };

          let formData = new FormData();
          for (const key in body) {
            formData.append([key], body[key]);
          }

          const response = await submitForm(
            payload?.form_builder?.slug,
            formData
          );

          if (response.data.success) {
            setLoading(false);
            navigate("/");
          } else {
            setLoading(false);

            if (typeof response.data.message === "object") {
              Object.keys(response.data.message).map((item, i) => {
                // response.data.message[item];

                // console.log(response.data.message[item][0]);
                dispatch(ShowAlert(response.data.message[item][0], "error"));
              });
            } else {
              dispatch(ShowAlert(response.data.message, "error"));
            }
          }
        } else if (renderData && type === "candidate") {
          let body = {
            ...renderData,
            recruiter_id: row?.recruiter_id,
            form_builder_id: row?.form_builder?.id,
            form_request_id: row?.id,
            form_builder_category_id:
              row?.form_builder?.form_builder_category?.id,
            workspace_id: row?.workspace_id,
            candidate_id: auth?.candidate_id,
            //from_json_value: { ...renderData },
          };

          let formData = new FormData();
          for (const key in body) {
            formData.append([key], body[key]);
          }
          const response = await storeFormRequest(
            row?.form_builder?.slug,
            formData
          );

          if (response.data.success) {
            setLoading(false);
            navigate("/dashboard/request/list");
          } else {
            setLoading(false);

            if (typeof response.data.message === "object") {
              Object.keys(response.data.message).map((item, i) => {
                dispatch(ShowAlert(response.data.message[item][0], "error"));
              });
            } else {
              dispatch(ShowAlert(response.data.message, "error"));
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    SubmitForm();
  }, [renderData]);

  useEffect(() => {
    if (type === "submission") {
      const API = async () => {
        const response = await showSubmissionForms(uuid, {
          workspace_id: auth?.workspace_id,
        });
        // console.log(response?.data?.results?.data_json);
        setSubmitData(response?.data?.results?.data_json);
      };

      API();
    }
  }, []);

  useEffect(() => {
    // console.log("================================", submitData);
    if (type === "submission" && submitData) {
      var frm = $(".rendered-form");

      $.each(submitData, function (key, value) {
        var $ctrl = $("[name=" + key + "]", frm);
        switch ($ctrl.attr("type")) {
          case "text":
          case "hidden":
            $ctrl.val(value);
            break;
          case "radio":
            $ctrl.each(function () {
              if ($(this).attr("value") == value) {
                $(this).attr("checked", value);
              }
            });
            break;
          case "checkbox":
            for (var i = 0; i < value.length; i++) {
              $ctrl.each(function () {
                if ($(this).attr("value") == value[i]) {
                  $(this).attr("checked", value[i]);
                }
              });
            }
            break;

          default:
            $ctrl.val(value);
        }
      });
    }
  }, [submitData]);

  return (
    <DndProvider backend={HTML5Backend} context={window}>
      <Container
        className={
          "d-flex flex-column align-items-center justify-content-center w-100"
        }
      >
        <form id="my-form" className="formView">
          <fieldset disabled={type === "submission" ? true : false}>
            <Box>
              {json_type === 0 ? (
                <Box className="mainView" id="fb-render" />
              ) : json_type === undefined ? (
                <Box className="mainView" id="fb-render">
                  <div className={style.previewForm}>
                    {formBuilderData?.map((dropItem, index) => {
                      // console.log("dropItem", dropItem);
                      switch (dropItem.type.field_type) {
                        case "input":
                          return (
                            <InputField
                              id={dropItem.id}
                              label={dropItem.type.label}
                              placeholder={dropItem.type.placeholder}
                              type={dropItem.type.field_type}
                              name={dropItem.name}
                              index={index}
                              value={formData?.[dropItem?.name]}
                            ></InputField>
                          );
                        case "paragraph":
                          return (
                            <ParagraphField
                              type={dropItem.type.field_type}
                              id={dropItem.id}
                              value={dropItem.type.field_value}
                              field_style={dropItem.type.style}
                            />
                          );
                        case "heading":
                          return (
                            <HeadingField
                              type={dropItem.type.field_type}
                              id={dropItem.id}
                              value={dropItem.type.field_value}
                              field_style={dropItem.type.style}
                              index={index}
                            />
                          );
                        case "email":
                          return (
                            <InputEmail
                              id={dropItem.id}
                              label={dropItem.type.label}
                              placeholder={dropItem.type.placeholder}
                              type={dropItem.type.field_type}
                              value={formData?.[dropItem?.name]}
                              name={dropItem.name}
                            />
                          );
                        case "phone":
                          return (
                            <InputPhone
                              id={dropItem.id}
                              label={dropItem.type.label}
                              placeholder={dropItem.type.placeholder}
                              type={dropItem.type.field_type}
                              name={dropItem.name}
                              value={formData?.[dropItem?.name]}
                            />
                          );
                        case "number":
                          return (
                            <InputNumber
                              id={dropItem.id}
                              label={dropItem.type.label}
                              placeholder={dropItem.type.placeholder}
                              type={dropItem.type.field_type}
                              name={dropItem.name}
                              value={formData?.[dropItem?.name]}
                            />
                          );
                        case "date":
                          return (
                            <InputDate
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              value={formData?.[dropItem?.name]}
                              name={dropItem.name}
                            />
                          );
                        case "textarea":
                          return (
                            <TextArea
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              value={formData?.[dropItem?.name]}
                              placeholder={dropItem.type.placeholder}
                              name={dropItem.name}
                            />
                          );
                        case "select":
                          return (
                            <SelectField
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              value={formData?.[dropItem?.name]}
                              placeholder={dropItem.type.placeholder}
                              name={dropItem.name}
                              SelectField={formData?.[dropItem?.name]}
                              two_col={""}
                              col_name={""}
                            />
                          );
                        case "info":
                          return (
                            <RecruiterInfo
                              id={dropItem.id}
                              name={dropItem.name}
                              type={dropItem.type.field_type}
                              value={formData?.[dropItem?.name]}
                              info={dropItem?.type?.recruiter_details}
                            />
                          );
                        case "radio":
                          return (
                            <RadioField
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              name={dropItem.name}
                              SelectField={formData?.[dropItem?.name]}
                            />
                          );
                        case "checkbox":
                          return (
                            <CheckboxField
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              name={dropItem.name}
                              SelectField={formData?.[dropItem?.name]}
                            />
                          );
                        case "upload_file":
                          return formToken ||
                            type === "candidate" ||
                            type === "recruiter" ? (
                            <UploadFileField
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              button_text={dropItem.type.button_text}
                              name={dropItem.name}
                            />
                          ) : (
                            <>
                              <label htmlFor="" className="mr-2">
                                {dropItem.type.label}
                              </label>
                              {formData?.[dropItem?.name] === "" ||
                              formData?.[dropItem?.name] === null ||
                              formData?.[dropItem?.name] === undefined ? (
                                <span>No file</span>
                              ) : (
                                <>
                                  <a
                                    target="_blank"
                                    href={`${
                                      process.env.REACT_APP_URL
                                    }/storage/${formData?.[dropItem?.name]}`}
                                  >
                                    {`${process.env.REACT_APP_URL}/storage/${
                                      formData?.[dropItem?.name]
                                    }`}
                                  </a>
                                </>
                              )}
                            </>
                          );
                        case "rating":
                          return (
                            <StarRating
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              name={dropItem.name}
                              selectedvalue={formData?.[dropItem?.name]}
                              value={formData?.[dropItem?.name + "rating"]}
                            />
                          );
                        case "signature":
                          return formToken ||
                            type === "candidate" ||
                            type === "recruiter" ? (
                            <SignatureField
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              name={dropItem.name}
                            />
                          ) : (
                            <img
                              style={{ maxWidth: "160px" }}
                              src={`${process.env.REACT_APP_URL}/storage/${
                                formData?.[dropItem?.name]
                              }`}
                              alt=""
                            />
                          );

                        case "autocomplete":
                          return (
                            <AutocompleteField
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              selectedvalue={formData?.[dropItem?.name]}
                              placeholder={dropItem.type.placeholder}
                              name={dropItem.name}
                              options={dropItem.type.options}
                            />
                          );

                        case "url":
                          return (
                            <InputURL
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              value={formData?.[dropItem?.name]}
                              placeholder={dropItem.type.placeholder}
                              name={dropItem.name}
                            />
                          );

                        case "hyperlink":
                          return (
                            <HyperLinkField
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              placeholder={dropItem.type.placeholder}
                              name={dropItem.name}
                            />
                          );

                        case "image":
                          return (
                            <ImageField
                              id={dropItem.id}
                              type={dropItem.type.field_type}
                              img_url={dropItem.type.img_url}
                              onerror="this.src='../images/default.png'"
                              name={dropItem.name}
                              field_style={dropItem.type.style}
                            />
                          );
                        case "line":
                          return (
                            <Line
                              type={dropItem.type.field_type}
                              id={dropItem.id}
                            ></Line>
                          );
                        case "table":
                          return (
                            <TableField
                              id={dropItem.id}
                              type={dropItem.type.field_type}
                              header={dropItem.type.header}
                              table={dropItem.type.table.data}
                            ></TableField>
                          );

                        case "two_col":
                          return (
                            <TwoColGrid2
                              id={dropItem.id}
                              type={dropItem.type.field_type}
                              col1={dropItem.type.col_1}
                              col2={dropItem.type.col_2}
                              col1_ID={dropItem.col_1_id}
                              col2_ID={dropItem.col_2_id}
                            ></TwoColGrid2>
                          );

                        default:
                      }
                    })}
                  </div>
                </Box>
              ) : (
                <Box className="mainView" id="fb-render">
                  <div className={style.previewForm}>
                    {data?.map((dropItem, index) => {
                      switch (dropItem.type.field_type) {
                        case "input":
                          return (
                            <>
                              <InputField
                                id={dropItem.id}
                                label={dropItem.type.label}
                                placeholder={dropItem.type.placeholder}
                                type={dropItem.type.field_type}
                                name={dropItem.name}
                                value={formData?.[dropItem?.name]}
                              ></InputField>
                              {/* {validation?.[dropItem?.name] ? (
                                <p>This field is requird</p>
                              ) : (
                                ""
                              )} */}
                            </>
                          );
                        case "paragraph":
                          return (
                            <ParagraphField
                              type={dropItem.type.field_type}
                              id={dropItem.id}
                              value={dropItem.type.field_value}
                              field_style={dropItem.type.style}
                            />
                          );
                        case "heading":
                          return (
                            <HeadingField
                              type={dropItem.type.field_type}
                              id={dropItem.id}
                              value={dropItem.type.field_value}
                              field_style={dropItem.type.style}
                              index={"0"}
                              moveCard={"0"}
                            />
                          );
                        case "email":
                          return (
                            <InputEmail
                              id={dropItem.id}
                              label={dropItem.type.label}
                              placeholder={dropItem.type.placeholder}
                              type={dropItem.type.field_type}
                              value={formData?.[dropItem?.name]}
                              name={dropItem.name}
                            />
                          );
                        case "phone":
                          return (
                            <InputPhone
                              id={dropItem.id}
                              label={dropItem.type.label}
                              placeholder={dropItem.type.placeholder}
                              type={dropItem.type.field_type}
                              name={dropItem.name}
                              value={formData?.[dropItem?.name]}
                            />
                          );
                        case "number":
                          return (
                            <InputNumber
                              id={dropItem.id}
                              label={dropItem.type.label}
                              placeholder={dropItem.type.placeholder}
                              type={dropItem.type.field_type}
                              name={dropItem.name}
                              value={formData?.[dropItem?.name]}
                            />
                          );
                        case "date":
                          return (
                            <InputDate
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              value={formData?.[dropItem?.name]}
                              name={dropItem.name}
                            />
                          );
                        case "textarea":
                          return (
                            <TextArea
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              value={formData?.[dropItem?.name]}
                              placeholder={dropItem.type.placeholder}
                              name={dropItem.name}
                            />
                          );
                        case "select":
                          return (
                            <SelectField
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              value={formData?.[dropItem?.name]}
                              placeholder={dropItem.type.placeholder}
                              name={dropItem.name}
                              SelectField={formData?.[dropItem?.name]}
                              two_col={""}
                              col_name={""}
                            />
                          );

                        case "info":
                          return (
                            <RecruiterInfo
                              id={dropItem.id}
                              name={dropItem.name}
                              type={dropItem.type.field_type}
                              value={data?.[dropItem?.name]}
                              info={dropItem?.type?.recruiter_details}
                            />
                          );
                        case "radio":
                          return (
                            <RadioField
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              name={dropItem.name}
                              SelectField={formData?.[dropItem?.name]}
                            />
                          );
                        case "checkbox":
                          return (
                            <CheckboxField
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              name={dropItem.name}
                              SelectField={formData?.[dropItem?.name]}
                            />
                          );
                        case "upload_file":
                          return formToken ||
                            type === "candidate" ||
                            type === "recruiter" ? (
                            <UploadFileField
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              button_text={dropItem.type.button_text}
                              name={dropItem.name}
                            />
                          ) : (
                            <>
                              <label htmlFor="" className="mr-2">
                                {dropItem.type.label}:{" "}
                              </label>
                              {formData?.[dropItem?.name] === "" ||
                              formData?.[dropItem?.name] === null ||
                              formData?.[dropItem?.name] === undefined ? (
                                <span>No file</span>
                              ) : (
                                <>
                                  <a
                                    target="_blank"
                                    href={`${
                                      process.env.REACT_APP_URL
                                    }/storage/${formData?.[dropItem?.name]}`}
                                  >
                                    {`${process.env.REACT_APP_URL}/storage/${
                                      formData?.[dropItem?.name]
                                    }`}
                                  </a>
                                </>
                              )}
                            </>
                          );
                        case "rating":
                          return (
                            <StarRating
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              name={dropItem.name}
                              selectedvalue={formData?.[dropItem?.name]}
                              value={formData?.[dropItem?.name + "rating"]}
                            />
                          );
                        case "signature":
                          return formToken ||
                            type === "candidate" ||
                            type === "recruiter" ? (
                            <SignatureField
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              name={dropItem.name}
                            />
                          ) : (
                            <img
                              style={{ maxWidth: "160px" }}
                              src={`${process.env.REACT_APP_URL}/storage/${
                                formData?.[dropItem?.name]
                              }`}
                              alt=""
                            />
                          );

                        case "autocomplete":
                          return (
                            <AutocompleteField
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              selectedvalue={formData?.[dropItem?.name]}
                              placeholder={dropItem.type.placeholder}
                              name={dropItem.name}
                              options={dropItem.type.options}
                            />
                          );

                        case "url":
                          return (
                            <InputURL
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              value={formData?.[dropItem?.name]}
                              placeholder={dropItem.type.placeholder}
                              name={dropItem.name}
                            />
                          );

                        case "hyperlink":
                          return (
                            <HyperLinkField
                              id={dropItem.id}
                              label={dropItem.type.label}
                              type={dropItem.type.field_type}
                              placeholder={dropItem.type.placeholder}
                              name={dropItem.name}
                            />
                          );

                        case "image":
                          return (
                            <ImageField
                              id={dropItem.id}
                              type={dropItem.type.field_type}
                              img_url={dropItem.type.img_url}
                              name={dropItem.name}
                              field_style={dropItem.type.style}
                            />
                          );

                        case "line":
                          return (
                            <Line
                              type={dropItem.type.field_type}
                              id={dropItem.id}
                            ></Line>
                          );

                        case "table":
                          return (
                            <TableField
                              id={dropItem.id}
                              type={dropItem.type.field_type}
                              header={dropItem.type.header}
                              table={dropItem.type.table.data}
                            ></TableField>
                          );

                        case "two_col":
                          return (
                            <TwoColGrid2
                              id={dropItem.id}
                              type={dropItem.type.field_type}
                              col1={dropItem.type.col_1}
                              col2={dropItem.type.col_2}
                              col1_ID={dropItem.col_1_id}
                              col2_ID={dropItem.col_2_id}
                            ></TwoColGrid2>
                          );

                        default:
                      }
                    })}
                  </div>
                </Box>
              )}
            </Box>

            {type === "submission" ? (
              <></>
            ) : (
              <Box
                className={`button-primary`}
                // style={{ display: "flex", width: "100%" }}
              >
                {formToken || type === "candidate" ? (
                  <Button type="submit" onClick={handleSubmit}>
                    {loading ? (
                      <p style={{ color: "white" }}>Loading...</p>
                    ) : (
                      "Submit Form"
                    )}
                  </Button>
                ) : (
                  <Button
                    style={{ marginRight: "20px" }}
                    className="secondary-btn"
                    onClick={() =>
                      auth?.type === "candidate"
                        ? navigate(routes.SUBMISSION_LIST)
                        : navigate("/dashboard/forms/all")
                    }
                  >
                    Back
                  </Button>
                )}
              </Box>
            )}
          </fieldset>
        </form>
      </Container>
    </DndProvider>
  );
};
export default FormView;

import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CustomModel from "../../model";

import "./styles.scss";
import { Box, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import CheckoutForm from "../../checkout/checkout";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../routes";

const TextCreditPopup = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const candidate = useSelector((state) => state?.rcandidate);
  const singleCandidate = useSelector((state) => state?.singleCandidate);
  const [openPayment, setOpenPayment] = useState();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    reff: "",
  });

  useEffect(() => {
    if (singleCandidate?.createReff?.success === true) {
      setOpen(false);
    }
  }, [singleCandidate?.createReff]);

  return (
    <>
      <Box className="interviewParrent">
        <Typography variant="h3">
          Text notification credits are finished for this form.
        </Typography>
        <p className="text-center">
          Hi {auth?.results?.first_name}, text notification credits are finished
          for {candidate?.showCandidate?.results?.first_name}, if you want to
          continue use text notification then please purchase it or else you can
          continue using this feature with only mail notification.
        </p>
        <Box className="button-primary d-flex justify-content-between mw-100 w-100">
          <Button
            onClick={() => {
              navigate(routes.PRICING);
            }}
          >
            Purchase text notifications
          </Button>
          <Button
            className="secondary-btn ml-2"
            onClick={() => {
              setOpen(false);
            }}
          >
            Continue with email notifications
          </Button>
        </Box>
      </Box>
      <CustomModel
        open={openPayment}
        setOpen={setOpenPayment}
        // data={data}
        // setData={setData}
      >
        <CheckoutForm
          open={openPayment}
          setOpen={setOpenPayment}
          // data={data}
          // setData={setData}
          // updatedData={updatedData}
        />
      </CustomModel>
    </>
  );
};

export default TextCreditPopup;

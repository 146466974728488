import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as Cross } from "../../../../../assets/industries/model/x.svg";
import Modal from "@mui/material/Modal";
import styles from "./styles.module.scss";
import { Button, Grid, CircularProgress } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
import TextField from "@mui/material/TextField";
import {
  getExistLicense,
  updateProfileLicense,
} from "../../../../../api/candidate/candidate.class";
import CustomSelect from "../../../../common/Select";
import ImageUploader from "./ImageUploader";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../../../store/actions/alertActions";
import Alerts from "../../../../common/Alert";
import TextInput from "../../../../common/TextInput";

export default function CustomModal({
  openModal,
  setOpenModal,
  setUpdation,
  userDetailData,
  data,
  setData,
  existData,
  type,
}) {
  const handleClose = () => setOpenModal(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth?.results);
  const [loading, setLoading] = useState(false);
  const [edit1, setEdit1] = useState(true);
  const [edit2, setEdit2] = useState(true);
  // const [existData, setExistData] = useState();

  const [file, setFile] = useState({
    front_image: data?.front_image,
    back_image: data?.back_image,
  });
  // console.log("existData", existData);
  // useEffect(() => {
  //   const API = async () => {
  //     try {
  //       const response = await getExistLicense({
  //         ind_cert_lic_request_id: data?.industry_certification_license?.id,
  //         rec_ind_request_id: data?.recruiter_industry?.id,
  //       });
  //       // setExistData(response?.data?.results);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   API();
  // }, []);
  useEffect(() => {
    if (openModal) {
      setFile({
        front_image: data?.front_image || existData?.front_image,
        back_image: data?.back_image || existData?.back_image,
      });
      setEdit1(true);
      setEdit2(true);
    }
  }, [openModal]);

  const initialValues = {
    recruit_industry:
      {
        value:
          data?.recruiter_industry?.id || existData?.recruiter_industry?.id,
        label:
          data?.recruiter_industry?.name || existData?.recruiter_industry?.name,
      } || "",
    industry_cert_license:
      {
        value:
          data?.industry_certification_license?.id ||
          existData?.industry_certification_license?.id,
        label:
          data?.industry_certification_license?.name ||
          existData?.industry_certification_license?.name,
      } || "",
    issue_date: existData?.issue_date
      ? moment(existData?.issue_date).format("yyyy-MM-DD")
      : moment(data?.issue_date).format("yyyy-MM-DD") || "",
    expiry_date: existData?.expiry_date
      ? moment(existData?.expiry_date).format("yyyy-MM-DD")
      : moment(data?.expiry_date).format("yyyy-MM-DD") || "",
    doc_id: data?.document_number || existData?.document_number || "",
    first_name: existData?.first_name || auth?.first_name,
    last_name: existData?.last_name || auth?.last_name,
    dob: data?.dob || existData?.dob || "",
    mail_id: auth?.email,
    state: data?.state || existData?.state || "",
    country: data?.country || existData?.country || "",
    passportNumber: "",
  };

  // console.log("initialValues", initialValues);

  const licenseSchema = Yup.object().shape({
    recruit_industry: Yup.object().required("Enter Recruit Industry"),
    industry_cert_license: Yup.object().required("Enter Certificate License"),
    issue_date: Yup.string()
      .required("Enter Issue Date")
      .test(
        "futureDate",
        "Issue Date cannot be in the future",
        function (value) {
          const selectedDate = moment(value, "YYYY-MM-DD");
          const currentDate = moment();
          return selectedDate.isBefore(currentDate);
        }
      ),
    expiry_date: Yup.string().required("Enter Expiry Date"),
    doc_id: Yup.string().required("Enter Document ID"),
    first_name: Yup.string().required("Enter First Name"),
    last_name: Yup.string().required("Enter Last Name"),
    dob: Yup.string()
      .required("Enter Date of Birth")
      .test(
        "futureDate",
        "Date of Birth cannot be in the future",
        function (value) {
          const selectedDate = moment(value, "YYYY-MM-DD");
          const currentDate = moment();
          return selectedDate.isBefore(currentDate);
        }
      ),
    mail_id: Yup.string().required("Enter Email"),
    country: Yup.string().required("Enter Country"),
  });

  const handleFrontImageRemove = () => {
    setFile({
      ...file,
      front_image: null, // Set front_image to null to indicate removal
    });
    setEdit1(true); // Set edit1 to true to show the default state (no image)
  };
  const handleBackImageRemove = () => {
    setFile({
      ...file,
      back_image: null, // Set back_image to null to indicate removal
    });
    setEdit2(true); // Set edit2 to true to show the default state (no image)
  };

  const onSubmit = async (values) => {
    let payload;
    setLoading(true);
    try {
      if (type === "request") {
        payload = {
          uuid: data?.uuid,
          candidate_id: auth?.candidate_id,
          workspace_id: data?.workspace_id,
          recruiter_id: data?.recruiter_id,
          doc_id: values.doc_id,
          first_name: auth?.first_name,
          last_name: auth?.last_name,
          dob: values?.dob,
          state: values?.state,
          country: values?.country,
          passportNumber: values?.passportNumber,
          mail_id: auth?.email,
          issue_date: values.issue_date,
          expiry_date: values.expiry_date,
          request_type: "candidate",
          candidate_lic_cert_id: userDetailData?.id || "",
        };
        payload["recruitment_industries[" + data.id + "][issue_date]"] =
          moment(values.issue_date).format("DD-MM-yyyy") || "";

        payload["recruitment_industries[" + data.id + "][expiry_date]"] =
          moment(values.expiry_date).format("DD-MM-yyyy") || "";
        payload["recruitment_industries[" + data.id + "][document_number]"] =
          values.doc_id || "";
        payload["recruitment_industries[" + data.id + "][rec_ind_request_id]"] =
          values.recruit_industry?.value || "";
        payload[
          "recruitment_industries[" + data.id + "][ind_cert_lic_request_id]"
        ] = values.industry_cert_license?.value || "";

        !edit1 &&
          (payload["recruitment_industries[" + data.id + "][front_image]"] =
            file?.front_image);
        !edit2 &&
          (payload["recruitment_industries[" + data.id + "][back_image]"] =
            file?.back_image);
        payload["recruitment_industries[" + data.id + "][first_name]"] =
          values.first_name || "";
        payload["recruitment_industries[" + data.id + "][last_name]"] =
          values.last_name || "";
        payload["recruitment_industries[" + data.id + "][dob]"] =
          values.dob || "";
        payload["recruitment_industries[" + data.id + "][state]"] =
          values.state || "";
        payload["recruitment_industries[" + data.id + "][country]"] =
          values.country || "";
        payload["recruitment_industries[" + data.id + "][passportNumber]"] =
          values.passportNumber || "";
        payload["recruitment_industries[" + data.id + "][mail_id]"] =
          values.mail_id || "";
      } else {
        payload = {
          uuid: data?.uuid,
          candidate_id: auth?.candidate_id,
          request_type: "candidate",
          doc_id: values.doc_id,
          first_name: auth?.first_name,
          last_name: auth?.last_name,
          dob: values?.dob,
          state: values?.state,
          country: values?.country,
          passportNumber: values?.passportNumber,
          mail_id: auth?.email,
          issue_date: values.issue_date,
          expiry_date: values.expiry_date,
          candidate_lic_cert_id: userDetailData?.id || "",
        };
        payload["recruitment_industries[" + data.id + "][issue_date]"] =
          moment(values.issue_date).format("DD-MM-yyyy") || "";

        payload["recruitment_industries[" + data.id + "][expiry_date]"] =
          moment(values.expiry_date).format("DD-MM-yyyy") || "";
        payload["recruitment_industries[" + data.id + "][document_number]"] =
          values.doc_id || "";
        payload["recruitment_industries[" + data.id + "][rec_ind_request_id]"] =
          values.recruit_industry?.value || "";
        payload[
          "recruitment_industries[" + data.id + "][ind_cert_lic_request_id]"
        ] = values.industry_cert_license?.value || "";
        !edit1 &&
          (payload["recruitment_industries[" + data.id + "][front_image]"] =
            file?.front_image);
        !edit2 &&
          (payload["recruitment_industries[" + data.id + "][back_image]"] =
            file?.back_image);
        payload["recruitment_industries[" + data.id + "][first_name]"] =
          values.first_name || "";
        payload["recruitment_industries[" + data.id + "][last_name]"] =
          values.last_name || "";
        payload["recruitment_industries[" + data.id + "][dob]"] =
          values.dob || "";
        payload["recruitment_industries[" + data.id + "][state]"] =
          values.state || "";
        payload["recruitment_industries[" + data.id + "][country]"] =
          values.country || "";
        payload["recruitment_industries[" + data.id + "][passportNumber]"] =
          values.passportNumber || "";
        payload["recruitment_industries[" + data.id + "][mail_id]"] =
          values.mail_id || "";
      }
      const response = await updateProfileLicense({ ...payload });

      if (response.data.success) {
        setLoading(false);
        dispatch(ShowAlert(response?.data?.message, "success"));
        handleClose();
        setUpdation((old) => old + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log("data", data);

  return (
    <>
      <Alerts />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${styles.industryModel}`}
      >
        <Box className={`${styles.boxModel} xym1`}>
          <Box className={styles.modalwapper}>
            <Box className={styles.cross} onClick={handleClose}>
              <Cross />
            </Box>
            <Typography variant="h4" className={styles.heading}>
              Update Certificates and Licenses
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={licenseSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} className={styles.formsBox}>
                  <Box className={styles.statusbox}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item md={6} xs={12}>
                        <TextInput
                          style={{ width: "100%" }}
                          compolsory={true}
                          customClass={styles.input}
                          label="First Name"
                          type="text"
                          name="first_name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="First Name"
                          onChange={handleChange}
                          value={values?.first_name}
                        />
                        <span>
                          {errors.first_name &&
                            touched.first_name &&
                            errors.first_name}
                        </span>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextInput
                          style={{ width: "100%" }}
                          compolsory={true}
                          customClass={styles.input}
                          label="Last Name"
                          type="text"
                          name="last_name"
                          // sx={{ mt: 5.6, pb: 1 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Last Name"
                          onChange={handleChange}
                          value={values?.last_name}
                        />
                        <span>
                          {errors.last_name &&
                            touched.last_name &&
                            errors.last_name}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          compolsory={true}
                          style={{ width: "100%" }}
                          customClass={styles.input}
                          label="Email"
                          type="email"
                          name="mail_id"
                          // sx={{ mt: 3, pb: 3 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleChange}
                          value={values.mail_id}
                        />
                        <span>
                          {errors.mail_id && touched.mail_id && errors.mail_id}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          compolsory={true}
                          label="Date of birth"
                          type="date"
                          name="dob"
                          // sx={{ maxWidth: "initial" }}
                          onChange={handleChange}
                          value={values.dob}
                        />
                        <span>{errors.dob && touched.dob && errors.dob}</span>
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          label="State"
                          type="text"
                          name="state"
                          // sx={{ maxWidth: "initial" }}
                          onChange={handleChange}
                          value={values.state}
                        />
                        <span>
                          {errors.state && touched.state && errors.state}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          compolsory={true}
                          label="Country"
                          type="text"
                          name="country"
                          // sx={{ maxWidth: "initial" }}
                          onChange={handleChange}
                          value={values.country}
                        />
                        <span>
                          {errors.country && touched.country && errors.country}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className={styles.parentInput}>
                          <CustomSelect
                            customClass={styles.select}
                            type="text"
                            name="recruit_industry"
                            value={values.recruit_industry}
                            sx={{ maxWidth: "initial" }}
                            label={
                              <>
                                Recruitment Industries&nbsp;
                                <span style={{ color: "red" }}>*</span>
                              </>
                            }
                            isDisabled={true}
                          />
                          <span>
                            {errors.recruit_industry &&
                              touched.recruit_industry &&
                              errors.recruit_industry}
                          </span>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className={styles.parentInput}>
                          <CustomSelect
                            customClass={styles.select}
                            type="text"
                            name="industry_cert_license"
                            value={values.industry_cert_license}
                            sx={{ maxWidth: "initial" }}
                            label="Industry Certification License"
                            isDisabled={true}
                          />
                          <span>
                            {errors.industry_cert_license &&
                              touched.industry_cert_license &&
                              errors.industry_cert_license}
                          </span>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className={styles.parentInput}>
                          <TextInput
                            style={{ width: "100%" }}
                            // customClass={styles.input}
                            label={
                              <>
                                Document ID&nbsp;
                                <span style={{ color: "red" }}>*</span>
                              </>
                            }
                            type="text"
                            name="doc_id"
                            // sx={{ mt: 3, pb: 3 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={handleChange}
                            value={values.doc_id}
                          />
                          <span>
                            {errors.doc_id && touched.doc_id && errors.doc_id}
                          </span>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className={styles.parentBox}>
                          <TextInput
                            style={{ width: "100%" }}
                            // customClass={styles.input}
                            id="date"
                            size="small"
                            label="Issue Date"
                            type="date"
                            name="issue_date"
                            // sx={{ pb: 3 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={handleChange}
                            value={values.issue_date}
                          />
                          <span>
                            {errors.issue_date &&
                              touched.issue_date &&
                              errors.issue_date}
                          </span>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box className={styles.parentBox}>
                          <TextInput
                            style={{ width: "100%" }}
                            // customClass={styles.input}
                            id="date"
                            size="small"
                            label="Expiry Date"
                            type="date"
                            name="expiry_date"
                            // sx={{ mt: 5.6, pb: 1 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={handleChange}
                            value={values.expiry_date}
                          />
                          <span>
                            {errors.expiry_date &&
                              touched.expiry_date &&
                              errors.expiry_date}
                          </span>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box className={styles.parentBox}>
                          <ImageUploader
                            label={
                              <>
                                <span>Front Side Image</span>
                              </>
                            }
                            valuenew={file?.front_image}
                            edit={edit1}
                            setData={setData}
                            onChange={(e) => {
                              setEdit1(false);
                              setFile({
                                ...file,
                                front_image:
                                  e && e.target && e.target.files
                                    ? e.target.files[0]
                                    : null,
                              });
                            }}
                            name="front_image"
                            onImageRemove={handleFrontImageRemove}
                          />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box className={styles.parentBox}>
                          <ImageUploader
                            label={
                              <>
                                <span>Back Side Image</span>
                              </>
                            }
                            valuenew={file?.back_image}
                            edit={edit2}
                            data={data}
                            setData={setData}
                            onChange={(e) => {
                              setEdit2(false);
                              setFile({
                                ...file,
                                back_image:
                                  e && e.target && e.target.files
                                    ? e.target.files[0]
                                    : null,
                              });
                            }}
                            name="back_image"
                            onImageRemove={handleBackImageRemove}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Box className={`${styles.button} button-primary`}>
                      <Button type="submit">
                        {" "}
                        {loading ? (
                          <p style={{ color: "white" }}>Loading...</p>
                        ) : existData ? (
                          "Send"
                        ) : (
                          "Save"
                        )}
                      </Button>
                      <Button className="secondary-btn" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

import { useState } from "react";
import { Grid } from "@mui/material";
import { Box } from "./Box.js";
import { DropBox } from "./DropBox.js";
import { FieldItems } from "./ItemTypes.js";
import style from "./style.module.scss";

const Container = ({ getpreviewform }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xl={9} md={8}>
        <DropBox getpreviewform={getpreviewform} />
      </Grid>
      <Grid item xl={3} md={4}>
        <div className={style.sideBar}>
          {FieldItems.map((sidebarItem, index) => {
            return <Box key={sidebarItem.id} data={sidebarItem} />;
          })}
        </div>
      </Grid>
    </Grid>
  );
};

export default Container;

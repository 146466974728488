import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Main from "../../components/license_orders/all-candidates/index";
import { getLicenseOrderList } from "../../store/actions/licenseOrderActions";
const LicenseOrder = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLicenseOrderList());
  }, []);
  return (
    <div>
      <Main />
    </div>
  );
};

export default LicenseOrder;

import { GET_CHECKBOX_OPTION } from "../constants/constants";
import { DELETE_CHECKBOX } from "../constants/constants";
import { Box } from "@mui/system";
import style from "../../components/forms/formbuilder/FormComponents/style.module.scss";
const initialState = [];

export const getcheckboxReducers = (
  state = initialState,
  { type, payload, index }
) => {
  switch (type) {
    case GET_CHECKBOX_OPTION:
      return [...state, ...payload];
    case DELETE_CHECKBOX:
      const next = [...payload];
      delete next[index];
      return next;
    default:
      return state;
  }
};

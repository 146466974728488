import React, { useState } from "react";
import { useDrop } from "react-dnd";
import { FieldItems, ItemTypes } from "./ItemTypes.js";
import { SIDEBAR_ITEM } from "../../../store/constants/constants.js";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import shortid from "shortid";
import { useDispatch } from "react-redux";
import { storeFormStructure } from "../../../store/actions/storeFormStructure.js";
import InputField from "./FormComponents/InputField.js";
import {
  COL_FORM_STRUCTURE,
  FORM_STRUCTURE,
  FORM_STRUCTURE_UPDATE,
} from "../../../store/constants/constants.js";
import ParagraphField from "./FormComponents/ParagraphField.js";
import HeadingField from "./FormComponents/HeadingField.js";
import InputEmail from "./FormComponents/InputEmail.js";
import InputPhone from "./FormComponents/InputPhone.js";
import InputNumber from "./FormComponents/InputNumber.js";
import InputDate from "./FormComponents/InputDate.js";
import TextArea from "./FormComponents/TextArea.js";
import SelectField from "./FormComponents/SelectField.js";
import RadioField from "./FormComponents/RadioField.js";
import CheckboxField from "./FormComponents/CheckboxField.js";
import UploadFileField from "./FormComponents/UploadFileField.js";
import StarRating from "./FormComponents/StarRating";
import SignatureField from "./FormComponents/SignatureField";
import AutocompleteField from "./FormComponents/AutocompleteField";
import TwoColGrid from "./GridLayout/TwoColGrid.js";
import { storeColFormStructure } from "../../../store/actions/storeColFormStructure.js";
import InputURL from "./FormComponents/InputURL.js";
import HyperLinkField from "./FormComponents/HyperLinkField.js";
import style from "./FormComponents/style.module.scss";
function getStyle(backgroundColor) {
  return {
    // border: "1px solid #e5e5e5",
    // width: "100%",
    backgroundColor,
    // padding: "16px",
    // fontSize: "1rem",
  };
}
function getParaStyle(color) {
  return {
    color,
  };
}

function ColDropBox({ id, greedy, col_ID, col_name }) {
  const getpreviewform = useSelector((state) => state?.getpreviewform);

  let dispatch = useDispatch();

  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
      accept: SIDEBAR_ITEM,

      drop(_item, monitor) {
        const didDrop = monitor.didDrop();
        if (didDrop && !greedy) {
          return;
        }

        let testval = _item.value;
        testval.id = shortid.generate();

        const addtablerow = getpreviewform.map((item) => {
          const Addar = {
            ...testval,
          };

          if (item.col_2_id === col_ID) {
            return {
              ...item,
              type: {
                ...item.type,
                col_2: {
                  ...item.type.col_2,
                  data: [...item.type["col_2"].data, Addar],
                },
                col_1: item.type.col_1,
              },
            };
          }
          return { ...item };
        });

        //dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow));
      },

      collect: (monitor) => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [greedy, col_ID]
  );

  let backgroundColor = "rgba(255, 255, 255)";
  let color = "#adadad";

  if (isOverCurrent || (isOver && greedy)) {
    backgroundColor = "skyblue";
    color = "#fff";
  }

  // const list = getColFormStructre.map((dropItem, index) => {

  const list = getpreviewform.map((dropItem, index) => {
    return dropItem.id === id
      ? dropItem?.type["col_2"]?.data?.map((subDropItems, i) => {
          switch (subDropItems.type.field_type) {
            case "input":
              return (
                <InputField
                  id={subDropItems.id}
                  label={subDropItems.type.label}
                  placeholder={subDropItems.type.placeholder}
                  type={subDropItems.type.field_type}
                ></InputField>
              );
            case "paragraph":
              return (
                <ParagraphField
                  type={subDropItems.type.field_type}
                  id={subDropItems.id}
                  value={subDropItems.type.field_value}
                  field_style={subDropItems.type.style}
                />
              );
            case "heading":
              return (
                <HeadingField
                  type={subDropItems.type.field_type}
                  id={subDropItems.id}
                  value={subDropItems.type.field_value}
                  field_style={subDropItems.type.style}
                />
              );
            case "email":
              return (
                <InputEmail
                  id={subDropItems.id}
                  label={subDropItems.type.label}
                  placeholder={subDropItems.type.placeholder}
                  type={subDropItems.type.field_type}
                  value={subDropItems.type.field_value}
                />
              );
            case "phone":
              return (
                <InputPhone
                  id={subDropItems.id}
                  label={subDropItems.type.label}
                  placeholder={subDropItems.type.placeholder}
                  type={subDropItems.type.field_type}
                  value={subDropItems.type.field_value}
                />
              );
            case "number":
              return (
                <InputNumber
                  id={subDropItems.id}
                  label={subDropItems.type.label}
                  placeholder={subDropItems.type.placeholder}
                  type={subDropItems.type.field_type}
                  value={subDropItems.type.field_value}
                />
              );
            case "date":
              return (
                <InputDate
                  id={subDropItems.id}
                  label={subDropItems.type.label}
                  type={subDropItems.type.field_type}
                  value={subDropItems.type.field_value}
                />
              );
            case "textarea":
              return (
                <TextArea
                  id={subDropItems.id}
                  label={subDropItems.type.label}
                  type={subDropItems.type.field_type}
                  value={subDropItems.type.field_value}
                  placeholder={subDropItems.type.placeholder}
                />
              );
            case "select":
              return (
                <SelectField
                  id={subDropItems.type.id}
                  label={subDropItems.type.label}
                  type={subDropItems.type.field_type}
                  value={subDropItems.type.field_value}
                  placeholder={subDropItems.type.placeholder}
                  options={subDropItems.type.options}
                />
              );
            case "radio":
              return (
                <RadioField
                  id={subDropItems.type.id}
                  label={subDropItems.type.label}
                  type={subDropItems.type.field_type}
                  name={subDropItems.type.name}
                  options={subDropItems.type.options}
                />
              );
            case "checkbox":
              return (
                <CheckboxField
                  id={subDropItems.type.id}
                  label={subDropItems.type.label}
                  type={subDropItems.type.field_type}
                  name={subDropItems.type.name}
                  options={subDropItems.type.options}
                />
              );
            case "upload_file":
              return (
                <UploadFileField
                  id={subDropItems.type.id}
                  label={subDropItems.type.label}
                  type={subDropItems.type.field_type}
                  button_text={subDropItems.type.button_text}
                />
              );
            case "rating":
              return (
                <StarRating
                  id={subDropItems.type.id}
                  label={subDropItems.type.label}
                  type={subDropItems.type.field_type}
                />
              );
            case "signature":
              return (
                <SignatureField
                  id={subDropItems.type.id}
                  label={subDropItems.type.label}
                  type={subDropItems.type.field_type}
                />
              );

            case "autocomplete":
              return (
                <AutocompleteField
                  id={subDropItems.type.id}
                  label={subDropItems.type.label}
                  type={subDropItems.type.field_type}
                />
              );

            case "url":
              return (
                <InputURL
                  id={subDropItems.id}
                  label={subDropItems.type.label}
                  type={subDropItems.type.field_type}
                  value={subDropItems.type.field_value}
                  placeholder={subDropItems.type.placeholder}
                />
              );

            case "hyperlink":
              return (
                <HyperLinkField
                  id={subDropItems.id}
                  label={subDropItems.type.label}
                  type={subDropItems.type.field_type}
                  placeholder={subDropItems.type.placeholder}
                />
              );

            default:
          }
        })
      : "";
  });

  return (
    <div
      ref={drop}
      className={style.col_item}
      style={getStyle(backgroundColor)}
    >
      {list}
      <p
        className={`${style.dropText} text-center`}
        style={getParaStyle(color)}
      >
        Drop Here
      </p>
    </div>
  );
}

export default ColDropBox;

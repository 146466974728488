import React, { useEffect, useState } from "react";
import { a11yProps, TabPanel } from "../../common/mui-tabs";
import FormsTabel from "../table/index";
import {
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import styles from "./styles.module.scss";
import { AiOutlinePlus } from "react-icons/ai";
import PopupForm from "../modal/index";
import CreateForm from "../create-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllForms,
  getDeactivateForms,
} from "../../../api/candidate/candidate.class";
import { routes } from "../../../routes";
import CustomSelect from "../../common/Select";
import {
  getAllFormFilter,
  getFormCategory,
} from "../../../api/candidate/candidate.class";
import "./styles.module.scss";

// import SEO from "../../meta-tags";

const WorkSpace = () => {
  const navigate = useNavigate();
  const [tabValue, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const [deactivateData, setDeactivateData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState();
  const [formNameOptions, setFormNameOptions] = useState([]);
  const [formCategory, setFormCategory] = useState();
  const [inputValue, setInputValue] = React.useState("");
  const [searchValue, setSearchValue] = useState("");

  let { category } = useParams();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (formCategory?.length > 0) {
      const all = [{ id: "All", name: "All" }];

      [...all, ...formCategory].forEach((record) => {
        const data = [{ value: record.id, label: record.name }];
        setCategoryOptions((prev) => [...prev, ...data]);
      });
    }

    return () => {
      setCategoryOptions("");
    };
  }, [formCategory]);

  useEffect(() => {
    if (formData?.length > 0) {
      [...formData].forEach((record) => {
        const data = [{ value: record.id, label: record.name }];
        setFormNameOptions((prev) => [...prev, ...data]);
      });
    }

    return () => {
      setFormNameOptions("");
    };
  }, [formData]);

  useEffect(() => {
    const api = async () => {
      try {
        const response = await getFormCategory({
          //workspace_id: auth.results.workspace_id,
          without_offer: 1,
        });

        setFormCategory(response.data.results);
      } catch (error) {}
    };
    api();
  }, []);

  const handleChangeoption = (value) => {
    setInputValue(value);
    const tableData = async () => {
      try {
        if (value.value === "All") {
          const response = await getAllForms();
          setFormData(response?.data?.results?.data);
        } else {
          if (tabValue === 1) {
            const payload = {
              form_builder_status: "0",
              category_id: value.value,
              name: value.label,
              searchText: searchValue,
            };
            const response = await getAllFormFilter(payload);
            setDeactivateData(response?.data?.results?.data);
          } else {
            const payload = {
              category_id: value.value,
              name: value.label,
              searchText: searchValue,
            };
            const response = await getAllFormFilter(payload);
            setFormData(response?.data?.results?.data);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    tableData();
  };

  const handleSearch = (value) => {
    const type = value?.target?.value;
    setSearchValue(type);
    const tableData = async () => {
      try {
        if (tabValue === 1) {
          const payload = {
            form_builder_status: "0",
            name: value.label,
            searchText: type,
            category_id: inputValue.label === "all" ? "" : inputValue.value,
          };
          const response = await getAllFormFilter(payload);
          setDeactivateData(response?.data?.results?.data);
        } else {
          const payload = {
            name: value.label,
            searchText: type,
            category_id: inputValue.label === "all" ? "" : inputValue.value,
          };
          const response = await getAllFormFilter(payload);
          setFormData(response?.data?.results?.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    tableData();
  };

  useEffect(() => {
    const api = async () => {
      let body = {
        category_id: category === "all" ? "" : 2,
      };

      try {
        const response = await getAllForms(body);
        setFormData(response?.data?.results?.data);
      } catch (error) {}
    };
    api();
  }, [update, category]);

  useEffect(() => {
    const api = async () => {
      const response = await getDeactivateForms({
        form_builder_status: "0",
        category_id: category === "all" ? "" : 2,
      });

      setDeactivateData(response?.data?.results?.data);
    };
    api();
  }, [update, category]);

  return (
    <Box className={styles.parent}>
      <Box className={styles.head}>
        <Typography variant='h4'>Forms</Typography>
        <Box className={styles.buttonGroup}>
          <Box className={`button-preimary ${styles.button}`}>
            {/* <Button
                // className={"secondary-btn"}
                onClick={() => navigate("/dashboard/create-new-form")}
              >
                <AiOutlinePlus /> Create
              </Button> */}
            <Button
              // className={"secondary-btn"}
              onClick={() => navigate(routes.FORM_BUILDER)}>
              <AiOutlinePlus /> Create
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <Grid container>
          <Grid item lg={12}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label='basic tabs example'
              className={styles.buttonTabs}>
              <Tab label='All Forms' {...a11yProps(0)} />
              <Tab label='Archived Forms' {...a11yProps(1)} />
            </Tabs>
          </Grid>
          <Grid item xl={3} lg={4} className='mt-4'>
            {/* <Autocomplete
                id="searchForm"
                size="small"
                disableClearable
                options={formNameOptions}
                className={styles.autocomplete}
                // onInputChange={(handleSearch, newInputValue) => {
                //   setInputValue(newInputValue);
                // }}
                onInputChange={handleSearch}
                // onChange={handleSearch}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search input"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              /> */}
            <input
              type='search'
              name='search'
              id='search'
              className={styles.searchBar}
              placeholder='Search by form name..'
              onChange={handleSearch}
            />
          </Grid>
          <Grid item xl={3} lg={4}>
            <CustomSelect
              customClass={styles.select}
              type='text'
              name='form_category'
              placeholder='Select Category'
              onChange={(el) => handleChangeoption(el)}
              options={categoryOptions}
              style={{ minWidth: "300px" }}
            />
          </Grid>
        </Grid>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <FormsTabel
          titles={[
            "Form Title",
            "Category Name",
            "Status",
            "Resend Form",
            "Actions",
          ]}
          rows={formData}
          setFormData={setFormData}
          setUpdate={setUpdate}
          type='All Forms'
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <FormsTabel
          titles={["Form Title", "Category Name", "Status", "Actions"]}
          rows={deactivateData}
          setUpdate={setUpdate}
          type='deactive'
        />
      </TabPanel>
      <PopupForm open={open} setOpen={setOpen}>
        <CreateForm />
      </PopupForm>
    </Box>
  );
};

export default WorkSpace;

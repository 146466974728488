import React, { useState } from "react";

import "react-datepicker/dist/react-datepicker.css";

import "./styles.scss";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
} from "@mui/material";

const InterviewPopup = ({ opne, setOpen, data, name, certificate }) => {
  // console.log("data", data);

  return (
    <Box className="interviewParrent">
      <Typography variant="h4" className="align-left">
        {name || "Reference Data"}
      </Typography>
      {data?.third_party_name === "backycheck" ? (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Typography variant="h6" component="h6" className="mb-2">
              Person Detail
            </Typography>
            <table style={{ width: "100%" }}>
              <tr>
                <th>Order ID</th>
                <td>{data?.verification_data?.orderDetails?.orderID}</td>
              </tr>
              <tr>
                <th>Name</th>
                <td>
                  {data?.verification_data?.orderDetails?.firstName}{" "}
                  {data?.verification_data?.orderDetails?.surname}
                </td>
              </tr>
              <tr>
                <th>DOB</th>
                <td>{data?.verification_data?.DOB}</td>
              </tr>
            </table>
          </Grid>

          <Grid item xs={12} lg={12}>
            <table style={{ width: "100%" }}>
              <tr>
                <th>Status</th>
                <td>
                  {data?.verification_data?.orderServices?.[0]?.checkStatus !==
                  null
                    ? data?.verification_data?.orderServices?.[0]?.checkStatus
                    : "-"}
                </td>
              </tr>
            </table>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Typography variant="h6" component="h6" className="mb-2">
              Person Detail
            </Typography>
            <table style={{ width: "100%" }}>
              <tr>
                <th>Name</th>
                <td>{data?.verification_data?.Person_Checked?.Name}</td>
              </tr>
              <tr>
                <th>DOB</th>
                <td>{data?.verification_data?.Person_Checked?.DOB}</td>
              </tr>
              <tr>
                <th>Nationality</th>
                <td>{data?.verification_data?.Person_Checked?.Nationality}</td>
              </tr>
              <tr>
                <th>Passport ID</th>
                <td>{data?.verification_data?.Person_Checked?.Passport_ID}</td>
              </tr>
            </table>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography variant="h6" component="h6" className="mb-2">
              Visa Details
            </Typography>
            <table style={{ width: "100%" }}>
              <tr>
                <th>Visa Type Name</th>
                <td>{data?.verification_data?.Visa_Details?.Visa_Type_Name}</td>
              </tr>
              <tr>
                <th>Visa Type Details</th>
                <td>
                  {data?.verification_data?.Visa_Details?.Visa_Type_Details}
                </td>
              </tr>
              <tr>
                <th>Visa Applicant</th>
                <td>{data?.verification_data?.Visa_Details?.Visa_Applicant}</td>
              </tr>
              <tr>
                <th>Work Entitlement</th>
                <td>{data?.verification_data?.Work_Entitlement}</td>
              </tr>
            </table>
          </Grid>
          <Grid item xs={12} lg={12}>
            <table style={{ width: "100%" }}>
              <tr>
                <th>Status</th>
                <td>
                  {data?.verification_data?.status === ""
                    ? "Completed"
                    : data?.verification_data?.status === "error"
                    ? "Rejected"
                    : "-"}
                </td>
              </tr>
            </table>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default InterviewPopup;

import { Grid, Box, Tab, Tabs } from "@mui/material";
import InfoIcon from "../../../assets/dashboard/message_green.svg";
import { a11yProps, TabPanel } from "../../common/mui-tabs";
import styles from "./styles.module.scss";
import React, { useEffect, useState } from "react";
import OfferLetterForm from "./OfferLetterForm";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Sendletter from "./Model/sendletter";
import RejectMsg from "./Model/rejectMsg";
import { ShowAlert, RemoveAlert } from "../../../store/actions/alertActions";
import {
  mirrorBubblesend,
  generatePdf,
  getBubbleFormData,
} from "../../../api/formBuilder/formBuilder.class";

const Formbuilderpreview = () => {
  const dispatch = useDispatch();
  let { candidateid } = useParams();
  const auth = useSelector((state) => state.auth);
  const [formState, setFormState] = useState(0);
  const [bubbleform, setBubble] = useState({
    recruiter_name: auth?.results?.first_name
      ? auth?.results?.first_name + " " + auth?.results?.last_name
      : "",
    recruiter_address: auth?.results?.company_detail?.address
      ? auth?.results?.company_detail?.address
      : "",
    recruiter_email: auth?.results?.email ? auth?.results?.email : "",
    recruiter_full_name: auth?.results?.first_name
      ? auth?.results?.first_name + " " + auth?.results?.last_name
      : "",
    recruiter_contact_number: auth?.results?.contact_number
      ? auth?.results?.contact_number
      : "",
    recruiter_company_name: auth?.results?.company_detail?.name
      ? auth?.results?.company_detail?.name
      : "",
  });

  const [bubbleform2, setBubble2] = useState({});
  const [setOpenmodelsendle, setOpenmodelsendletter] = useState(false);
  const [Openmodel, setOpenmodel] = useState(false);
  const [pdfURL, setPdfURL] = useState("");
  const [pdfURLNew, setPdfURLNew] = useState("");
  const [loader, setLoader] = useState(0);

  useEffect(() => {});
  const [value, setValue] = useState(0);
  const [file, setFile] = useState({ resume_path: "" });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const formData = async () => {
    try {
      const payload = {
        recruiter_id: auth.results.recruiter_id,
        workspace_id: auth.results.workspace_id,
        candidate_id: candidateid,
        form_category_name: "Job Offer Letter",
        form_builder_category_id: 1,
      };
      const response = await getBubbleFormData(payload);

      if (response?.data?.results?.form_data) {
        setBubble(response?.data?.results?.form_data);
      }

      setBubble2(response?.data);
      setPdfURL(response?.data?.results?.pdf_url);
    } catch (error) {}
  };

  useEffect(() => {
    formData();
  }, [setOpenmodelsendle]);

  const handleSubmit = () => {
    const api = async () => {
      try {
        const payload = {
          recruiter_id: auth.results.recruiter_id,
          workspace_id: auth.results.workspace_id,
          candidate_id: candidateid,
          form_category_name: "Job Offer Letter",
          form_builder_category_id: 1,
        };

        const response = await mirrorBubblesend(payload);

        if (response?.data?.success == true) {
          dispatch(ShowAlert(response?.data?.message, "success"));
        } else {
          dispatch(ShowAlert(response?.data?.message, "error"));
        }
      } catch (error) {
        console.log(error);
      }
    };
    api();
  };

  const handleModelOpen = () => {
    if (pdfURL !== "") {
      setOpenmodelsendletter(true);
    } else {
      dispatch(ShowAlert("Generate PDF first!"));
    }
  };
  const handleGenerate = () => {
    let flag = 0;

    if (
      bubbleform?.recruiter_name == "" ||
      bubbleform?.recruiter_name == undefined
    ) {
      flag++;

      dispatch(ShowAlert("Name field is required", "error"));
      // process.exit(0);
    }

    if (
      bubbleform?.recruiter_address == "" ||
      bubbleform?.recruiter_address == undefined
    ) {
      flag++;
      dispatch(ShowAlert("Address field is required", "error"));
      // process.exit(0);
    }

    if (
      bubbleform?.recruiter_email == "" ||
      bubbleform?.recruiter_email == undefined
    ) {
      flag++;
      dispatch(ShowAlert("Email field is required", "error"));
      // process.exit(0);
    }

    if (
      bubbleform?.candidate_name == "" ||
      bubbleform?.candidate_name == undefined
    ) {
      flag++;
      dispatch(ShowAlert("Candidate Name field is required", "error"));
      // process.exit(0);
    }

    if (bubbleform?.job_company == "" || bubbleform?.job_company == undefined) {
      flag++;
      dispatch(ShowAlert("Job Company field is required", "error"));
      // process.exit(0);
    }

    if (bubbleform?.job_role == "" || bubbleform?.job_role == undefined) {
      flag++;
      dispatch(ShowAlert("Job Role field is required", "error"));
      // process.exit(0);
    }

    if (
      bubbleform?.currency_code == "" ||
      bubbleform?.currency_code == undefined
    ) {
      flag++;
      dispatch(ShowAlert("Currency code field is required", "error"));
      // process.exit(0);
    }

    if (
      bubbleform?.salary_amount == "" ||
      bubbleform?.salary_amount == undefined
    ) {
      flag++;
      dispatch(ShowAlert("Job package field is required", "error"));
      // process.exit(0);
    }

    if (
      bubbleform?.company_name == "" ||
      bubbleform?.company_name == undefined
    ) {
      flag++;
      dispatch(ShowAlert("Company name field is required", "error"));
      // process.exit(0);
    }

    if (
      bubbleform?.recruiter_full_name == "" ||
      bubbleform?.recruiter_full_name == undefined
    ) {
      flag++;
      dispatch(ShowAlert("Recruiter name field is required", "error"));
      // process.exit(0);
    }

    if (
      bubbleform?.recruiter_company_name == "" ||
      bubbleform?.recruiter_company_name == undefined
    ) {
      flag++;
      dispatch(ShowAlert("Recruiter Company field is required", "error"));
      // process.exit(0);
    }

    // if (
    //   bubbleform.candidate_full_name == "" ||
    //   bubbleform.candidate_full_name == undefined
    // ) {
    //   flag++;
    //   dispatch(ShowAlert("Candidate full name field is required", "error"));
    //   process.exit(0);
    // }

    if (flag === 0) {
      const generate = async () => {
        try {
          const payload2 = {
            formdata: bubbleform,
            recruiter_id: auth.results.recruiter_id,
            workspace_id: auth.results.workspace_id,
            candidate_id: candidateid,
            form_category_name: "Job Offer Letter",
            form_builder_category_id: "1",
            html_data: "",
            form_logo_image_id: bubbleform.form_logo_image_id,
          };

          const response = await generatePdf(payload2);
          if (response?.data?.success == true) {
            dispatch(ShowAlert(response?.data?.message, "success"));
          } else {
            dispatch(ShowAlert(response?.data?.message, "success"));
          }
          setPdfURL(response?.results?.pdf_url);
        } catch (error) {
          console.log(error);
        }
      };
      generate();
    }
  };

  const initialValues = {
    name: "",
  };

  const fieldRequiredSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className={styles.buttonTabs}
            >
              <Tab
                label="Edit"
                onClick={() => {
                  setFormState(0);
                }}
              />
              <Tab
                label="Preview"
                onClick={() => {
                  setFormState(1);
                }}
              />
            </Tabs>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box className={`${styles.buttons} button-primary`}>
            <button
              className="secondary-btn"
              onClick={() => {
                handleGenerate();
              }}
            >
              Generate Letter
            </button>

            <button
              onClick={() => {
                handleModelOpen();
              }}
            >
              {bubbleform2?.results?.response === "completed"
                ? "Resend Letter"
                : "Send Letter"}
            </button>
          </Box>
        </Grid>
        {bubbleform2?.results?.response === "rejected" ? (
          <Grid item md={12} className={styles.rejectedblock}>
            <Box className={`${styles.buttons} button-primary`}>
              <div
                onClick={() => {
                  setOpenmodel(true);
                }}
              >
                <img src={InfoIcon} alt="info" />
                Job offer rejected
              </div>
            </Box>
          </Grid>
        ) : (
          ""
        )}
      </Grid>

      <TabPanel value={value} index={formState}>
        <OfferLetterForm
          formState={formState}
          setBubble={setBubble}
          bubbleform={bubbleform}
          setFile={setFile}
          file={file}
          initialValues={initialValues}
          fieldRequiredSchema={fieldRequiredSchema}
        />
      </TabPanel>

      <Sendletter
        openModal={setOpenmodelsendle}
        setOpenModal={setOpenmodelsendletter}
        olddata={bubbleform2}
      />
      <RejectMsg
        openModal={Openmodel}
        setOpenModal={setOpenmodel}
        olddata={bubbleform2?.results?.reason}
      />
    </>
  );
};

export default Formbuilderpreview;

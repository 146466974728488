import React from 'react';

function MessageBox({ message }) {
  return (
    <div className="form_box email_verfication">
      <p>{message}</p>
    </div>
  );
}

export default MessageBox;

import React, { useState } from "react";

import TextInput from "../../common/TextInput/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./styles.scss";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import CustomSelect from "../../common/Select";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../store/actions/alertActions";
import {
  createSingleInterviewFunc,
  resetSingleCandidateReff,
  updateSingleInterviewFunc,
} from "../../../store/actions/singleCandidateScreenActions";
import { ReactComponent as Add } from "../../../assets/interview/addInterview.svg";
import { ReactComponent as Close } from "../../../assets/interview/close.svg";
import { useEffect } from "react";
import TimeRange from "react-time-range";
import ImageUploader from "../../common/ImageUploader";

const InterviewPopup = ({ opne, setOpen, data }) => {
  const auth = useSelector((state) => state?.auth);
  const candidate = useSelector((state) => state?.rcandidate);
  const singleCandidate = useSelector((state) => state?.singleCandidate);

  const [selectValue, setSelectValue] = useState(0);
  const [startDate, setStartDate] = useState(
    singleCandidate?.editInterView?.results?.date
      ? new Date(singleCandidate?.editInterView?.results?.date)
      : new Date()
  );
  // console.log(startDate);
  const candidateID = JSON.parse(localStorage.getItem("candidateID"));

  const [edit1, setEdit1] = useState(false);
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();

  const options = [
    { value: "Office", label: "Office" },
    { value: "Online", label: "Online" },
  ];
  const options2 = [
    { value: "Custom Support", label: "Custom Support" },
    { value: "Technical Support", label: "Technical Support" },
    { value: " Billing Support    ", label: " Billing Support    " },
    { value: "Feedback", label: "Feedback" },
  ];

  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = (hour % 12 || 12).toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        times.push(
          `${formattedHour}:${formattedMinute} ${hour >= 12 ? "PM" : "AM"}`
        );
      }
    }
    return times;
  };
  const timeOptions = generateTimeOptions();

  // console.log(singleCandidate?.editInterView?.results?.time.split(" "));
  const date = new Date();
  const mapTags =
    singleCandidate?.editInterView?.results?.candidate_interview_invitation?.map(
      (e) => (e?.length === 0 ? [] : { email: e?.email })
    );

  const [inputList, setInputList] = useState(
    mapTags?.length > 0
      ? mapTags
      : [{ email: candidate?.showCandidate?.results?.email }]
  );

  const [values, setValues] = useState({
    title: singleCandidate?.editInterView?.results?.title || "",
    emails: "",
    tags: [candidate?.showCandidate?.results?.email] || mapTags,
    description: singleCandidate?.editInterView?.results?.description || "",

    time: singleCandidate?.editInterView?.results?.start_time
      ? singleCandidate?.editInterView?.results?.start_time
      : "",
    time2: singleCandidate?.editInterView?.results?.end_time
      ? singleCandidate?.editInterView?.results?.end_time
      : "",

    type: {
      value: singleCandidate?.editInterView?.results?.type,
      label: singleCandidate?.editInterView?.results?.type,
    } || { value: "", label: "" },
    topic: singleCandidate?.editInterView?.results?.topic || "",
    address: singleCandidate?.editInterView?.results?.address || "",
  });

  function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
  }

  const [Customerror, setErrors] = useState({});
  const validate = (data) => {
    const newError = {};
    for (let key in data) {
      let value = data[key];
      switch (key) {
        case "title":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "fields is Required";
          break;
        case "description":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "fields is Required";
          break;
        case "time":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "fields is Required";
          // else if (new Date().getTime() <= new Date().getTime(values?.time))
          //   newError[key] = "Please select correct date";
          break;
        case "time2":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "fields is Required";
          // else if (new Date().getTime() <= new Date().getTime(values?.time))
          //   newError[key] = "Please select correct date";
          break;

        case "type":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "fields is Required";
          break;
        // case "topic":
        //   if (value.length === 0 || value.toString() === "")
        //     newError[key] = "fields is Required";
        //   break;
        case "emails":
          if (!ValidateEmail(value))
            newError[key] = "Please Add Email In Correct Format";
          else if (values?.tags?.includes(value))
            newError[key] = "Email Already in Tags";
          break;
        default:
      }
    }
    return newError;
  };

  const onChange = (e) => {
    const { name, value } = e?.target;
    if (Customerror.hasOwnProperty(name)) {
      delete Customerror[name];
      setErrors(Customerror);
    }

    setValues((preVal) => {
      return {
        ...preVal,

        [name]: value,
      };
    });
  };

  const submitData = async (e) => {
    const condition =
      inputList?.length > 0 &&
      inputList?.find((e, i) => (e?.email?.trim() === "" ? true : false));

    if (condition?.email === "") {
      return dispatch(ShowAlert("Please fill all fields", "error"));
    }
    e.preventDefault();
    const validateSelect = validate(values);
    if (values.emails === "") {
      delete validateSelect["emails"];
    }
    if (Object?.keys(validateSelect)?.length > 0) {
      dispatch(ShowAlert("Please fill all fields", "error"));

      setErrors(validateSelect);
      return;
    }
    let objectCreate = {};

    let recruitment_industries = inputList?.map((e, index) => {
      return (objectCreate[`email_invitation_${index}`] = {
        email: e?.email,
      });
    });

    let local_data = {
      ...values,
      email_invitations: JSON.stringify(objectCreate),
      type: values?.type?.value.toLowerCase(),
      address: values?.address,
      // values?.type?.value === "Office"
      //   ? "20-40 Meagher St, Sydney NSW 2008, Australia"
      //   : "",
      date: moment(startDate).format("DD-MM-YYYY"),

      recruiter_id: auth?.results?.recruiter_id,
      workspace_id: auth?.results?.workspace_id,
      candidate_id: candidate?.showCandidate?.results?.id || candidateID,
      // start_time: startDate,
      start_time: values?.time,
      end_time: values?.time2,
      duration: "60",
      host_video: "1",
      agenda: "testsetset",
      participant_video: "1",
      front_image: file,
      // "recruitment_industries[recruitment_industry_1][front_image]":
      //   file?.front_image,
    };

    let formData = new FormData();

    for (const key in local_data) {
      formData.append([key], local_data[key]);
    }

    const local_data2 = {
      ...values,
      uuid: singleCandidate?.editInterView?.results?.uuid,
      email_invitations: objectCreate,
      type: values?.type?.value.toLowerCase(),
      address: values?.address,
      // values?.type?.value.toLowerCase() === "Office".toLowerCase()
      //   ? "20-40 Meagher St, Sydney NSW 2008, Australia"
      //   : "",

      date: moment(startDate).format("DD-MM-YYYY"),
      recruiter_id: auth?.results?.recruiter_id,
      workspace_id: auth?.results?.workspace_id,
      candidate_id: candidate?.showCandidate?.results?.id || candidateID,
      // start_time: startDate,
      start_time: values?.time,
      end_time: values?.time2,
      duration: "60",
      host_video: "1",
      agenda: "testsetset",
      participant_video: "1",
      zoom_id: "82629994706",
      host_id: "FQVcUJkIQ1iNYCmcnvjumA",
      front_image: file,
      // "recruitment_industries[recruitment_industry_1][front_image]":
      //   file?.front_image,
    };

    if (singleCandidate?.editInterView?.success === true) {
      dispatch(
        updateSingleInterviewFunc(local_data2, {
          workspace_id: auth?.results?.workspace_id,
          candidate_id: candidate?.showCandidate?.results?.id || candidateID,
        })
      );
    } else {
      // delete local_data?.front_image;
      dispatch(
        createSingleInterviewFunc(formData, {
          workspace_id: auth?.results?.workspace_id,
          candidate_id: candidate?.showCandidate?.results?.id || candidateID,
        })
      );
    }

    // createInterView
    //   "":"eyJpdiI6Im9BR2lqek9TWHpEYVB6YXgzOUY5eFE9PSIsInZhbHVlIjoiTmVNT2hCNmYxQkdvV0daSndYdFM1UT09IiwibWFjIjoiYWM0NWRjZmU2YmIyMGE5NmE5YjkwYTQ3YjJmMmNkZWJiMDExMGRiMjJhNTVlZTNlOWY1ZmIzODk1MWQ2YzdjZSIsInRhZyI6IiJ9",
    //   "workspace_id":"1",
    //   "candidate_id":"2",
    //   "title":"Web Development",
    //   "description":"Web Development description",
    //   "date":"29-09-2022",
    //   "time":"08:16",
    //   "type":"online",
    // "topic":"Test",
    // "start_time":"2022-09-29T20:00:00Z",
    // "duration":"60",
    // "host_video":1,
    // "agenda":"testsetset",
    // "participant_video":1
  };
  useEffect(() => {
    if (singleCandidate?.createInterView?.data?.success === true) {
      setOpen(false);
      dispatch(resetSingleCandidateReff());
    }
  }, [singleCandidate?.createInterView?.data?.success]);

  useEffect(() => {
    if (singleCandidate?.updateInterView?.success === true) {
      setOpen(false);
      dispatch(resetSingleCandidateReff());
    }
  }, [singleCandidate?.updateInterView?.success]);

  const onKeyDown = (e) => {
    const { key } = e;

    const trimmedInput = values?.emails?.trim();

    if (
      key === "Tab" &&
      trimmedInput.length &&
      !values?.tags?.includes(trimmedInput)
    ) {
      e.preventDefault();
      const validateSelect = validate(values);
      if (Object?.keys(validateSelect)?.length > 0) {
        // console.log(validateSelect);
        // dispatch(ShowAlert("Please Fill All fields", "error"));
        setErrors(validateSelect);
        // return;
        if (validateSelect.emails) {
          return;
        }
      }

      setValues({
        ...values,
        tags: [...values?.tags, trimmedInput],
        emails: "",
      });
    }
  };
  const removeIndex = (index) => {
    if (index === 0) {
      return;
    }
    let newIndex = values?.tags.splice(index, 1);

    setValues({ ...values });
    return newIndex;
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (e, index) => {
    e?.preventDefault();
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = (e) => {
    setInputList([...inputList, { email: "" }]);
    e?.preventDefault();
  };

  return (
    <Box className="interviewParrent">
      <Typography variant="h3">Schedule Interview</Typography>
      <form onSubmit={submitData}>
        <Box className="date-picker">
          <label>Date</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="Date *"
            minDate={moment().toDate()}
          />
        </Box>
        {/* <Box className={"timebox"}>
          <TextInput
            type="time"
            name="time"
            label="Start Time"
            placeholder="Time *"
            value={values.time}
            onChange={onChange}
            customClass={"inputInterview"}
          />
          <TextInput
            type="time"
            name="time2"
            label="End Time"
            placeholder="Time *"
            value={values.time2}
            onChange={onChange}
            customClass={"inputInterview"}
          />
        </Box> */}
        <Box className={"timebox"}>
          {/* <TextInput
            type="time"
            name="time"
            label="Start Time"
            placeholder="Time *"
            value={values.time}
            onChange={onChange}
            customClass={"inputInterview"}
          /> */}

          {/* <TimeRange
            onStartTimeChange={(e) =>
              setValues({ ...values, time: e?.startTime })
            }
            className="time-picker-custom"
            startLabel={"Start Time"}
            endLabel={"End Time"}
            onEndTimeChange={(e) => setValues({ ...values, time2: e?.endTime })}
            startMoment={values?.time}
            endMoment={values?.time2}
          /> */}
          {/* <TimePicker onChange={onChangeee} value={valueee} />*/}

          {/* <TextInput
            type="time"
            name="time"
            data-open="DatePickerID"
            label="Start Time"
            placeholder="Time *"
            value={values.time}
            onChange={onChange}
            customClass={"inputInterview"}
          />
          <TextInput
            type="time"
            name="time2"
            data-open="DatePickerID"
            label="End Time"
            placeholder="Time *"
            value={values.time2}
            onChange={onChange}
            customClass={"inputInterview"}
          /> */}
          <label htmlFor="">
            Start Time<span style={{ color: "red" }}>*</span>
          </label>
          <select
            id="time"
            name="time"
            value={values.time}
            onChange={onChange}
            className="inputInterview"
            required
          >
            {timeOptions.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>

          <label htmlFor="" style={{ marginTop: "20px" }}>
            End Time<span style={{ color: "red" }}>*</span>
          </label>
          <select
            id="time"
            name="time2"
            value={values.time2}
            onChange={onChange}
            className="inputInterview"
            required
          >
            {timeOptions.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>
        </Box>

        <CustomSelect
          options={options}
          customClass={"interviewSelect"}
          placeholder="Location *"
          label="Location"
          name="type"
          value={values.type?.value ? values?.type : null}
          onChange={(e) => {
            setValues({ ...values, type: e });
            if (Customerror.hasOwnProperty("type")) {
              delete Customerror["type"];
              setErrors(Customerror);
            }
          }}
          // onChange={(location) => setSelectValue(location.value)}
        />
        {values?.type?.value === "Office" && (
          <TextInput
            type="text"
            name="address"
            label="Address"
            value={values?.address}
            onChange={onChange}
            placeholder="20-40 Meagher St, Sydney NSW 2008, Australia"
            customClass={"inputInterview"}
          />
        )}

        {/* <CustomSelect
          options={options2}
          customClass={"interviewSelect"}
          placeholder="Subject *"
          name="type"
          value={values.title}
          onChange={(e) => {
            setValues({ ...values, title: e });
            if (Customerror.hasOwnProperty("title")) {
              delete Customerror["title"];
              setErrors(Customerror);
            }
          }}
          // onChange={(location) => setSelectValue(location.value)}
        /> */}
        <TextInput
          type="text"
          name="title"
          label="Subject"
          value={values.title}
          placeholder="Subject *"
          customClass={"inputInterview"}
          onChange={onChange}
        />
        <TextInput
          type="text"
          name="description"
          label="Description"
          placeholder="Description *"
          value={values.description}
          customClass={"inputInterview"}
          textarea={true}
          onChange={onChange}
        />

        {/* <TextInput
          type="text"
          name="topic"
          placeholder="Topic *"
          value={values.topic}
          onChange={onChange}
          customClass={"inputInterview"}
        /> */}
        <Box>
          <ImageUploader
            label={
              <>
                <span>Upload Resume</span>
              </>
            }
            valuenew={file}
            edit={edit1}
            accept={".pdf, .docx"}
            onChange={(e) => {
              // setFieldValue("front_image", e.target.files[0]);
              setEdit1(false);
              setFile(e.target.files[0]);
            }}
            name="front_image"
            // onChange={onChange}
          />
        </Box>
        <>
          {inputList?.map((e, i) => (
            <Box className="parrentCandidateCloseInvite" key={i}>
              <TextInput
                type="text"
                key={i}
                name="email"
                label={i === 0 ? "Candidate Email" : "Optional Attendees"}
                value={e?.email}
                disabled={i === 0 && true}
                placeholder="Enter Email"
                customClass={"inputInterview"}
                onChange={(e) => handleInputChange(e, i)}
              />
              {i !== 0 && (
                <Close
                  className="candidateCloseInvite"
                  onClick={(e) => handleRemoveClick(e, i)}
                />
              )}
            </Box>
          ))}
          <Box className={"addEmailCandidate"}>
            <Add onClick={(e) => handleAddClick(e)} />
            <Typography>Add Optional Attendees</Typography>
          </Box>
        </>

        <Box className="button-primary buttoninterview">
          <Button onClick={submitData} disabled={singleCandidate?.loading}>
            {singleCandidate?.loading ? (
              <p style={{ color: "white" }}>Loading...</p>
            ) : singleCandidate?.editInterView?.success ? (
              " Update"
            ) : (
              " Send Invite"
            )}
          </Button>
          <Button
            className="secondary-btn"
            onClick={() => {
              setOpen(false);
              dispatch(resetSingleCandidateReff());
            }}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default InterviewPopup;

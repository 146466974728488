import {
  COL_FORM_STRUCTURE,
  COL_FORM_STRUCTURE_UPDATE,
} from "../constants/constants";

export const storeColFormStructure = (type, value) => {
  switch (type) {
    case COL_FORM_STRUCTURE:
      return {
        type: COL_FORM_STRUCTURE,
        payload: value,
      };
    case COL_FORM_STRUCTURE_UPDATE:
      return {
        type: COL_FORM_STRUCTURE_UPDATE,
        payload: value,
      };

    default:
      return value;
  }
};

import React, { useRef } from "react";
import CustomSelect from "../../../common/Select";
import { Box } from "@mui/system";
import Actions from "./Actions";
import style from "./style.module.scss";
import { useSelector } from "react-redux";
import useDragField from "../../../../customHook/useDragField";

function SelectField({
  label,
  type,
  id,
  main_id,
  value,
  require,
  placeholder,
  name,
  two_col,
  col_name,
  SelectField,
  index,
  moveCard,
}) {
  const getpreviewform = useSelector((state) => state?.getpreviewform);
  const ref = useRef(null);
  const dragField = useDragField(ref, index, moveCard, id);
  const opacity = dragField.opacity;
  // const options = [
  //   { value: "Part Time", label: "Part Time" },
  //   { value: "Full Time", label: "Full Time" },
  //   { value: "Remote", label: "Remote" },
  // ];
  return (
    <Box
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={dragField?.handlerId}
      className={`${style.inputBox} dropItem`}
    >
      <Actions
        type={type}
        id={id}
        two_col={two_col}
        col_name={col_name}
        main_id={main_id}
      />
      <label htmlFor="">{label}</label>
      <select id="" name={name}>
        <option disabled selected>
          {placeholder}
        </option>
        {two_col === 1
          ? getpreviewform.map((item) => {
              return item?.type[col_name]?.data?.map((subcol) => {
                if (subcol?.id === id) {
                  {
                    return subcol.type.options.map((subitem, index) => {
                      return subitem.value === SelectField ? (
                        <option selected value={subitem.value} key={index}>
                          {subitem.label}
                        </option>
                      ) : (
                        <option value={subitem.value} key={index}>
                          {subitem.label}
                        </option>
                      );
                    });
                  }
                }
              });
            })
          : getpreviewform.map((item) => {
              if (item.id === id) {
                {
                  return item.type.options.map((option, index) => {
                    return option.value === SelectField ? (
                      <option selected value={option.value} key={index}>
                        {option.label}
                      </option>
                    ) : (
                      <option value={option.value} key={index}>
                        {option.label}
                      </option>
                    );
                  });
                }
              }
            })}
      </select>
    </Box>
  );
}

export default SelectField;

import React, { useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Table, Button } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "./styles.module.scss";
import { getSubmitRequest } from "../../../api/candidate/candidate.class";
import { Link, useNavigate } from "react-router-dom";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { ReactComponent as Eye } from "../../../assets/candidates/eye.svg";
import Accept from "../requests/Model/accept";
import Reject from "../requests/Model/reject";
import Modelcandidate from "../requests/Model/model-candidate";
import { useSelector } from "react-redux";
import { getBubbleindex } from "../../../api/candidate/candidate.class";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    width: "90px",
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "10px",
    background: "#00CFC5",
    color: "white",
  },
}));

const Index = () => {
  const [formData, setFormData] = useState([]);
  const [Openmodel, setOpenpopop] = useState(false);
  const [jobofferid, setJobofferid] = useState();
  const [jobDenyToken, setJobDenyToken] = useState();
  const [jobAcceptToken, setJobAcceptToken] = useState();
  const [Openmodelacpt, setOpenpopopaccept] = useState(false);
  const [Openmodelrejt, setOpenpopopreject] = useState(false);
  const [singleformdata, setSingleformdata] = useState();

  const auth = useSelector((state) => state.auth);

  const navigate = useNavigate();
  useEffect(() => {
    const tableData = async () => {
      try {
        const response = await getSubmitRequest();
        // console.log("response", response);

        setFormData(response?.data?.results?.data);
      } catch (e) {
        console.log(e);
      }
    };

    tableData();
  }, []);

  useEffect(() => {
    if (Openmodel) {
      const getsinglejobofferdata = async () => {
        try {
          const payload = {
            form_request_uuid: jobofferid,
            candidate_id: auth.results.id,
          };

          const response = await getBubbleindex(payload);
          console.log("response", response);
          setSingleformdata(response?.data?.results);
        } catch (e) {}
      };
      getsinglejobofferdata();
    }
  }, [Openmodel]);

  console.log("formDataSubmit", formData);
  console.log("singleformdata", singleformdata);

  return (
    <>
      <Box className={styles.parent}>
        <Typography variant="h4">Submission List</Typography>
        <Box className={styles.customBox}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{ "& td, & th": { border: 0 } }}
                  className={styles.headingBox}
                >
                  <TableCell align="left">Form Title</TableCell>
                  <TableCell align="center">Category Name</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Time </TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData?.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ "& td, & th": { border: 0 } }}
                    className={styles.contentBox}
                  >
                    <TableCell align="left">
                      {row?.form_builder_category?.name === "Job Offer Letter"
                        ? "Offer Letter"
                        : row?.form_builder?.name}
                    </TableCell>
                    <TableCell align="center">
                      {row?.form_builder_category?.name === "Job Offer Letter"
                        ? row?.form_builder_category?.name
                        : row?.form_builder?.form_builder_category?.name}
                    </TableCell>
                    <TableCell align="center">{row?.created_at}</TableCell>
                    <TableCell align="center">{row?.time}</TableCell>
                    <TableCell align="center">
                      {row.form_builder_category.id === 1 ? (
                        <Tooltip title="View" className="">
                          <Eye
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setOpenpopop(true);
                              setJobofferid(row?.form_request_uuid);
                              setJobDenyToken(row?.deny_token);
                              setJobAcceptToken(row?.accept_token);
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="View" className="">
                          <Eye
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/dashboard/form/view/${row?.uuid}`, {
                                state: {
                                  data: row?.form_builder?.form_builder_json,
                                  uuid: row?.uuid,
                                  json_type: row?.form_builder?.json_type,
                                  formData: row.submit_data,
                                },
                              })
                            }
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Modelcandidate
        openModal={Openmodel}
        setOpenModal={setOpenpopop}
        setOpenpopopaccept={setOpenpopopaccept}
        setOpenpopopreject={setOpenpopopreject}
        singleformdata={singleformdata}
      />
      <Accept
        openModal={Openmodelacpt}
        setOpenModal={setOpenpopopaccept}
        singleformdata={singleformdata}
        acceptToken={jobAcceptToken}
      />
      <Reject
        openModal={Openmodelrejt}
        setOpenModal={setOpenpopopreject}
        singleformdata={singleformdata}
        denyToken={jobDenyToken}
      />
    </>
  );
};

export default Index;

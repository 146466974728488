import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

import { uid } from './helpers';

function encryptString(str, encrytionKey) {
  const encrypted = CryptoJS.AES.encrypt(str, encrytionKey).toString();
  return encrypted;
}

function decryptString(encryptedString, key) {
  const originalText = CryptoJS.AES.decrypt(encryptedString, key).toString(
    CryptoJS.enc.Utf8
  );
  return originalText;
}

function objectToJSONBlob(object) {
  const jsonFile = new Blob([JSON.stringify({ object: object })], {
    type: 'application/json',
  });
  const uid =
    new Date().getTime() +
    Date.now().toString(36) +
    Math.random().toString(36).substr(2);

  jsonFile.name = `${uuidv4() + uid}.json`;
  return jsonFile;
}

function SHA1_hash(str) {
  return CryptoJS.SHA1(str).toString();
}

async function encryptFileAsync(file, key) {
  // console.log(file)
  const promise = await new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const result = event.target.result;
      //console.log('filereader result', result)
      resolve(result);
    };
    fileReader.onerror = (event) => {
      reject(null);
    };

    fileReader.readAsDataURL(file);
  });

  if (!promise) {
    return null;
  }
  const hash = await SHA1_hash(file);
  const name = file.name;
  const type = file.type;

  const encrypted = await encryptString(promise, key);

  const jsonFile = new Blob(
    [JSON.stringify({ name, hash, type, data: encrypted })],
    {
      type: 'application/json',
    }
  );
  jsonFile.name = `${uid()}.json`;

  const json = new File([jsonFile], jsonFile.name, {
    type: 'application/json',
  });

  return json;
}

function objectToFile(obj) {
  const jsonFile = new Blob([JSON.stringify(obj)], {
    type: 'application/json',
  });
  jsonFile.name = `${uid()}.json`;
  return new File([jsonFile], jsonFile.name, { type: 'application/json' });
}

export const EDH = {
  encryptString,
  decryptString,
  objectToJSONBlob,
  SHA1_hash,
  encryptFileAsync,
  objectToFile,
};

import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Table, Button, Grid } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "./styles.module.scss";
import { getFormRequest } from "../../../api/candidate/candidate.class";
import { getFormRequestfilter } from "../../../api/candidate/candidate.class";
import { Link, useNavigate } from "react-router-dom";
import Modelcandidate from "./Model/model-candidate";
import EDocModal from "./Model/e_doc_modal";
import Accept from "./Model/accept";
import Reject from "./Model/reject";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import CustomSelect from "../../common/Select";
import { useSelector } from "react-redux";
import {
  getFormCategory,
  getBubbleindex,
} from "../../../api/candidate/candidate.class";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    width: "90px",
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "10px",
    background: "#00CFC5",
    color: "white",
  },
}));

const Index = () => {
  const [category, setCategory] = useState();
  const [categoryOptions, setCategoryOptions] = useState();

  const auth = useSelector((state) => state.auth);

  const [Openmodel, setOpenpopop] = useState(false);
  const [OpenEDocModal, setOpenEDocModal] = useState(false);

  const [jobofferid, setJobofferid] = useState();
  const [singleformdata, setSingleformdata] = useState();
  const [jobDenyToken, setJobDenyToken] = useState();
  const [jobAcceptToken, setJobAcceptToken] = useState();

  const [Openmodelacpt, setOpenpopopaccept] = useState(false);
  const [Openmodelrejt, setOpenpopopreject] = useState(false);

  const [formData, setFormData] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const tableData = async () => {
      try {
        const response = await getFormRequest();
        // console.log(response?.data?.results?.data);
        setFormData(response?.data?.results?.data);
      } catch (e) {}
    };

    tableData();
  }, []);

  // console.log("formData", formData);

  useEffect(() => {
    if (Openmodel) {
      const getsinglejobofferdata = async () => {
        try {
          const payload = {
            form_request_uuid: jobofferid,
            candidate_id: auth.results.id,
          };

          const response = await getBubbleindex(payload);
          setSingleformdata(response?.data?.results);
        } catch (e) {}
      };
      getsinglejobofferdata();
    }
  }, [Openmodel]);

  useEffect(() => {
    const api = async () => {
      try {
        const response = await getFormCategory({
          //workspace_id: auth.results.workspace_id,
        });

        setCategory(response.data.results);
      } catch (error) {}
    };
    api();
  }, []);

  useEffect(() => {
    if (category?.length > 0) {
      const all = [{ id: "All", name: "All" }];

      [...all, ...category].forEach((record) => {
        const data = [{ value: record.id, label: record.name }];
        setCategoryOptions((prev) => [...prev, ...data]);
      });
    }

    return () => {
      setCategoryOptions("");
    };
  }, [category]);

  const handleChangeoption = (value) => {
    const tableData = async () => {
      try {
        if (value.value === "All") {
          const response = await getFormRequest();
          setFormData(response?.data?.results?.data);
        } else {
          const payload = {
            category_id: value.value,
            name: value.label,
          };
          const response = await getFormRequestfilter(payload);
          setFormData(response?.data?.results?.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    tableData();
  };

  // const handelNavigate = (rowData) => {
  //   if (row) {

  //   }
  //  }

  // console.log("formData", formData);

  return (
    <>
      <Box className={styles.parent}>
        <div
          className={`${styles.slectLebel} d-flex flex-wrap`}
          style={{ gap: "16px" }}
        >
          <Typography variant="h4" className="mb-0">
            Form request list{" "}
          </Typography>

          <CustomSelect
            customClass={styles.select}
            type="text"
            name="form_category"
            placeholder="Select Category"
            onChange={(el) => handleChangeoption(el)}
            options={categoryOptions}
            style={{ minWidth: "300px" }}
          />
        </div>

        <Box className={styles.customBox}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{ "& td, & th": { border: 0 } }}
                  className={styles.headingBox}
                >
                  <TableCell align="left">Form Title</TableCell>
                  <TableCell align="">Category Name</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              {formData !== undefined ? (
                <TableBody>
                  {formData?.map((row, i) =>
                    row?.response !== "completed" ||
                    row?.form_builder_category?.id === 1 ? (
                      <TableRow
                        key={i}
                        sx={{ "& td, & th": { border: 0 } }}
                        className={styles.contentBox}
                      >
                        <TableCell align="left">
                          {row.form_builder_category.id === 1
                            ? "Offer Letter"
                            : row?.form_builder?.name}
                        </TableCell>
                        <TableCell align="">
                          {row.form_builder_category.id === 1
                            ? row?.form_builder_category?.name
                            : row?.form_builder?.form_builder_category?.name}
                        </TableCell>
                        <TableCell align="center">
                          {"  "}
                          <StyledBadge
                            badgeContent={row?.response ? row?.response : ""}
                          />
                        </TableCell>
                        <TableCell align="center" className={styles.link}>
                          {row.form_builder_category.id === 1 ? (
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "#00CFC5",
                                color: "#FFFFFF",
                                border: "none",
                                cursor: "pointer",
                                minWidth: "100px",
                              }}
                              onClick={() => {
                                setOpenpopop(true);
                                setJobofferid(row?.uuid);
                                setJobDenyToken(row?.deny_token);
                                setJobAcceptToken(row?.accept_token);
                              }}
                            >
                              View
                            </Button>
                          ) : row?.response !== "completed" ? (
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "#00CFC5",
                                color: "#FFFFFF",
                                border: "none",
                                cursor: "pointer",
                                minWidth: "100px",
                              }}
                              onClick={() =>
                                // handelNavigate(row)
                                navigate(`/dashboard/form/view/${row?.uuid}`, {
                                  state: {
                                    data: row?.form_builder?.form_builder_json,
                                    formData:
                                      row?.form_builder?.form_builder_json,
                                    uuid: row?.form_builder?.uuid,
                                    type: "candidate",
                                    json_type: row?.form_builder?.json_type,
                                    row: row,
                                  },
                                })
                              }
                            >
                              Complete
                            </Button>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )
                  )}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4} className="border-0">
                      <Typography variant="h5" className="text-center">
                        No Data Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Modelcandidate
        openModal={Openmodel}
        setOpenModal={setOpenpopop}
        setOpenpopopaccept={setOpenpopopaccept}
        setOpenpopopreject={setOpenpopopreject}
        singleformdata={singleformdata}
      />
      <Accept
        openModal={Openmodelacpt}
        setOpenModal={setOpenpopopaccept}
        singleformdata={singleformdata}
        acceptToken={jobAcceptToken}
      />
      <Reject
        openModal={Openmodelrejt}
        setOpenModal={setOpenpopopreject}
        singleformdata={singleformdata}
        denyToken={jobDenyToken}
      />
      <EDocModal
        openModal={OpenEDocModal}
        setOpenModal={setOpenEDocModal}
        singleformdata={singleformdata}
      />
    </>
  );
};

export default Index;

import React, { useState } from "react";
import { useDrop } from "react-dnd";
import { FieldItems, ItemTypes } from "./ItemTypes.js";
import { SIDEBAR_ITEM } from "../../../store/constants/constants.js";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import shortid from "shortid";
import { useDispatch } from "react-redux";
import { storeFormStructure } from "../../../store/actions/storeFormStructure.js";
import InputField from "./FormComponents/InputField.js";
import {
  COL_FORM_STRUCTURE,
  FORM_STRUCTURE,
  FORM_STRUCTURE_UPDATE,
} from "../../../store/constants/constants.js";
import ParagraphField from "./FormComponents/ParagraphField.js";
import HeadingField from "./FormComponents/HeadingField.js";
import InputEmail from "./FormComponents/InputEmail.js";
import InputPhone from "./FormComponents/InputPhone.js";
import InputNumber from "./FormComponents/InputNumber.js";
import InputDate from "./FormComponents/InputDate.js";
import TextArea from "./FormComponents/TextArea.js";
import SelectField from "./FormComponents/SelectField.js";
import SelectField_r from "./FormComponents/SelectField_r.js";
import RadioField from "./FormComponents/RadioField.js";
import CheckboxField from "./FormComponents/CheckboxField.js";
import UploadFileField from "./FormComponents/UploadFileField.js";
import StarRating from "./FormComponents/StarRating";
import SignatureField from "./FormComponents/SignatureField";
import AutocompleteField from "./FormComponents/AutocompleteField";
import TwoColGrid from "./GridLayout/TwoColGrid.js";
import { storeColFormStructure } from "../../../store/actions/storeColFormStructure.js";
import InputURL from "./FormComponents/InputURL.js";
import HyperLinkField from "./FormComponents/HyperLinkField.js";
import style from "./FormComponents/style.module.scss";
import ImageField from "./FormComponents/ImageField.js";
import TableField from "./FormComponents/TableField.js";
import Line from "./FormComponents/Line.js";
import SelectField_r_print from "./FormComponents/SelectField_r_print.js";
import RecruiterInfo from "./FormComponents/RecruiterInfo.js";
import VerticalTableField from "./FormComponents/VerticalTableField.js";
function getStyle(backgroundColor) {
  return {
    // border: "1px solid #e5e5e5",
    // width: "100%",
    backgroundColor,
    // padding: "16px",
    // fontSize: "1rem",
  };
}
function getParaStyle(color) {
  return {
    color,
  };
}

function ColDropBox({ id, greedy, col_ID, col_name, showPreview }) {
  const getpreviewform = useSelector((state) => state?.getpreviewform);

  let dispatch = useDispatch();

  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
      accept: SIDEBAR_ITEM,

      drop(_item, monitor) {
        const didDrop = monitor.didDrop();
        if (didDrop && !greedy) {
          return;
        }

        let testval = _item.value;
        testval.id = shortid.generate();
        testval.name = shortid.generate();

        const addtablerow = getpreviewform.map((item) => {
          const Addar = {
            ...testval,
          };

          if (item?.id === id) {
            return {
              ...item,
              type: {
                ...item.type,
                [col_name]: {
                  ...item.type[col_name],
                  data: [...item.type[col_name].data, Addar],
                },
              },
            };
          }
          return item;
        });

        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow));
      },

      collect: (monitor) => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [greedy, getpreviewform]
  );

  let backgroundColor = "rgba(255, 255, 255)";
  let color = "#adadad";

  if (isOverCurrent || (isOver && greedy)) {
    backgroundColor = "skyblue";
    color = "#fff";
  }

  const list = getpreviewform.map((dropItem, index) => {
    if (dropItem.id === id) {
      return dropItem?.type[col_name]?.data?.map((subDropItems, i) => {
        switch (subDropItems?.type?.field_type) {
          case "input":
            return (
              <InputField
                id={subDropItems.id}
                main_id={dropItem.id}
                label={subDropItems.type.label}
                placeholder={subDropItems.type.placeholder}
                type={subDropItems.type.field_type}
                two_col={1}
                col_name={col_name}
              ></InputField>
            );
          case "paragraph":
            return (
              <ParagraphField
                type={subDropItems.type.field_type}
                id={subDropItems.id}
                main_id={dropItem.id}
                value={subDropItems.type.field_value}
                field_style={subDropItems.type.style}
                two_col={1}
                col_name={col_name}
              />
            );
          case "heading":
            return (
              <HeadingField
                type={subDropItems.type.field_type}
                id={subDropItems.id}
                main_id={dropItem.id}
                value={subDropItems.type.field_value}
                field_style={subDropItems.type.style}
                two_col={1}
                col_name={col_name}
              />
            );
          case "email":
            return (
              <InputEmail
                id={subDropItems.id}
                main_id={dropItem.id}
                label={subDropItems.type.label}
                placeholder={subDropItems.type.placeholder}
                type={subDropItems.type.field_type}
                value={subDropItems.type.field_value}
                two_col={1}
                col_name={col_name}
              />
            );
          case "phone":
            return (
              <InputPhone
                id={subDropItems.id}
                main_id={dropItem.id}
                label={subDropItems.type.label}
                placeholder={subDropItems.type.placeholder}
                type={subDropItems.type.field_type}
                value={subDropItems.type.field_value}
                two_col={1}
                col_name={col_name}
              />
            );
          case "number":
            return (
              <InputNumber
                id={subDropItems.id}
                main_id={dropItem.id}
                label={subDropItems.type.label}
                placeholder={subDropItems.type.placeholder}
                type={subDropItems.type.field_type}
                value={subDropItems.type.field_value}
                two_col={1}
                col_name={col_name}
              />
            );
          case "date":
            return (
              <InputDate
                id={subDropItems.id}
                main_id={dropItem.id}
                label={subDropItems.type.label}
                type={subDropItems.type.field_type}
                value={subDropItems.type.field_value}
                two_col={1}
                col_name={col_name}
              />
            );
          case "textarea":
            return (
              <TextArea
                id={subDropItems.id}
                main_id={dropItem.id}
                label={subDropItems.type.label}
                type={subDropItems.type.field_type}
                value={subDropItems.type.field_value}
                placeholder={subDropItems.type.placeholder}
                two_col={1}
                col_name={col_name}
              />
            );
          case "select":
            return (
              <SelectField
                id={subDropItems.id}
                main_id={dropItem.id}
                label={subDropItems.type.label}
                type={subDropItems.type.field_type}
                value={subDropItems.type.field_value}
                placeholder={subDropItems.type.placeholder}
                options={subDropItems.type.options}
                two_col={1}
                col_name={col_name}
              />
            );

          case "info":
            return (
              <RecruiterInfo
                id={dropItem.id}
                name={dropItem.name}
                type={dropItem.type.field_type}
              />
            );
          case "radio":
            return (
              <RadioField
                id={subDropItems.id}
                main_id={dropItem.id}
                label={subDropItems.type.label}
                type={subDropItems.type.field_type}
                name={subDropItems.type.name}
                options={subDropItems.type.options}
                two_col={1}
                col_name={col_name}
              />
            );
          case "checkbox":
            return (
              <CheckboxField
                id={subDropItems.id}
                main_id={dropItem.id}
                label={subDropItems.type.label}
                type={subDropItems.type.field_type}
                name={subDropItems.type.name}
                options={subDropItems.type.options}
                two_col={1}
                col_name={col_name}
              />
            );
          case "upload_file":
            return (
              <UploadFileField
                id={subDropItems.id}
                main_id={dropItem.id}
                label={subDropItems.type.label}
                type={subDropItems.type.field_type}
                button_text={subDropItems.type.button_text}
                two_col={1}
                col_name={col_name}
              />
            );
          case "rating":
            return (
              <StarRating
                id={subDropItems.id}
                main_id={dropItem.id}
                label={subDropItems.type.label}
                type={subDropItems.type.field_type}
                name={subDropItems.name}
                selectedvalue=""
                two_col={1}
                col_name={col_name}
              />
            );
          case "signature":
            return (
              <SignatureField
                id={subDropItems.id}
                main_id={dropItem.id}
                label={subDropItems.type.label}
                type={subDropItems.type.field_type}
                name={subDropItems.name}
                two_col={1}
                col_name={col_name}
              />
            );

          case "autocomplete":
            return (
              <AutocompleteField
                id={subDropItems.id}
                main_id={dropItem.id}
                label={subDropItems.type.label}
                type={subDropItems.type.field_type}
                selectedvalue=""
                placeholder={subDropItems.type.placeholder}
                name={subDropItems.name}
                options={subDropItems.type.options}
                two_col={1}
                col_name={col_name}
              />
            );

          case "url":
            return (
              <InputURL
                id={subDropItems.id}
                main_id={dropItem.id}
                label={subDropItems.type.label}
                type={subDropItems.type.field_type}
                value={subDropItems.type.field_value}
                placeholder={subDropItems.type.placeholder}
                name={subDropItems.name}
                two_col={1}
                col_name={col_name}
              />
            );

          case "hyperlink":
            return (
              <HyperLinkField
                id={subDropItems.id}
                main_id={dropItem.id}
                label={subDropItems.type.label}
                type={subDropItems.type.field_type}
                placeholder={subDropItems.type.placeholder}
                name={subDropItems.name}
                two_col={1}
                col_name={col_name}
              />
            );

          case "image":
            return (
              <ImageField
                id={subDropItems.id}
                main_id={dropItem.id}
                type={subDropItems.type.field_type}
                img_url={subDropItems.type.img_url}
                name={subDropItems.name}
                two_col={1}
                col_name={col_name}
                field_style={subDropItems.type.style}
              />
            );
          case "line":
            return (
              <Line
                type={subDropItems.type.field_type}
                id={subDropItems.id}
                main_id={dropItem.id}
                two_col={1}
                col_name={col_name}
              ></Line>
            );
          case "table":
            return (
              <TableField
                id={subDropItems.id}
                main_id={dropItem.id}
                type={subDropItems.type.field_type}
                header={subDropItems.type.header}
                table={subDropItems.type.table.data}
                two_col={1}
                col_name={col_name}
              ></TableField>
            );

          case "vertical_table":
            return (
              <VerticalTableField
                id={subDropItems.id}
                main_id={dropItem.id}
                type={subDropItems.type.field_type}
                header={subDropItems.type.header}
                table={subDropItems.type.table.data}
                two_col={1}
                col_name={col_name}
              ></VerticalTableField>
            );

          default:
        }
      });
    }
  });

  return (
    <div
      ref={drop}
      className={style.col_item}
      style={getStyle(backgroundColor)}
    >
      {list}
      <p
        className={`${style.dropText} text-center`}
        style={getParaStyle(color)}
      >
        Drop Here
      </p>
    </div>
  );
}

export default ColDropBox;

import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as Cross } from "../../../../assets/industries/model/x.svg";
import Modal from "@mui/material/Modal";
import styles from "./stylesUpload.module.scss";
import { Button } from "@mui/material";
import Alerts from "../../../common/Alert";
import SignatureCanvas from "react-signature-canvas";
import { useDispatch } from "react-redux";
import { uploadsign } from "../../../../store/actions/uploadsign";

function CommentModel({
  openModal,
  setOpenModal,
  type,
  id,
  main_id,
  two_col,
  col_name,
  name,
  ratingcomment,
  setRateComment,
}) {
  let dispatch = useDispatch();
  const handleClose = () => setOpenModal(false);

  const handleSubmit = () => {
    setOpenModal(false);
  };

  const handleChange = (val) => {
    let newval = val.replace(/\s/g, "").length;
    if (newval > 0) {
      setRateComment(val);
    } else {
      setRateComment("");
    }
  };

  return (
    <>
      <Alerts />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${styles.industryModel}`}
      >
        <Box className={`${styles.boxModel} xym1`}>
          <Box className={styles.modalwapper}>
            <Box className={styles.cross} onClick={handleClose}>
              <Cross />
            </Box>
            <Typography variant="h5" className={styles.heading}>
              Can you please give further clarification for rating?
            </Typography>
            <Box className={styles.statusbox}>
              <Box className={styles.offermodelDetail}>
                <textarea
                  placeholder="Add Comment"
                  value={ratingcomment}
                  id={name + "rating"}
                  required={require}
                  name={name + "rating"}
                  onChange={(e) => {
                    handleChange(e?.target?.value);
                  }}
                ></textarea>
              </Box>
              <Box
                className={`${styles.button} button-primary d-flex justify-end`}
              >
                <Button className="secondary-btn" onClick={handleClose}>
                  Update
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default CommentModel;

import { Box } from "@mui/material";
import React, { useEffect } from "react";
import SEO from "../../components/meta-tags";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import "./notFound.scss";

const NotFound = () => {
  const auth = useSelector((state) => state.auth);
  const token = JSON.parse(localStorage.getItem("token"));
  const userType = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {}, [token, userType]);
  const navigate = useNavigate();

  if (!token && !userType) {
    return <Navigate to={routes.LOGIN} replace />;
  }
  return (
    <>
      <SEO title="Not Found" />
      <div className="row not-found-sec">
        <div className="col-lg-6 col-md-8 mx-auto">
          <div className="notFoundCard">
            <div class="glitch" data-text="404">
              404
            </div>
            <p className="title">Page Not Found</p>
            <p className="info">
              Sorry for inconvenience, page you are looking for is not available
              you can go back to dashboard by clicking on below button.
            </p>
            <Box className="button-primary">
              <button
                // className={styles.addTabel}
                onClick={() => {
                  navigate(routes.HOME);
                }}
              >
                Back to Home
              </button>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;

import React, { useRef, useState } from "react";
import { Box } from "@mui/system";
import Actions from "./Actions";
import style from "./style.module.scss";
import useDragField from "../../../../customHook/useDragField";

function InputPhone({
  placeholder,
  label,
  type,
  id,
  main_id,
  value,
  two_col,
  col_name,
  require,
  name,
  index,
  moveCard,
}) {
  const ref = useRef(null);
  const dragField = useDragField(ref, index, moveCard, id);
  const opacity = dragField.opacity;
  return (
    <Box
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={dragField?.handlerId}
      className={`${style.inputBox} dropItem`}
    >
      <Actions
        type={type}
        id={id}
        two_col={two_col}
        col_name={col_name}
        main_id={main_id}
      />
      <label>{label}</label>
      <input
        type="number"
        placeholder={placeholder}
        value={value}
        id={id}
        required={require}
        name={name}
      />
    </Box>
  );
}

export default InputPhone;

import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ColDropBox from "../ColDropBox";
import ColDropBox2 from "../ColDropBox2";
import { DropBox } from "../DropBox";
import Actions from "../FormComponents/Actions";

import style from "../FormComponents/style.module.scss";
import { useSelector } from "react-redux";
import HeadingField from "../FormComponents/HeadingField.js";
import InputField from "../FormComponents/InputField";
import ParagraphField from "../FormComponents/ParagraphField";
import InputEmail from "../FormComponents/InputEmail.js";
import InputPhone from "../FormComponents/InputPhone.js";
import InputNumber from "../FormComponents/InputNumber.js";
import InputDate from "../FormComponents/InputDate.js";
import TextArea from "../FormComponents/TextArea.js";
import SelectField from "../FormComponents/SelectField.js";
import RadioField from "../FormComponents/RadioField.js";
import CheckboxField from "../FormComponents/CheckboxField.js";
import UploadFileField from "../FormComponents/UploadFileField.js";
import StarRating from "../FormComponents/StarRating";
import SignatureField from "../FormComponents/SignatureField";
import AutocompleteField from "../FormComponents/AutocompleteField";

import InputURL from "../FormComponents/InputURL.js";
import HyperLinkField from "../FormComponents/HyperLinkField.js";

import ImageField from "../FormComponents/ImageField.js";
import TableField from "../FormComponents/TableField.js";
import Line from "../FormComponents/Line.js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
function TwoColGrid2({
  id,
  type,
  col1,
  col2,
  col1_ID,
  col2_ID,
  col_name,
  two_col,
}) {
  const location = useLocation();
  const formData = location?.state?.formData;
  const typesa = location?.state?.type;
  const { formToken } = useParams();
  return (
    <Box className={`${style.gridBox} dropItem`}>
      <Actions
        id={id}
        type={type}
        className={`${style.editbutton} editbutton`}
      />
      <Grid container spacing={2} className={style.gridRow} id={id}>
        <Grid item md={6} id={col1_ID}>
          {col1.data.map((dropItem, index) => {
            switch (dropItem?.type?.field_type) {
              case "input":
                return (
                  <InputField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    placeholder={dropItem.type.placeholder}
                    type={dropItem.type.field_type}
                    name={dropItem.name}
                    value={formData?.[dropItem?.name]}
                  ></InputField>
                );
              case "paragraph":
                return (
                  <ParagraphField
                    type={dropItem.type.field_type}
                    id={dropItem.id}
                    value={dropItem.type.field_value}
                    field_style={dropItem.type.style}
                  />
                );
              case "heading":
                return (
                  <HeadingField
                    type={dropItem.type.field_type}
                    id={dropItem.id}
                    value={dropItem.type.field_value}
                    field_style={dropItem.type.style}
                  />
                );
              case "email":
                return (
                  <InputEmail
                    id={dropItem.id}
                    label={dropItem.type.label}
                    placeholder={dropItem.type.placeholder}
                    type={dropItem.type.field_type}
                    value={formData?.[dropItem?.name]}
                    name={dropItem.name}
                  />
                );
              case "phone":
                return (
                  <InputPhone
                    id={dropItem.id}
                    label={dropItem.type.label}
                    placeholder={dropItem.type.placeholder}
                    type={dropItem.type.field_type}
                    name={dropItem.name}
                    value={formData?.[dropItem?.name]}
                  />
                );
              case "number":
                return (
                  <InputNumber
                    id={dropItem.id}
                    label={dropItem.type.label}
                    placeholder={dropItem.type.placeholder}
                    type={dropItem.type.field_type}
                    name={dropItem.name}
                    value={formData?.[dropItem?.name]}
                  />
                );
              case "date":
                return (
                  <InputDate
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    value={formData?.[dropItem?.name]}
                    name={dropItem.name}
                  />
                );
              case "textarea":
                return (
                  <TextArea
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    value={formData?.[dropItem?.name]}
                    placeholder={dropItem.type.placeholder}
                    name={dropItem.name}
                  />
                );
              case "select":
                return (
                  <SelectField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    value={formData?.[dropItem?.name]}
                    placeholder={dropItem.type.placeholder}
                    name={dropItem.name}
                    SelectField={formData?.[dropItem?.name]}
                    two_col={1}
                    col_name={"col_1"}
                  />
                );
              case "radio":
                return (
                  <RadioField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    name={dropItem.name}
                    SelectField={formData?.[dropItem?.name]}
                    two_col={1}
                    col_name={"col_1"}
                  />
                );
              case "checkbox":
                return (
                  <CheckboxField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    name={dropItem.name}
                    SelectField={formData?.[dropItem?.name]}
                    two_col={1}
                    col_name={"col_1"}
                  />
                );
              case "upload_file":
                return formToken ||
                  typesa === "candidate" ||
                  typesa === "recruiter" ? (
                  <UploadFileField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    button_text={dropItem.type.button_text}
                    name={dropItem.name}
                  />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_URL}/storage/${
                      formData?.[dropItem?.name]
                    }`}
                    height="50"
                    width="50"
                    alt=""
                  />
                );
              case "rating":
                return (
                  <StarRating
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    name={dropItem.name}
                    selectedvalue={formData?.[dropItem?.name]}
                  />
                );
              case "signature":
                return formToken ||
                  typesa === "candidate" ||
                  typesa === "recruiter" ? (
                  <SignatureField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    name={dropItem.name}
                  />
                ) : (
                  <img
                    style={{ maxWidth: "160px" }}
                    src={`${process.env.REACT_APP_URL}/storage/${
                      formData?.[dropItem?.name]
                    }`}
                    alt=""
                  />
                );

              case "autocomplete":
                return (
                  <AutocompleteField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    selectedvalue={formData?.[dropItem?.name]}
                    placeholder={dropItem.type.placeholder}
                    name={dropItem.name}
                    options={dropItem.type.options}
                  />
                );

              case "url":
                return (
                  <InputURL
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    value={formData?.[dropItem?.name]}
                    placeholder={dropItem.type.placeholder}
                    name={dropItem.name}
                  />
                );

              case "hyperlink":
                return (
                  <HyperLinkField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    placeholder={dropItem.type.placeholder}
                    name={dropItem.name}
                  />
                );

              case "image":
                return (
                  <ImageField
                    id={dropItem.id}
                    type={dropItem.type.field_type}
                    img_url={dropItem.type.img_url}
                    name={dropItem.name}
                  />
                );
              case "line":
                return (
                  <Line type={dropItem.type.field_type} id={dropItem.id}></Line>
                );
              case "table":
                return (
                  <TableField
                    id={dropItem.id}
                    type={dropItem.type.field_type}
                    header={dropItem.type.header}
                    table={dropItem.type.table.data}
                  ></TableField>
                );

              default:
            }
          })}
        </Grid>
        <Grid item md={6} id={col2_ID}>
          {col2.data.map((dropItem, index) => {
            switch (dropItem?.type?.field_type) {
              case "input":
                return (
                  <InputField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    placeholder={dropItem.type.placeholder}
                    type={dropItem.type.field_type}
                    name={dropItem.name}
                    value={formData?.[dropItem?.name]}
                  ></InputField>
                );
              case "paragraph":
                return (
                  <ParagraphField
                    type={dropItem.type.field_type}
                    id={dropItem.id}
                    value={dropItem.type.field_value}
                    field_style={dropItem.type.style}
                  />
                );
              case "heading":
                return (
                  <HeadingField
                    type={dropItem.type.field_type}
                    id={dropItem.id}
                    value={dropItem.type.field_value}
                    field_style={dropItem.type.style}
                  />
                );
              case "email":
                return (
                  <InputEmail
                    id={dropItem.id}
                    label={dropItem.type.label}
                    placeholder={dropItem.type.placeholder}
                    type={dropItem.type.field_type}
                    value={formData?.[dropItem?.name]}
                    name={dropItem.name}
                  />
                );
              case "phone":
                return (
                  <InputPhone
                    id={dropItem.id}
                    label={dropItem.type.label}
                    placeholder={dropItem.type.placeholder}
                    type={dropItem.type.field_type}
                    name={dropItem.name}
                    value={formData?.[dropItem?.name]}
                  />
                );
              case "number":
                return (
                  <InputNumber
                    id={dropItem.id}
                    label={dropItem.type.label}
                    placeholder={dropItem.type.placeholder}
                    type={dropItem.type.field_type}
                    name={dropItem.name}
                    value={formData?.[dropItem?.name]}
                  />
                );
              case "date":
                return (
                  <InputDate
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    value={formData?.[dropItem?.name]}
                    name={dropItem.name}
                  />
                );
              case "textarea":
                return (
                  <TextArea
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    value={formData?.[dropItem?.name]}
                    placeholder={dropItem.type.placeholder}
                    name={dropItem.name}
                  />
                );
              case "select":
                return (
                  <SelectField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    value={formData?.[dropItem?.name]}
                    placeholder={dropItem.type.placeholder}
                    name={dropItem.name}
                    SelectField={formData?.[dropItem?.name]}
                    two_col={1}
                    col_name={"col_2"}
                  />
                );
              case "radio":
                return (
                  <RadioField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    name={dropItem.name}
                    SelectField={formData?.[dropItem?.name]}
                    two_col={1}
                    col_name={"col_2"}
                  />
                );
              case "checkbox":
                return (
                  <CheckboxField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    name={dropItem.name}
                    SelectField={formData?.[dropItem?.name]}
                    two_col={1}
                    col_name={"col_2"}
                  />
                );
              case "upload_file":
                return formToken ||
                  typesa === "candidate" ||
                  typesa === "recruiter" ? (
                  <UploadFileField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    button_text={dropItem.type.button_text}
                    name={dropItem.name}
                  />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_URL}/storage/${
                      formData?.[dropItem?.name]
                    }`}
                    height="50"
                    width="50"
                    alt=""
                  />
                );
              case "rating":
                return (
                  <StarRating
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    name={dropItem.name}
                    selectedvalue={formData?.[dropItem?.name]}
                  />
                );
              case "signature":
                return formToken ||
                  typesa === "candidate" ||
                  typesa === "recruiter" ? (
                  <SignatureField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    name={dropItem.name}
                  />
                ) : (
                  <img
                    style={{ maxWidth: "160px" }}
                    src={`${process.env.REACT_APP_URL}/storage/${
                      formData?.[dropItem?.name]
                    }`}
                    alt=""
                  />
                );

              case "autocomplete":
                return (
                  <AutocompleteField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    selectedvalue={formData?.[dropItem?.name]}
                    placeholder={dropItem.type.placeholder}
                    name={dropItem.name}
                    options={dropItem.type.options}
                  />
                );

              case "url":
                return (
                  <InputURL
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    value={formData?.[dropItem?.name]}
                    placeholder={dropItem.type.placeholder}
                    name={dropItem.name}
                  />
                );

              case "hyperlink":
                return (
                  <HyperLinkField
                    id={dropItem.id}
                    label={dropItem.type.label}
                    type={dropItem.type.field_type}
                    placeholder={dropItem.type.placeholder}
                    name={dropItem.name}
                  />
                );

              case "image":
                return (
                  <ImageField
                    id={dropItem.id}
                    type={dropItem.type.field_type}
                    img_url={dropItem.type.img_url}
                    name={dropItem.name}
                  />
                );
              case "line":
                return (
                  <Line type={dropItem.type.field_type} id={dropItem.id}></Line>
                );
              case "table":
                return (
                  <TableField
                    id={dropItem.id}
                    type={dropItem.type.field_type}
                    header={dropItem.type.header}
                    table={dropItem.type.table.data}
                  ></TableField>
                );

              default:
            }
          })}
        </Grid>
      </Grid>
    </Box>
  );
}

export default TwoColGrid2;

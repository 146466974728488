import {
  Button,
  Checkbox,
  Grid,
  InputBase,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Navigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { adminLogin } from "../../../api/adminlogin/login.class";
import React, { useState } from "react";
import { ShowAlert } from "../../../store/actions/alertActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProfile } from "../../../store/actions/profileActions";
import { routes } from "../../../routes";
import { loginByAdminApi } from "../../../store/actions/authActions";

// ----------------------

// ------------------------
const Adminlogin = () => {
  const uuid = useParams().slug;
  const [loader, setloader] = useState(0);
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token")) || "";
  const userType = JSON.parse(localStorage.getItem("user")) || "";
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    onSubmit();
  }, []);

  const onSubmit = () => {
    dispatch(
      loginByAdminApi({
        recruiter_uuid: uuid,
      })
    );
  };

  // console.log("auth", auth);

  if (auth?.results?.type === "recruitment") {
    return (
      <Navigate
        to={
          userType && userType !== "recruitment"
            ? routes.CANDIDATE_DASHBOARD
            : routes.DASHBOARD
        }
        replace
      />
    );
  }
  if (auth?.results?.type === "candidate") {
    return (
      <Navigate
        to={
          userType && userType !== "recruitment"
            ? routes.CANDIDATE_DASHBOARD
            : routes.DASHBOARD
        }
        replace
      />
    );
  }

  {
    return loader === 0 ? <CircularProgress /> : "";
  }
};

export default Adminlogin;

import React, { useState } from "react";
import style from "./style.module.scss";
import editIcon from "./../../../../assets/jobs/edit_teal.svg";
import deleteIcon from "./../../../../assets/jobs/deleteAction.svg";
import { Button } from "@mui/material";
import EditModel from "../Model/EditModel";
import { useDispatch, useSelector } from "react-redux";
import shortid from "shortid";
import { Getdeleteid } from "../../../../store/actions/getdeleteid";
import {
  COL_FORM_STRUCTURE,
  COL_FORM_STRUCTURE_UPDATE,
  FORM_STRUCTURE_UPDATE,
} from "../../../../store/constants/constants.js";
import { storeFormStructure } from "../../../../store/actions/storeFormStructure";
import $ from "jquery";
import { storeColFormStructure } from "../../../../store/actions/storeColFormStructure";
function Actions({ type, id, main_id, two_col, col_name }) {
  let dispatch = useDispatch();
  const [editOpenmodel, setEditOpenmodel] = useState(false);
  const uiniq_id = shortid.generate();
  const getpreviewform = useSelector((state) => state?.getpreviewform);
  const getColFormStructre = useSelector((state) => state?.getColFormStructre);
  const deleteindex = (val) => {
    if (two_col === 1) {
      const addtablerow2 = getpreviewform.map((item) => {
        item?.type["col_1"]?.data?.map((colitem, i) => {
          if (colitem.id === val) {
            item.type["col_1"].data.splice(i, 1);

            return {
              ...item,
            };
          }
        });

        return item;
      });

      const addtablerow3 = addtablerow2.map((item) => {
        item?.type["col_2"]?.data?.map((colitem, j) => {
          if (colitem.id === val) {
            item.type["col_2"].data.splice(j, 1);

            return {
              ...item,
            };
          }
        });

        return item;
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow3));
    } else {
      const Newa = getpreviewform.filter(checID);
      const delColItem = getColFormStructre.filter(checkID);

      function checID(newid) {
        return val !== newid?.id;
      }
      function checkID(newid) {
        return val !== newid?.id;
      }

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, Newa));
      dispatch(storeColFormStructure(COL_FORM_STRUCTURE_UPDATE, delColItem));
    }
  };

  return (
    <div className={style.actionsButtons}>
      {type !== "line" && type !== "info" ? (
        <Button
          onClick={() => {
            setEditOpenmodel(true);
          }}
          className={style.editbtn}
        >
          <img src={editIcon} alt="edit" />
        </Button>
      ) : (
        ""
      )}

      <Button
        id={uiniq_id}
        onClick={(e) => {
          deleteindex(id);
        }}
      >
        <img src={deleteIcon} alt="delete" />
      </Button>

      <EditModel
        openModal={editOpenmodel}
        setOpenModal={setEditOpenmodel}
        type={type}
        id={id}
        main_id={main_id}
        two_col={two_col}
        col_name={col_name}
      />
    </div>
  );
}

export default Actions;

import { OPTION_LABEL_VALUE } from "../constants/constants";
import { Box } from "@mui/system";
import OptionTableRow from "../../components/forms/formbuilder/OptionTable/OptionTableRow";
import style from "../../components/forms/formbuilder/FormComponents/style.module.scss";

const initialState = [];

export const getlabelvalueReducers = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case OPTION_LABEL_VALUE:
      let emptyObj = { value: payload?.value, key: payload?.key - 1 };
      for (let i = 0; i < payload?.key; i++) {
        if (payload?.key - 1 === i) {
          state[i] = emptyObj;
        }
      }
      return [...state];
    default:
      return state;
  }
};

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { getTrail } from "../../../../store/actions/pricingActions";

export default function FormDialog({ open, setOpen }) {
  const dispatch = useDispatch();
  const pricing = useSelector((state) => state?.pricing?.trail);
  const price = useSelector((state) => state?.pricing);

  const handleClose = (_, reason) => {
    if (reason === "backdropClick") {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    if (pricing?.success) {
      setOpen(false);
    }
  }, [pricing]);
  return (
    <>
      <Dialog open={open} onClose={handleClose} className={styles.modelMail}>
        <Box className="p-4">
          <Typography variant="h4">
            {/* Please confirm, if you want to activate trial.{" "} */}
            Welcome to Worecs!
          </Typography>
          <Typography variant="p">
            We are providing these features in free trial for you..
          </Typography>
          <ul>
            <li>14 days period</li>
            <li>Allow 1 application form</li>
            <li>Allow 1 job offer letter</li>
            <li>Allow 2 reference form</li>
            <li>Allow 1 e-doc form</li>
            <li>Allow to send 1 e-doc form (induction or miscellaneous)</li>
          </ul>
        </Box>

        <DialogActions className={styles.buttonSection}>
          <Box className={` button-primary ${styles.button}`}>
            <Button
              disabled={price?.isLoading}
              onClick={() => dispatch(getTrail())}
            >
              {price?.isLoading && pricing === null ? (
                <p style={{ color: "white" }}>Loading...</p>
              ) : (
                "  Confirm"
              )}
            </Button>
          </Box>
          <Box className={`button-primary ${styles.button1}`}>
            <Button className={"secondary-btn"} onClick={handleClose}>
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
